import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { FeedbackItemDto } from '../../../../../../api';
import { catchError, map, Observable, of, take } from 'rxjs';
import { FilesService } from '../../../../plant-profiles/files.service';
import { LangService } from '../../../../../services/lang.service';

@Component({
  selector: 'app-feedback-img-modal',
  templateUrl: './feedback-img-modal.component.html',
  styleUrls: ['./feedback-img-modal.component.scss'],
})
export class FeedbackImgModalComponent implements OnInit {
  constructor(
    private readonly modalCtrl: ModalController,
    private readonly filesService: FilesService,
    protected readonly langService: LangService,
  ) {}

  @Input() feedback: FeedbackItemDto;

  imageLoaded: boolean = false;

  blob: Observable<string>;

  ngOnInit() {}

  cancel() {
    this.modalCtrl.dismiss(null, 'cancel').then();
  }

  getFeedbackImg() {
    if (!this.feedback.file_id) {
      return of('/assets/svg/default.svg');
    }

    if (!this.blob) {
      this.imageLoaded = false;
      this.blob = this.filesService.getThumbnail(this.feedback.file_id).pipe(
        take(1),
        map((blob) => {
          this.imageLoaded = true;
          return URL.createObjectURL(blob);
        }),
        catchError(() => of('/assets/svg/default.svg')),
      );
    }
    return this.blob;
  }
}
