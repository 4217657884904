<ion-fab slot="fixed" class="fixed-top">
  <ion-fab-button size="large" class="language-button">
    <ion-icon name="language-outline"></ion-icon>
  </ion-fab-button>
  <ion-fab-list side="bottom">
    <ion-fab-button
      [color]="langService.language === lang ? 'primary' : ''"
      size="small"
      *ngFor="let lang of langList"
      (click)="changeLanguage(lang)"
    >
      <ion-icon [src]="'assets/flags/' + lang.toUpperCase() + '.svg'"></ion-icon>
    </ion-fab-button>
  </ion-fab-list>
</ion-fab>
