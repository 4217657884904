<ion-accordion-group #accordionGroup>
  <ion-accordion value="sorting" class="border-radius-10">
    <ion-item slot="header" color="light">
      <ion-label>
        {{ 'COMMON.SORTING' | translate
        }}{{ sorting.value ? ': ' + (sorting.name | translate | lowercase) : '' }}
        <ng-container *ngIf="sorting.value">
          <ion-icon
            *ngIf="sorting.direction === 'asc'"
            name="arrow-up-outline"
            class="accordion-item__icon"
          ></ion-icon>
          <ion-icon
            *ngIf="sorting.direction === 'desc'"
            name="arrow-down-outline"
            class="accordion-item__icon"
          ></ion-icon>
        </ng-container>
      </ion-label>
    </ion-item>
    <ion-radio-group
      [allowEmptySelection]="true"
      value="sortTypes"
      slot="content"
      (ionChange)="emitSort($event)"
    >
      <ng-container *ngFor="let header of tableHeaders">
        <ion-item *ngIf="header.name !== 'actions'">
          <ion-radio
            class="ion-align-items-center"
            [value]="{ field: header.value, direction: 'asc' }"
          >{{ header.name | translate | lowercase }}
          </ion-radio>
          <ion-icon slot="start" name="arrow-up-outline" class="accordion-item__icon"></ion-icon>
        </ion-item>
        <ion-item *ngIf="header.name !== 'actions'">
          <ion-radio
            class="ion-align-items-center"
            [value]="{ field: header.value, direction: 'desc' }"
          >{{ header.name | translate | lowercase }}
          </ion-radio>
          <ion-icon slot="start" name="arrow-down-outline" class="accordion-item__icon"></ion-icon>
        </ion-item>
      </ng-container>
    </ion-radio-group>
  </ion-accordion>
</ion-accordion-group>
