import { Component, OnInit } from '@angular/core';
import { ModalController, ToastController } from '@ionic/angular';
import { SensorsService } from '../sensors.service';
import { SensorItemDto } from '../../../../api';
import { Router } from '@angular/router';
import { LangService } from '../../../services/lang.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { UserService } from '../../../services/user.service';
import { User } from '@auth0/auth0-angular';
import { PlantProfileSelectComponent } from './plant-profile-select/plant-profile-select.component';
import { TranslateService } from '@ngx-translate/core';
import { AlertService } from '../../../services/alert.service';

@Component({
  selector: 'app-sensor-add',
  templateUrl: './sensor-add.component.html',
  styleUrls: ['./sensor-add.component.scss'],
})
export class SensorAddComponent implements OnInit {
  segment: string = 'step1';

  isDisabled: {
    step1: boolean;
    step2: boolean;
    step3: boolean;
  } = {
    step1: false,
    step2: true,
    step3: true,
  };

  selectedPlantProfile!: any | undefined;

  sensors: SensorItemDto[] | undefined = undefined;

  newSensor: SensorItemDto | undefined;

  newSensorData: {
    temp: number | undefined;
    hum: number | undefined;
  } = {
    temp: undefined,
    hum: undefined,
  };

  newSensorId: number = 0;

  fetchSensorsInterval!: any;

  fetchSensorBlockFlag: boolean = false; // Only during requesting data

  sensorForm!: FormGroup;

  currentUser!: any;

  selectedPlantProfileName: string = '';

  profilesList: any[] = [];

  searchValue: string = '';

  intervalOptions: {
    value: string;
    name: string;
  }[] = [
    {
      value: 'none',
      name: 'SENSOR.INTERVAL_OPTION_NONE',
    },
    {
      value: '7 days',
      name: 'SENSOR.INTERVAL_OPTION_7',
    },
    {
      value: '14 days',
      name: 'SENSOR.INTERVAL_OPTION_14',
    },
    {
      value: '30 days',
      name: 'SENSOR.INTERVAL_OPTION_30',
    },
  ];

  constructor(
    private modalCtrl: ModalController,
    private readonly sensorsService: SensorsService,
    private readonly router: Router,
    protected readonly langService: LangService,
    private readonly userService: UserService,
    private readonly toastCtrl: ToastController,
    private readonly formBuilder: FormBuilder,
    protected readonly translate: TranslateService,
    private alertService: AlertService,
  ) {
    sensorsService
      .getPlantProfiles({
        filter: [],
        page_index: 0,
        page_size: 25,
        sort_direction: 'asc',
        lang: this.langService.language,
      })
      .subscribe({
        next: (res) => {
          this.profilesList = res.data;
        },
        error: () => {
          this.alertService.showFailureAlert('', 'Error while deleting sensor').then();
        },
      });

    this.sensorForm = this.formBuilder.group({
      id: [0, Validators.required],
      plant_profile_id: [null],
      plant_profile_user_id: [null],
      name: ['', Validators.required],
      interval: ['', Validators.required],
    });

    // this.fetchSensorsInterval = setInterval(() => {
    //   if (this.fetchSensorBlockFlag) {
    //     return;
    //   }
    //   this.getSensors();
    // }, 1000);
  }

  applySearchFilter(event: Event) {
    this.searchValue = (event.target as HTMLInputElement).value;
    this.searchValue = this.searchValue.trim();
    this.searchValue = this.searchValue.toLowerCase();
    this.sensorsService
      .getPlantProfiles({
        filter: [
          {
            field: 'name',
            value: `%${this.searchValue}%`,
          },
        ],
        page_index: 0,
        page_size: 25,
        sort: [],
        sort_direction: 'asc',
        lang: this.langService.language,
      })
      .subscribe({
        next: (res) => {
          this.profilesList = res.data;
        },
        error: () => {
          this.alertService.showFailureAlert('', 'Error while fetching filters').then();
        },
      });
  }

  ngOnInit() {
    this.fetchSensorBlockFlag = false;
    this.userService.currentUserSub().then((user: User | null | undefined) => {
      this.currentUser = user;
      this.sensorForm.patchValue({
        modified_by: this.currentUser.sub,
      });
      this.sensorsService.cleanupSensors().subscribe({
        next: (res) => {
          this.getSensors();
        },
        error: () => {
          this.alertService.showFailureAlert('', 'Error while sensors cleanup').then();
        },
      });
    });
  }

  ionViewWillLeave() {
    clearInterval(this.fetchSensorsInterval);
  }

  close() {
    this.router.navigate(['/sensors']).then();
    this.segment = 'step1';
    this.isDisabled.step1 = false;
    this.isDisabled.step2 = true;
    this.isDisabled.step3 = true;
    this.newSensor = undefined;
    this.sensors = [];
  }

  showNextStep() {
    if (this.segment === 'step1') {
      this.isDisabled.step2 = false;
      this.segment = 'step2';
      this.fetchSensorsInterval = setInterval(() => {
        if (this.fetchSensorBlockFlag) {
          return;
        }

        this.getSensors();
      }, 1000);

      return;
    }
    this.isDisabled.step1 = true;
    this.isDisabled.step2 = true;
    this.isDisabled.step3 = false;
    this.segment = 'step3';
  }

  showPrevStep() {
    if (this.segment === 'step2') {
      this.segment = 'step1';
    } else {
      this.segment = 'step2';
    }
  }

  showSegment(event: any) {
    this.segment = event.detail.value;
  }

  async openPlantProfilesList(event: Event) {
    event.preventDefault();
    event.stopPropagation();

    const modal = await this.modalCtrl
      .create({
        component: PlantProfileSelectComponent,
        componentProps: {
          selectedItem: this.selectedPlantProfile,
        },
      })
      .then();

    await modal.present();
    const { data, role } = await modal.onWillDismiss();
    if (role === 'save' && data) {
      if (data.id_global) {
        this.getGlobalPlantProfile(data.id_global);
      } else {
        this.getUserPlantProfile(data.id_user);
      }
    }
  }

  getSensors() {
    this.fetchSensorBlockFlag = true;
    this.sensorsService
      .getSensors({
        filter: [],
        page_index: 0,
        page_size: 25,
        sort: ['id'],
        sort_direction: 'asc',
        search_new: true,
      })
      .subscribe({
        next: (res) => {
          this.fetchSensorBlockFlag = false;
          if (this.sensors === undefined || this.sensors.length === res.data.length) {
            this.sensors = res.data;
            return;
          }
          this.newSensor = res.data[res.data.length - 1];
          this.newSensorId = res.data[res.data.length - 1].id;
          this.sensorForm.patchValue({
            id: this.newSensor.id,
          });
          this.newSensorData.temp = res.data[res.data.length - 1].temp;
          this.newSensorData.hum = res.data[res.data.length - 1].hum;
          this.redirectToLastStep();

          this.sensors.find((item) => item.id === this.newSensorId);
          clearInterval(this.fetchSensorsInterval);
        },
        error: () => {
          this.fetchSensorBlockFlag = false;
          this.alertService.showFailureAlert('', 'Error while fetching sensors').then();
        },
      });
  }

  redirectToLastStep() {
    this.isDisabled.step1 = true;
    this.isDisabled.step2 = true;
    this.isDisabled.step3 = false;
    this.segment = 'step3';
  }

  getGlobalPlantProfile(id: number) {
    this.sensorsService.getGlobalPlantProfileById(id).subscribe({
      next: (res) => {
        this.selectedPlantProfileName = res.name_lang.find(
          (name: any) => name.lang === this.langService.language,
        ).value;
        this.selectedPlantProfile = res;
        this.sensorForm.patchValue({
          plant_profile_id: res.id_global,
          plant_profile_user_id: res.id_user,
          interval: res.interval,
        });
      },
      error: () => {
        this.alertService.showFailureAlert('', 'Error while fetching plant profiles').then();
      },
    });
  }

  getUserPlantProfile(id: number) {
    this.sensorsService.getUserPlantProfileById(id).subscribe({
      next: (res) => {
        this.selectedPlantProfileName = res.name_lang.find(
          (name: any) => name.lang === this.langService.language,
        ).value;
        this.selectedPlantProfile = res;
        this.sensorForm.patchValue({
          plant_profile_id: res.id_global,
          plant_profile_user_id: res.id_user,
          interval: res.interval,
        });
      },
      error: () => {
        this.alertService.showFailureAlert('', 'Error while fetching plant profiles').then();
      },
    });
  }

  saveSensor() {
    this.sensorsService.updateSensor(this.sensorForm.value).subscribe({
      next: (res) => {
        if (res.code === 200) {
          this.showSuccessAlert().then();
          this.close();
        }
      },
      error: () => {
        this.alertService.showFailureAlert('', 'Error while saving sensor').then();
      },
    });
  }

  async showSuccessAlert() {
    const toast = await this.toastCtrl.create({
      message: `Sensor created successfully`,
      duration: 3000,
      position: 'top',
      cssClass: 'success-alert',
      icon: 'checkmark-outline',
    });

    await toast.present();
  }

  selectInterval(event: Event) {
    const selectedInterval = (<any>event).detail.value;
    this.sensorForm.patchValue({
      interval: selectedInterval,
    });
  }
}
