import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Subject, take } from 'rxjs';
import { LangService } from '../../../services/lang.service';
import { PlantSearchRequestDto } from '../../../../api';
import LangEnum = PlantSearchRequestDto.LangEnum;
import { UserService } from '../../../services/user.service';

@Component({
  selector: 'app-language-button',
  templateUrl: './language-button.component.html',
  styleUrls: ['./language-button.component.scss'],
})
export class LanguageButtonComponent implements OnInit {
  unsubscribe: Subject<void> = new Subject<void>();

  langList: LangEnum[] = [];

  constructor(
    public translate: TranslateService,
    protected langService: LangService,
    private readonly userService: UserService,
  ) {}

  ngOnInit(): void {
    this.langList = this.langService.supportedLanguages;
  }

  changeUserLanguage(lang: string) {
    this.userService.changeUserLanguage(lang).pipe(take(1)).subscribe();
  }

  changeLanguage(lang: LangEnum) {
    this.langService.language = lang;
    this.changeUserLanguage(lang);
  }
}
