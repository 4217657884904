<app-header></app-header>

<ion-content color="nimus-md3-green">
  <ion-card class="border-radius-10 ion-margin-vertical alert">

    <ion-card-content *ngIf="thereAreFeedbacks" class="alert-bar">
      <ion-list class="item-trns">
        <ion-item>
          <ion-icon
            class="feedback__alert-icon ion-padding-end"
            name="alert-circle-outline"
            color="nimus_red"
            style="font-size:20px"
          ></ion-icon>
          <ion-text color="nimus_red" class="feedback__alert-text"
          >{{ 'USER.FEEDBACK_WARNING' | translate }}
          </ion-text>
        </ion-item>
        <ion-item lines="none" *ngFor="let row of feedbackAlerts.data" [routerLink]="['/sensors/' + row.id + '/leave-feedback']">
          <ion-label>{{ row.name }}</ion-label>
        </ion-item>
      </ion-list>
    </ion-card-content>
  </ion-card>

  <ion-card style="margin-top: 24px">
    <ion-card-content class="user-card">
      <div class="information">
        <img
          class="img-center"
          [src]="authenticatedUser?.picture"
          alt="User picture"
          width="48"
          height="48"/>

        <div class="information__details">
          <div class="information__details__names">
            <ion-text class="user-name">{{ authenticatedUser?.name }}</ion-text>
            <ion-text class="user-email">{{ authenticatedUser?.email }}</ion-text>
          </div>
        </div>
      </div>
      <div
        *ngIf = "isLocalUser()"
        class="information_buttons">
        <ion-button
          *ngIf="true"
          size="small"
          class="ion-align-self-center ion-margin-start"
          fill="outline"
          color="secondary"
          (click)="changePassword()"
        >{{ 'USER.CHANGE_PASSWORD' | translate }}</ion-button>
      </div>
       <ion-text class="privacy-text" routerLink="/policy">{{'POLICY.HEADER_PRIVATE' | translate}}</ion-text>

      <ion-item-divider></ion-item-divider>

      <div class="active_components">
        <div class="active_components__block">
          <div class="active_components__block__details">
            <ion-text class="count" *ngIf="devicesLoaded">{{ numberOfSensors }}</ion-text>
            <ion-spinner *ngIf="!devicesLoaded"></ion-spinner>
            <ion-icon size="medium" name="flower-outline"></ion-icon>
          </div>
          <ion-text class="counter-name">{{ 'USER.ACTIVE_SENSORS' | translate }}</ion-text>
        </div>
        <div class="active_components__block">
          <div class="active_components__block__details">
            <ion-text class="count" *ngIf="devicesLoaded">{{ numberOfGateways }}</ion-text>
            <ion-spinner *ngIf="!devicesLoaded"></ion-spinner>
            <ion-icon size="medium" name="radio-outline"></ion-icon>
          </div>
          <ion-text class="counter-name">{{ 'USER.ACTIVE_GATEWAYS' | translate }}</ion-text>
        </div>
      </div>
    </ion-card-content>
  </ion-card>

  <ion-card class="mt-5">
    <ion-card-content>
      <ion-item lines="none" class="ion-no-padding">
        <ion-label class="notify-header">{{ 'USER.NOTIFICATIONS_HEADER' | translate }}</ion-label>
      </ion-item>
      <ion-item lines="none" *ngIf="!pwaService.isPwa">
        <ion-label>{{ 'USER.NOTIFICATIONS_UNINSTALLED' | translate }}</ion-label>
        <ion-button
          size="small"
          fill="outline"
          color="primary"
          (click)="pwaInstall()"
        >{{ 'USER.NOTIFICATIONS_INSTALL' | translate }}</ion-button>
      </ion-item>

      <ion-item-divider class="no-height"></ion-item-divider>

      <ion-item lines="none" [disabled]="!pwaService.isPwa">
        <ion-label>{{ 'USER.NOTIFICATIONS_POP_UP' | translate }}</ion-label>
        <ion-toggle
          slot="end"
          aria-label="Primary toggle"
          [checked]="notifications.push"
          (ionChange)="onPushNotificationChange($event)"
        ></ion-toggle>
      </ion-item>

      <ion-item lines="none" class="item-padding-bottom">
        <ion-label>{{ 'USER.NOTIFICATIONS_EMAIL' | translate }}</ion-label>
        <ion-toggle
          slot="end"
          aria-label="Primary toggle"
          [checked]="notifications.email"
          (ionChange)="onEmailNotificationChange($event)"
        ></ion-toggle>
      </ion-item>
    </ion-card-content>
  </ion-card>
</ion-content>

