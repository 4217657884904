<ion-header>
  <ion-toolbar>
    <ion-title
      class="ion-text-start">{{ feedback.created_at | amLocale:langService.language | amDateFormat: 'lll' }}</ion-title>
    <ion-buttons slot="end" (click)="cancel()" class="ion-padding-end">
      <ion-icon name="close-outline" size="large" color="nimus_grey"></ion-icon>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content color="light" class="ion-padding">
  <ion-item class="ion-margin-bottom" lines="none">
    <ion-input
      label="{{ 'SENSOR.PLANT_HEIGHT' | translate }}"
      label-placement="stacked"
      type="text"
      [value]="feedback.height + ' cm'"
      [readonly]="true"
    ></ion-input>
  </ion-item>
  <ion-img alt="feedback image" class="border-radius-5" [src]="getFeedbackImg() | async"></ion-img>
</ion-content>
