/**
 * NIMUS CLOUD File API Backend
 * NIMUS Cloud File Backend Documentation
 *
 * OpenAPI spec version: 1.0.1
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */ /* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams, HttpResponse, HttpEvent } from '@angular/common/http';
import { CustomHttpUrlEncodingCodec } from '../encoder';

import { Observable } from 'rxjs';

import { SuccessDto } from '../model/successDto';

import { BASE_PATH, COLLECTION_FORMATS } from '../variables';
import { Configuration } from '../configuration';

@Injectable()
export class FilesService {
  protected basePath = 'http://127.0.0.1:3000/file-api';

  public defaultHeaders = new HttpHeaders();

  public configuration = new Configuration();

  constructor(
    protected httpClient: HttpClient,
    @Optional() @Inject(BASE_PATH) basePath: string,
    @Optional() configuration: Configuration,
  ) {
    if (basePath) {
      this.basePath = basePath;
    }
    if (configuration) {
      this.configuration = configuration;
      this.basePath = basePath || configuration.basePath || this.basePath;
    }
  }

  /**
   * @param consumes string[] mime-types
   * @return true: consumes contains 'multipart/form-data', false: otherwise
   */
  private canConsumeForm(consumes: string[]): boolean {
    const form = 'multipart/form-data';
    for (const consume of consumes) {
      if (form === consume) {
        return true;
      }
    }
    return false;
  }

  /**
   * Delete plant profile/sensor file
   *
   * @param id
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public filesControllerDeleteFile(
    id: number,
    observe?: 'body',
    reportProgress?: boolean,
  ): Observable<SuccessDto>;
  public filesControllerDeleteFile(
    id: number,
    observe?: 'response',
    reportProgress?: boolean,
  ): Observable<HttpResponse<SuccessDto>>;
  public filesControllerDeleteFile(
    id: number,
    observe?: 'events',
    reportProgress?: boolean,
  ): Observable<HttpEvent<SuccessDto>>;
  public filesControllerDeleteFile(
    id: number,
    observe: any = 'body',
    reportProgress: boolean = false,
  ): Observable<any> {
    if (id === null || id === undefined) {
      throw new Error(
        'Required parameter id was null or undefined when calling filesControllerDeleteFile.',
      );
    }

    let headers = this.defaultHeaders;

    // authentication (bearer) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === 'function'
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set('Authorization', 'Bearer ' + accessToken);
    }
    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.request<SuccessDto>(
      'delete',
      `${this.basePath}/delete/${encodeURIComponent(String(id))}`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      },
    );
  }

  /**
   * Get plant profile/sensor file
   *
   * @param id
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public filesControllerGetFile(
    id: number,
    observe?: 'body',
    reportProgress?: boolean,
  ): Observable<Blob>;
  public filesControllerGetFile(
    id: number,
    observe?: 'response',
    reportProgress?: boolean,
  ): Observable<HttpResponse<Blob>>;
  public filesControllerGetFile(
    id: number,
    observe?: 'events',
    reportProgress?: boolean,
  ): Observable<HttpEvent<Blob>>;
  public filesControllerGetFile(
    id: number,
    observe: any = 'body',
    reportProgress: boolean = false,
  ): Observable<any> {
    if (id === null || id === undefined) {
      throw new Error(
        'Required parameter id was null or undefined when calling filesControllerGetFile.',
      );
    }

    let headers = this.defaultHeaders;

    // authentication (bearer) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === 'function'
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set('Authorization', 'Bearer ' + accessToken);
    }
    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['image/jpeg'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.request<Blob>(
      'get',
      `${this.basePath}/get/${encodeURIComponent(String(id))}`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
        responseType: 'blob' as 'json',
      },
    );
  }

  /**
   * Get plant thumbnail profile/sensor file
   *
   * @param id
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public filesControllerThumbnail(
    id: number,
    observe?: 'body',
    reportProgress?: boolean,
  ): Observable<Blob>;
  public filesControllerThumbnail(
    id: number,
    observe?: 'response',
    reportProgress?: boolean,
  ): Observable<HttpResponse<Blob>>;
  public filesControllerThumbnail(
    id: number,
    observe?: 'events',
    reportProgress?: boolean,
  ): Observable<HttpEvent<Blob>>;
  public filesControllerThumbnail(
    id: number,
    observe: any = 'body',
    reportProgress: boolean = false,
  ): Observable<any> {
    if (id === null || id === undefined) {
      throw new Error(
        'Required parameter id was null or undefined when calling filesControllerThumbnail.',
      );
    }

    let headers = this.defaultHeaders;

    // authentication (bearer) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === 'function'
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set('Authorization', 'Bearer ' + accessToken);
    }
    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['image/jpeg'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.request<Blob>(
      'get',
      `${this.basePath}/thumbnail/${encodeURIComponent(String(id))}`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
        responseType: 'blob' as 'json',
      },
    );
  }

  /**
   * Attached file to feedback. Only JPEG allowed.
   *
   * @param hash
   * @param file
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public filesControllerUploadFeedbackFileForm(
    hash: string,
    file: Blob,
    observe?: 'body',
    reportProgress?: boolean,
  ): Observable<any>;
  public filesControllerUploadFeedbackFileForm(
    hash: string,
    file: Blob,
    observe?: 'response',
    reportProgress?: boolean,
  ): Observable<HttpResponse<any>>;
  public filesControllerUploadFeedbackFileForm(
    hash: string,
    file: Blob,
    observe?: 'events',
    reportProgress?: boolean,
  ): Observable<HttpEvent<any>>;
  public filesControllerUploadFeedbackFileForm(
    hash: string,
    file: Blob,
    observe: any = 'body',
    reportProgress: boolean = false,
  ): Observable<any> {
    if (hash === null || hash === undefined) {
      throw new Error(
        'Required parameter hash was null or undefined when calling filesControllerUploadFeedbackFile.',
      );
    }

    if (file === null || file === undefined) {
      throw new Error(
        'Required parameter file was null or undefined when calling filesControllerUploadFeedbackFile.',
      );
    }

    let headers = this.defaultHeaders;

    // authentication (bearer) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === 'function'
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set('Authorization', 'Bearer ' + accessToken);
    }
    // to determine the Accept header
    let httpHeaderAccepts: string[] = [];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ['multipart/form-data'];

    const canConsumeForm = this.canConsumeForm(consumes);

    let formParams: { append(param: string, value: any): void };
    let useForm = false;
    let convertFormParamsToString = false;
    // use FormData to transmit files using content-type "multipart/form-data"
    // see https://stackoverflow.com/questions/4007969/application-x-www-form-urlencoded-or-multipart-form-data
    useForm = canConsumeForm;
    if (useForm) {
      formParams = new FormData();
    } else {
      formParams = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
    }

    if (hash !== undefined) {
      formParams = (formParams.append('hash', <any>hash) as any) || formParams;
    }
    if (file !== undefined) {
      formParams = (formParams.append('file', <any>file) as any) || formParams;
    }

    return this.httpClient.request<any>('post', `${this.basePath}/feedback`, {
      body: convertFormParamsToString ? formParams.toString() : formParams,
      withCredentials: this.configuration.withCredentials,
      headers: headers,
      observe: observe,
      reportProgress: reportProgress,
    });
  }

  /**
   * Attached file to plant profile/sensor. Only JPEG allowed.
   *
   * @param source
   * @param file
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public filesControllerUploadFileForm(
    source: string,
    file: Blob,
    observe?: 'body',
    reportProgress?: boolean,
  ): Observable<any>;
  public filesControllerUploadFileForm(
    source: string,
    file: Blob,
    observe?: 'response',
    reportProgress?: boolean,
  ): Observable<HttpResponse<any>>;
  public filesControllerUploadFileForm(
    source: string,
    file: Blob,
    observe?: 'events',
    reportProgress?: boolean,
  ): Observable<HttpEvent<any>>;
  public filesControllerUploadFileForm(
    source: string,
    file: Blob,
    observe: any = 'body',
    reportProgress: boolean = false,
  ): Observable<any> {
    if (source === null || source === undefined) {
      throw new Error(
        'Required parameter source was null or undefined when calling filesControllerUploadFile.',
      );
    }

    if (file === null || file === undefined) {
      throw new Error(
        'Required parameter file was null or undefined when calling filesControllerUploadFile.',
      );
    }

    let headers = this.defaultHeaders;

    // authentication (bearer) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === 'function'
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set('Authorization', 'Bearer ' + accessToken);
    }
    // to determine the Accept header
    let httpHeaderAccepts: string[] = [];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ['multipart/form-data'];

    const canConsumeForm = this.canConsumeForm(consumes);

    let formParams: { append(param: string, value: any): void };
    let useForm = false;
    let convertFormParamsToString = false;
    // use FormData to transmit files using content-type "multipart/form-data"
    // see https://stackoverflow.com/questions/4007969/application-x-www-form-urlencoded-or-multipart-form-data
    useForm = canConsumeForm;
    if (useForm) {
      formParams = new FormData();
    } else {
      formParams = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
    }

    if (source !== undefined) {
      formParams = (formParams.append('source', <any>source) as any) || formParams;
    }
    if (file !== undefined) {
      formParams = (formParams.append('file', <any>file) as any) || formParams;
    }

    return this.httpClient.request<any>('post', `${this.basePath}/upload`, {
      body: convertFormParamsToString ? formParams.toString() : formParams,
      withCredentials: this.configuration.withCredentials,
      headers: headers,
      observe: observe,
      reportProgress: reportProgress,
    });
  }
}
