<app-header></app-header>
<ion-content color="nimus-md3-green">
  <ion-card class="plants-page-card border-radius-25">
    <ion-card-content class="animate__animated animate__fadeIn animate__faster">
      <plant-list [listData]="filteredItems" [listHeaders]="tableHeaders"></plant-list>
    </ion-card-content>
  </ion-card>
  <ion-fab slot="fixed" vertical="bottom" horizontal="end">
    <ion-fab-button routerLink="add">
      <ion-icon name="add"></ion-icon>
    </ion-fab-button>
  </ion-fab>
</ion-content>
