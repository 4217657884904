import { Component, OnDestroy, OnInit } from '@angular/core';
import { SensorsService } from '../sensors.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { SensorItemDto } from '../../../../api';
import { LangService } from '../../../services/lang.service';
import { PlantProfileSelectComponent } from '../sensor-add/plant-profile-select/plant-profile-select.component';
import { ModalController, ToastController, ViewWillEnter } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { AlertService } from '../../../services/alert.service';
import { take } from 'rxjs';
import { Location } from '@angular/common';

@Component({
  selector: 'app-sensor-edit',
  templateUrl: './sensor-edit.component.html',
  styleUrls: ['./sensor-edit.component.scss'],
})
export class SensorEditComponent implements OnInit, ViewWillEnter {
  sensorForm!: FormGroup;

  sensorId: number = 0;

  editedSensor!: SensorItemDto | undefined;

  selectedPlantProfile!: any | undefined;

  selectedPlantProfileName: string = '';

  isDataFetched: boolean = false;

  profileChangedTo: number | null = null;

  intervalOptions: {
    value: string;
    name: string;
  }[] = [
    {
      value: 'none',
      name: 'SENSOR.INTERVAL_OPTION_NONE',
    },
    {
      value: '7 days',
      name: 'SENSOR.INTERVAL_OPTION_7',
    },
    {
      value: '14 days',
      name: 'SENSOR.INTERVAL_OPTION_14',
    },
    {
      value: '30 days',
      name: 'SENSOR.INTERVAL_OPTION_30',
    },
  ];

  constructor(
    private readonly sensorsService: SensorsService,
    private readonly formBuilder: FormBuilder,
    private readonly route: ActivatedRoute,
    protected readonly langService: LangService,
    private readonly modalCtrl: ModalController,
    private readonly toastCtrl: ToastController,
    private readonly router: Router,
    protected readonly translate: TranslateService,
    private alertService: AlertService,
    private location: Location,
  ) {}

  ngOnInit(): void {
    this.sensorForm = this.formBuilder.group({
      id: [0, Validators.required],
      plant_profile_id: [null],
      plant_profile_user_id: [null],
      name: ['', Validators.required],
      interval: ['', Validators.required],
    });
  }

  ionViewWillEnter(): void {
    this.route.params.pipe(take(1)).subscribe({
      next: (params) => {
        if (!this.profileChangedTo) {
          this.sensorId = params['id'];
          this.getSensor();
        } else {
          this.getUserPlantProfile(this.profileChangedTo);
        }
      },
    });
  }

  getSensor() {
    this.sensorsService.getSensorById(this.sensorId).subscribe({
      next: (res) => {
        this.editedSensor = res;
        this.sensorForm.patchValue({
          id: res.id,
          plant_profile_id: res.id_global,
          plant_profile_user_id: res.id_user,
          name: res.name,
          interval: res.interval,
        });
        if (res.id_global) {
          this.getGlobalPlantProfile(res.id_global);
        } else if (res.id_user) {
          this.getUserPlantProfile(res.id_user);
        }
      },
      error: () => {
        this.alertService.showFailureAlert('', 'Error while fetching sensor data').then();
      },
    });
  }

  getGlobalPlantProfile(id: number) {
    this.sensorsService.getGlobalPlantProfileById(id).subscribe({
      next: (res) => {
        this.selectedPlantProfileName = res.name_lang.find(
          (name: any) => name.lang === this.langService.language,
        ).value;
        this.selectedPlantProfile = res;
        this.sensorForm.patchValue({
          plant_profile_id: res.id_global,
          plant_profile_user_id: res.id_user,
        });
        this.isDataFetched = true;
      },
      error: () => {
        this.alertService.showFailureAlert('', 'Error while fetching global plant profile').then();
        this.isDataFetched = true;
      },
    });
  }

  getUserPlantProfile(id: number): void {
    this.sensorsService.getUserPlantProfileById(id).subscribe({
      next: (res) => {
        this.selectedPlantProfileName = res.name_lang.find(
          (name: any) => name.lang === this.langService.language,
        ).value;
        this.selectedPlantProfile = res;
        this.sensorForm.patchValue({
          plant_profile_id: res.id_global,
          plant_profile_user_id: res.id_user,
        });
        this.isDataFetched = true;
      },
      error: () => {
        this.alertService.showFailureAlert('', 'Error while fetching user plant profile').then();
        this.isDataFetched = true;
      },
    });
  }

  cancel() {}

  close() {
    this.router.navigate(['/sensors']).then();
    this.editedSensor = undefined;
    this.sensorForm.reset();
    this.sensorId = 0;
    this.profileChangedTo = null;
  }

  editProfile() {
    if (!!this.sensorForm.get('plant_profile_user_id')?.value) {
      this.router.navigate([
        'plant-profiles',
        `u${this.sensorForm.get('plant_profile_user_id')?.value}`,
        'edit',
      ]);
    }
  }

  save() {
    if (!this.sensorForm.get('interval')?.value) {
      this.showErrorAlert(this.translate.instant('SENSOR.INTERVAL_REQUIRED')).then();
      return;
    }

    this.sensorsService.updateSensor(this.sensorForm.value).subscribe({
      next: (res) => {
        if (res.code === 200) {
          this.showSuccessAlert(this.sensorForm.value).then();
          this.close();
        }
      },
      error: () => {
        this.alertService.showFailureAlert('', 'Error while updating sensor').then();
      },
    });
  }

  async openPlantProfilesList(event: Event) {
    event.preventDefault();

    const modal = await this.modalCtrl.create({
      component: PlantProfileSelectComponent,
      componentProps: {
        previouslySelectedItem: this.selectedPlantProfile,
      },
    });
    await modal.present();
    const { data, role } = await modal.onWillDismiss();
    if (role === 'save' && data) {
      if (data.id_global) {
        this.getGlobalPlantProfile(data.id_global);
      } else {
        this.getUserPlantProfile(data.id_user);
        this.profileChangedTo = data.id_user;
      }
    }
  }

  async showSuccessAlert(item: any) {
    const toast = await this.toastCtrl.create({
      message: `Sensor updated successfully`,
      duration: 3000,
      position: 'top',
      cssClass: 'success-alert',
      icon: 'checkmark-outline',
    });

    await toast.present();
  }

  selectInterval(event: Event) {
    const selectedInterval = (<any>event).detail.value;
    this.sensorForm.patchValue({
      interval: selectedInterval,
    });
  }

  private async showErrorAlert(error: string) {
    const toast = await this.toastCtrl.create({
      message: error,
      duration: 3000,
      position: 'top',
      cssClass: 'error-alert',
      icon: 'checkmark-outline',
    });

    await toast.present();
  }
}
