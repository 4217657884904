import { Injectable } from '@angular/core';
import { PolicyService as ApiPolicyService } from '../../../file-api';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class PolicyService {
  constructor(private readonly policyService: ApiPolicyService, private readonly http: HttpClient) {
    this.policyService = new ApiPolicyService(
      this.http,
      environment.fileApiURL,
      /* @ts-ignore */
      undefined,
    );
  }

  getFilesList() {
    return this.policyService.policyControllerGetDocumentList();
  }

  getDocument(name: string) {
    return this.policyService.policyControllerGetDocument(name);
  }
}
