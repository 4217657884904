<ion-item>
  <ion-thumbnail slot="start" (click)="openFeedbackImgModal()">
    <img
      class="border-radius-5"
      [defaultImage]="defaultImage"
      [lazyLoad]="blob"
    ><img>
  </ion-thumbnail>
  <ion-label>
    <h3>{{ item.modified_at | amLocale:langService.language | amDateFormat: 'll' }}</h3>
    <p>{{ item.height }} cm</p>
  </ion-label>
  <ion-buttons>
    <ion-button fill="clear" color="nimus_red" (click)="showDeleteModal(item)">
      <ion-icon slot="icon-only" name="trash-outline" color="nimus_red"></ion-icon>
    </ion-button>
  </ion-buttons>
</ion-item>
