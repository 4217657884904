<app-header></app-header>
<ion-content color="nimus-md3-green">
  <ion-card class="plant-edit-card border-radius-25">
    <ion-card-header class="animate__animated animate__fadeIn animate__faster">
      <ion-card-title class="ion-text-center"
        >{{ name }}</ion-card-title>
    </ion-card-header>

    <ion-card-content class="animate__animated animate__fadeIn animate__faster ion-no-padding">
      <div class="image-box ion-padding-bottom">
        <img
          id="profile-image"
          [defaultImage]="defaultImage"
          [lazyLoad]="blob"
          class="plant-image"
          alt="plant image"/>

        <ion-button
          color="primary"
          shape="round"
          size="small"
          class="image-box__image-button ion-no-padding"
          (click)="openFileDialog()"
          slot="end"
          [disabled]="blockElementFromUser()">
          <input
            type="file"
            id="file-upload"
            (change)="onFileChange($event)"
            style="display: none"/>
          <!--          <ion-icon style="padding-left: 2px" slot="icon-only" name="add-outline"></ion-icon>-->
          <ion-icon
            class="image-box__image-button__photo-icon"
            slot="icon-only"
            name="camera-outline"
          ></ion-icon>
        </ion-button>
      </div>

      <ion-list>
        <ion-item text-wrap style="padding-bottom: 16px">
          <ion-input
            class="smaller-label"
            label="{{ 'PP.EDIT.NAME' | translate }}:"
            type="text"
            placeholder="{{ 'PP.EDIT.NAME_PLACEHOLDER' | translate }}"
            [disabled]="blockElementFromUser()"
            [(ngModel)]="name">
          </ion-input>
        </ion-item>

        <ion-item text-wrap>
          <ion-textarea
            class="smaller-label"
            label="{{ 'PP.EDIT.DESCRIPTION' | translate }}: "
            autoGrow="true"
            maxlength="800"
            placeholder="{{ 'PP.EDIT.DESCRIPTION_PLACEHOLDER' | translate }}"
            [disabled]="blockElementFromUser()"
            [(ngModel)]="info">
          </ion-textarea>
        </ion-item>

        <ion-item
          button="true"
          id="open-translations-modal"
          class="ion-margin-bottom"
          [disabled]="blockElementFromUser()">
          <ion-label>{{ 'PP.EDIT.TRANSLATION' | translate }}</ion-label>
          <ion-button slot="end">
            <ion-icon name="language-outline"></ion-icon>
          </ion-button>
        </ion-item>

        <ion-item [disabled]="blockElementFromUser()">
          <ion-select
            interface="popover"
            justify="space-between"
            label="{{ 'SENSOR.INTERVAL' | translate }}"
            placeholder="{{profile.interval.toString()}}"
            [value]="profile.interval"
            (ionChange)="selectInterval($event)">
            <ion-select-option value="none">{{
              'SENSOR.INTERVAL_OPTION_NONE' | translate
            }}</ion-select-option>
            <ion-select-option value="7 days">{{
              'SENSOR.INTERVAL_OPTION_7' | translate
            }}</ion-select-option>
            <ion-select-option value="14 days">{{
              'SENSOR.INTERVAL_OPTION_14' | translate
            }}</ion-select-option>
            <ion-select-option value="30 days">{{
              'SENSOR.INTERVAL_OPTION_30' | translate
            }}</ion-select-option>
          </ion-select>
        </ion-item>

        <ion-title
          class="ion-text-center ion-padding-top"
          mode="md"
          [ngStyle]="blockElementFromUser()?{'opacity': '40%'} : {'opacity': '100%'}">{{
          'PP.EDIT.TEMP' | translate
        }}</ion-title>

        <ion-title
          *ngIf="hasOptTemp"
          class="ion-text-center ion-padding-top optimal-header"
          mode="md"
          [ngStyle]="blockElementFromUser()?{'opacity': '40%'} : {'opacity': '100%'}">
          {{'PP.OPTIMAL' | translate}} {{tempPinFormatter(profile.temp_opt!)}}
        </ion-title>

        <ion-row class="ion-padding-bottom ion-justify-content-center" mode="md">
          <ion-range
            aria-label="Temperature"
            class="plant-card-width"
            [dualKnobs]="true"
            [value]="{ lower: profile.temp_min, upper: profile.temp_max }"
            [ticks]="true"
            [snaps]="true"
            [pin]="true"
            [pinFormatter]="tempPinFormatter"
            [min]="-20"
            [max]="50"
            (ionChange)="onTempChange($event)"
            [disabled]="blockElementFromUser()"
            [ngStyle]="blockElementFromUser()?{'opacity': '40%'} : {'opacity': '100%'}">
            <ion-icon
              slot="start"
              name="thermometer-outline"
              color="primary"
              size="small">
            </ion-icon>
            <ion-icon slot="end" name="thermometer-outline" color="danger" size="large"></ion-icon>
          </ion-range>
        </ion-row>

        <ion-title
          class="ion-text-center ion-padding-top"
          mode="md"
          [ngStyle]="blockElementFromUser()?{'opacity': '40%'} : {'opacity': '100%'}">
          {{'PP.EDIT.HUMID' | translate}}
        </ion-title>

        <ion-title
          *ngIf="hasOptHum"
          class="ion-text-center ion-padding-top optimal-header"
          mode="md"
          [ngStyle]="blockElementFromUser()?{'opacity': '40%'} : {'opacity': '100%'}">
          {{'PP.OPTIMAL' | translate}} {{humidPinFormatter(profile.hum_opt!)}}
        </ion-title>

        <ion-row class="ion-justify-content-center" mode="md">
          <ion-range
            aria-label="Humidity"
            class="plant-card-width"
            [dualKnobs]="true"
            [value]="{ lower: profile.hum_min, upper: profile.hum_max }"
            [ticks]="true"
            [snaps]="true"
            [pin]="true"
            [pinFormatter]="humidPinFormatter"
            [min]="0"
            [max]="100"
            (ionChange)="onHumidChange($event)"
            [disabled]="blockElementFromUser()"
            [ngStyle]="blockElementFromUser()?{'opacity': '40%'} : {'opacity': '100%'}">
            <ion-icon slot="start" name="water-outline" color="medium" size="small"></ion-icon>
            <ion-icon slot="end" name="water-outline" color="primary" size="large"></ion-icon>
          </ion-range>
        </ion-row>
      </ion-list>

      <ion-grid>
        <ion-row class="ion-padding-top">
          <ion-col *ngIf="!blockElementFromUser()" size-xs="12" size-sm="4" class="ion-text-center">
            <ion-button
              expand="block"
              class="ion-text-wrap button-group-bottom"
              fill="solid"
              color="nimus_red"
              [disabled]="blockElementFromUser()"
              (click)="openDeleteModal(profile)">
              {{ 'PP.BUTTON.DELETE' | translate }}
            </ion-button>
          </ion-col>
          <ng-container *ngIf="blockElementFromUser(); else visibleButtons">
            <ion-col size-xs="12" size-sm="12" class="ion-text-center">
              <ion-button
                expand="block"
                class="ion-text-wrap button-group-bottom"
                fill="outline"
                color="nimus_red"
                (click)="onCancel()">
                {{ 'PP.BUTTON.CANCEL' | translate }}
              </ion-button>
            </ion-col>
          </ng-container>
          <ng-template #visibleButtons>
            <ion-col size-xs="12" size-sm="4" class="ion-text-center">
              <ion-button
                expand="block"
                class="ion-text-wrap button-group-bottom"
                fill="outline"
                color="nimus_red"
                (click)="onCancel()">
                {{ 'PP.BUTTON.CANCEL' | translate }}
              </ion-button>
            </ion-col>
            <ion-col size-xs="12" size-sm="4" class="ion-text-center">
              <ion-button
                expand="block"
                class="ion-text-wrap button-group-bottom"
                fill="solid"
                color="primary"
                [disabled]="blockElementFromUser()"
                (click)="save()">
                {{ 'PP.BUTTON.SAVE' | translate }}
              </ion-button>
            </ion-col>
          </ng-template>
        </ion-row>
      </ion-grid>
    </ion-card-content>
  </ion-card>

  <ion-modal #modal trigger="open-translations-modal">
    <ng-template>
      <ion-content>
        <ion-toolbar color="nimus-md3-green-bg" class="toolbar" mode="md">
          <ion-title size="md" style="font-size: 20px"
          >{{ 'PP.ADD.TRANSLATION' | translate }}</ion-title>
          <div class="exit-button" (click)="modal.dismiss()" slot="end">
            <ion-icon class="item-red" name="close-outline" slot="end" size="large"></ion-icon>
          </div>
        </ion-toolbar>

        <ion-row>
          <ion-col style="text-align: center; padding-bottom: 20px; padding-top: 10px">
            <h3>{{ 'LANG.LANG' | translate }} {{ getDifferentLanguageName() }}</h3>
          </ion-col>
        </ion-row>

        <ion-list>
          <ion-item text-wrap="true">
            <ion-input [clearInput]="true" [(ngModel)]="profile.name_lang[translationLanguageId].value" label="{{ 'GATEWAYS.PAGE.ELEMENT_NAME' | translate }}:" placeholder="{{ 'LANG.NAME' | translate }}"></ion-input>
          </ion-item>

          <ion-item>
            <ion-textarea maxlength="800" [(ngModel)]="profile.info_lang[translationLanguageId].value" label="{{ 'PP.EDIT.DESCRIPTION' | translate }}:" placeholder="{{ 'LANG.DESC' | translate }}"></ion-textarea>
          </ion-item>
        </ion-list>
      </ion-content>

      <ion-button
              class="ion-align-self-center"
              size="medium"
              color="primary"
              fill="solid"
              (click)="modal.dismiss()">
        {{ 'PP.BUTTON.SAVE' | translate }}
      </ion-button>
    </ng-template>
  </ion-modal>
</ion-content>
