/**
 * NIMUS CLOUD API Backend
 * NIMUS Cloud Backend Documentation
 *
 * OpenAPI spec version: 1.0.0
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface GatewayUpdateRequestDto {
  /**
   * Identification of gateway
   */
  id: number;
  /**
   * Name of gateway
   */
  name: string;
  /**
   * Latitude from GPS
   */
  latitude?: string;
  /**
   * Longitude from GPS
   */
  longitude?: string;
  /**
   * How accurate localisation is
   */
  accuracy?: string;
}
