import { Component, OnInit, ViewChild } from '@angular/core';
// eslint-disable-next-line import/no-extraneous-dependencies
import { EChartsOption } from 'echarts';
import * as moment from 'moment';
import { TranslateService } from '@ngx-translate/core';
import { LangService } from '../../../../services/lang.service';
import { SensorsService } from '../../sensors.service';
import { ActivatedRoute } from '@angular/router';
import { Components } from '@ionic/core';
import IonAccordionGroup = Components.IonAccordionGroup;
import { PlantProfileService } from '../../../plant-profiles/plant-profiles.service';
import { FeedbackSearchRequestDto, PlantUpdateRequestDto } from '../../../../../api';
import { FeedbacksService } from '../../feedbacks.service';
import { AlertService } from '../../../../services/alert.service';

// eslint-disable-next-line import/no-unresolved
@Component({
  selector: 'app-graphs',
  templateUrl: './graphs.component.html',
  styleUrls: ['./graphs.component.scss'],
})
export class GraphsComponent implements OnInit {
  // eslint-disable-next-line @typescript-eslint/no-useless-constructor
  constructor(
    public translate: TranslateService,
    public langService: LangService,
    public sensorService: SensorsService,
    public profileService: PlantProfileService,
    public route: ActivatedRoute,
    private alertService: AlertService,
    private readonly feedbacksService: FeedbacksService,
  ) {
    this.period.start = moment().subtract(1, 'months').format();
    this.period.end = moment().format();
    this.urlId = +this.route.snapshot.paramMap.get('id')!;
  }

  sensorData: any[] = [];

  emptyDatesArray: any[];

  mergedTempData: any[];

  mergedHumData: any[];

  mergedHeightData: any[] = [];

  sensorTempData: any[] = [];

  sensorHumData: any[] = [];

  sensorHeightData: any[] = [];

  maxTemp: number;

  maxHum: number;

  minTemp: number;

  minHum: number;

  sensorInfo: any;

  plantProfile: PlantUpdateRequestDto;

  sensorStartValue: number = 0;

  sensorPredictedHeightData: any[] = [];

  sensorPredictionInterval: number = 7;

  thereIsAi: boolean = false;

  isDataFetched: boolean = false;

  showSkeleton: boolean = true;

  humChartOptions!: EChartsOption;

  tempChartOptions!: EChartsOption;

  heightChartOptions!: EChartsOption;

  urlId: number;

  period: {
    start: string;
    end: string;
  } = {
    start: '',
    end: '',
  };

  listRequest: FeedbackSearchRequestDto = {
    filter: undefined,
    page_index: 0,
    page_size: 0,
    sensor_id: 0,
    sort: undefined,
    sort_direction: 'asc',
  };

  public getDateFormat(): string {
    const currentLang = this.translate.currentLang;

    switch (currentLang) {
      case 'pl':
        return 'd.MM.y, HH:mm:ss';
      case 'fr':
        return 'd/MM/y, HH:mm:ss';
      case 'en':
        return 'MM/d/y, HH:mm:ss';
    }
    return 'MM/d/y, HH:mm:ss';
  }

  ngOnInit() {
    this.period.start = moment().subtract(1, 'months').format();
    this.period.end = moment().add(3, 'weeks').format();
    moment.locale(this.langService.language);
    // console.log('s: ', this.period.start, 'e:', this.period.end);

    this.route.params.subscribe({
      next: (params) => {
        this.sensorService.getSensorById(params['id']).subscribe({
          next: (res) => {
            this.sensorInfo = res;
            this.updateGraphData().then();
            this.getPlantProfile();
          },
          error: () => {
            this.alertService.showFailureAlert('', 'Error while fetching sensor').then();
          },
        });
      },
    });
  }

  setPeriodStart(event: any) {
    this.period.start = event.detail.value;
    this.updateGraphData().then();
    // console.log('s: ', this.period.start, 'e:', this.period.end);
  }

  setPeriodEnd(event: any) {
    this.period.end = event.detail.value;
    this.updateGraphData().then();
    // console.log('s: ', this.period.start, 'e:', this.period.end);
  }

  createArrayOfEmptyDates(startDate: string, endDate: string) {
    const dateArray = [];
    const currentDate = new Date(startDate);
    const endDateObj = new Date(endDate);

    while (currentDate <= endDateObj) {
      dateArray.push({
        value: null,
        created_at: currentDate.toISOString(),
        measure_type_id: 1,
      });
      // Create an empty object for every day between start date and end date
      // currentDate.setDate(currentDate.getDate() + 1);

      // Create an empty object for every hour of every day between start date and end date
      currentDate.setHours(currentDate.getHours() + 1);
    }
    this.emptyDatesArray = dateArray;
    this.mergedTempData = dateArray;
    this.mergedHumData = dateArray;
    this.mergedHeightData = dateArray;
    this.sensorPredictedHeightData = dateArray;
  }

  async getPlantProfile() {
    let id: string;
    if (this.sensorInfo.id_global && !this.sensorInfo.id_user) {
      id = 'g' + String(this.sensorInfo.id_global);
    } else if (this.sensorInfo.id_user) {
      id = 'u' + String(this.sensorInfo.id_user);
    }

    try {
      const res: PlantUpdateRequestDto = await new Promise((resolve, reject) => {
        this.profileService.getProfile(id).subscribe({
          next: (profile: PlantUpdateRequestDto) => {
            resolve(profile);
          },
          error: (err: any) => {
            reject(err);
          },
        });
      });

      this.plantProfile = res;
    } catch (err: any) {
      await this.alertService.showFailureAlert(
        err.status,
        'Error while searching for plant profile',
      );
    }
  }

  async getSensorData(): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this.sensorService
        .getSensorDataById(
          {
            page_index: 0,
            page_size: 10000,
            sort_direction: 'asc',
            filter: [],
            date_from: this.period.start,
            date_to: this.period.end,
            search_new: false,
          },
          this.sensorInfo.id,
        )
        .subscribe({
          next: (response) => {
            this.sensorData = response.data;
            this.isDataFetched = true;
            this.createArrayOfEmptyDates(this.period.start, this.period.end);
            this.getAiPredictions(this.urlId);
            resolve();
          },
          error: (err: any) => {
            this.alertService
              .showFailureAlert(err.message, 'Error while requesting sensor data')
              .then();
            reject();
          },
        });
    });
  }

  async getHeightData(): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this.sensorHeightData = [];

      this.listRequest.page_size = 10000;
      this.listRequest.sensor_id = this.sensorInfo.id;
      this.listRequest.sort = ['modified_at'];
      this.listRequest.sort_direction = 'asc';
      this.listRequest.filter = [
        { field: 'modified_at', operator: '>', value: String(this.period.start) },
        { field: 'modified_at', operator: '<', value: String(this.period.end) },
      ];

      if (!this.sensorInfo.id) {
        return;
      }
      this.feedbacksService.getFeedbacksSortList(this.listRequest).subscribe({
        next: (response: any) => {
          this.sensorHeightData = response.data.map((item: any) => ({
            value: item.height,
            created_at: item.modified_at,
            measure_type_id: 1,
          }));
          resolve();
        },
        error: (err: any) => {
          this.alertService
            .showFailureAlert(err.message, 'Error while requesting sensor height data')
            .then();
          reject();
        },
      });
    });
  }

  async updateGraphData(): Promise<void> {
    await Promise.all([this.getPlantProfile(), this.getHeightData(), this.getSensorData()]);
    this.sensorTempData = this.sensorData.filter((item) => item.measure_type_id === 1);
    this.sensorHumData = this.sensorData.filter((item) => item.measure_type_id === 2);
    this.mergedTempData = [...this.mergedTempData, ...this.sensorTempData];
    this.mergedHumData = [...this.mergedHumData, ...this.sensorHumData];
    this.mergedHeightData = [...this.mergedHeightData, ...this.sensorHeightData];
    this.sortArrayByDate(this.mergedTempData);
    this.sortArrayByDate(this.mergedHumData);
    this.sortArrayByDate(this.mergedHeightData);
    this.findMinMaxValues(this.mergedTempData);
    this.findMinMaxValues(this.mergedHumData);
    setTimeout(() => {
      this.showSkeleton = false;
      this.drawTemperatureGraph();
      this.drawHumidityGraph();
      this.drawHeightGraph();
    }, 10);
  }

  sortArrayByDate(array: any[]) {
    array.sort((a, b) => {
      const dateA = new Date(a.created_at);
      const dateB = new Date(b.created_at);
      // @ts-ignore
      return dateA - dateB;
    });
  }

  findMinMaxValues(array: any[]) {}

  // fillMissingValuesAI(data: any[]): any[] {
  //   const filledData: any[] = [];
  //   let previousNonNullValue = 0;
  //
  //   data.forEach((item) => {
  //     if (item.value > previousNonNullValue) {
  //       previousNonNullValue = item.value;
  //     } else {
  //       item.value = previousNonNullValue;
  //     }
  //     console.log('1 item: ', item.value, 'previous: ', previousNonNullValue);
  //     if (item.value === 0) {
  //       item.value = null;
  //     }
  //     filledData.push(item);
  //   });
  //   console.log('filled Data: ', filledData);
  //   return filledData;
  // }

  drawTemperatureGraph() {
    this.tempChartOptions = {
      tooltip: {
        confine: true,
        trigger: 'axis',
        axisPointer: {
          type: 'shadow',
        },
        formatter: (params, unit: string) => {
          let label = this.tooltipFormatter(params, '°C');
          return label.includes('undefined')
            ? label.replace('undefined°C', this.translate.instant('NO_DATA'))
            : label;
        },
      },
      grid: {
        top: '5%',
      },
      xAxis: {
        data: this.mergedTempData.map((item) => item.created_at),
        axisLabel: {
          interval: 'auto',
          formatter: function (params: any) {
            let date = new Date(params);
            return moment(date).format('ll');
          },
        },
      },
      yAxis: {
        type: 'value',
        // min: Math.ceil(this.plantProfile.temp_min / 10) * 10 - 15,
        // max: Math.ceil(this.plantProfile.temp_max / 10) * 10 + 5,
      },
      toolbox: {
        right: 10,
        feature: {
          restore: {},
          saveAsImage: {},
        },
      },
      dataZoom: [
        {
          textStyle: { color: 'rgba(255,255,255,0)', fontSize: 0, show: false, position: 'inside' },
        },
        {
          type: 'inside',
        },
      ],
      series: {
        name: 'Temperature',
        type: 'line',
        data: this.mergedTempData.map((item) => item.value),
        color: '#FF4961FF',
        connectNulls: true,
        markLine: {
          symbol: ['none', 'none'],
          data: [
            {
              yAxis: this.plantProfile.temp_min,
              lineStyle: { color: '#49bfff' },
            },
            {
              yAxis: this.plantProfile.temp_max,
              lineStyle: { color: '#ff494f' },
            },
          ],
        },
      },
    };
  }

  drawHumidityGraph() {
    this.humChartOptions = {
      tooltip: {
        confine: true,
        trigger: 'axis',
        axisPointer: {
          type: 'shadow',
        },
        formatter: (params, unit: string) => {
          let label = this.tooltipFormatter(params, '%');
          return label.includes('undefined')
            ? label.replace('undefined%', this.translate.instant('NO_DATA'))
            : label;
        },
      },
      grid: {
        top: '5%',
      },
      xAxis: {
        type: 'category',
        data: this.mergedHumData.map((item) => item.created_at),
        axisLabel: {
          formatter: function (params: any) {
            let date = new Date(params);
            return moment(date).format('ll');
          },
        },
      },
      yAxis: {
        type: 'value',
        // uncomment to make graph scale go from ~min to ~max default profile values, no matter the real ones (anomalies are cut off)
        // min: Math.ceil(this.plantProfile.hum_min / 10) * 10 - 15,
        // max: Math.ceil(this.plantProfile.hum_max / 10) * 10 + 10,
      },
      toolbox: {
        right: 10,
        feature: {
          restore: {},
          saveAsImage: {},
        },
      },
      dataZoom: [
        {
          type: 'inside',
        },
        {
          textStyle: { color: 'rgba(255,255,255,0)', fontSize: 0, show: false, position: 'inside' },
        },
      ],
      series: {
        name: 'Temperature',
        data: this.mergedHumData.map((item) => item.value),
        type: 'line',
        color: '#4099FF',
        connectNulls: true,
        markLine: {
          symbol: ['none', 'none'],
          data: [
            {
              yAxis: this.plantProfile.hum_min,
              lineStyle: { color: '#49bfff' },
            },
            {
              yAxis: this.plantProfile.hum_max,
              lineStyle: { color: '#ff494f' },
            },
          ],
        },
      },
    };
  }

  drawHeightGraph() {
    if (this.sensorHeightData.length === 0) {
      return;
    }
    this.sensorStartValue = this.sensorHeightData[0].value;
    // uncomment to make graph scale go from ~min to ~max default profile values, no matter the real ones (anomalies are cut off)
    // this.mergedHeightData = this.fillMissingValuesHeight(this.mergedHeightData);
    // this.sensorPredictedHeightData = this.fillMissingValuesAI(this.sensorPredictedHeightData);
    this.heightChartOptions = {
      tooltip: {
        confine: false,
        trigger: 'axis',
        axisPointer: {
          type: 'cross',
          snap: true,
        },
        formatter: function (params: any) {
          let tooltip =
            '<strong>' + moment(params[0].axisValueLabel).format('DD.MM.YYYY') + '</strong><br/>';
          //   params.forEach((param: any) => {
          //     if (param.seriesName === 'Measured height' && param.value !== null) {
          //       tooltip += 'Measured height: ' + param.value + '<br/>';
          //     }
          //     if (param.seriesName === 'Predicted height' && param.value !== null) {
          //       console.log(param);
          //       tooltip += 'Predicted height: ' + param.value + '<br/>';
          //     }
          //   });
          return tooltip;
        },
      },
      grid: {
        top: '5%',
      },
      xAxis: {
        boundaryGap: false,
        axisPointer: {
          snap: true,
          label: {
            show: false,
          },
        },
        type: 'category',
        data: this.mergedHeightData.map((item) => item.created_at),
        axisLabel: {
          formatter: function (params: any) {
            let date = new Date(params);
            return moment(date).format('ll');
          },
        },
      },

      yAxis: {
        axisPointer: {
          snap: true,
          label: {},
        },
        type: 'value',
      },
      toolbox: {
        right: 10,
        feature: {
          restore: {},
          saveAsImage: {},
        },
      },
      dataZoom: [
        {
          textStyle: { color: 'rgba(255,255,255,0)', fontSize: 0, show: false, position: 'inside' },
        },
        {
          type: 'inside',
        },
      ],
      series: [
        {
          name: 'Measured height',
          data: this.mergedHeightData.map((item) => item.value),
          type: 'line',
          connectNulls: true,
          showAllSymbol: true,
          color: '#2dd36f',
        },
        {
          name: 'Predicted height',
          type: 'line',
          color: '#bbd034',
          connectNulls: true,
          showAllSymbol: true,
          data: this.sensorPredictedHeightData.map((item) => item.value),
        },
      ],
    };
  }

  tooltipFormatter(params: any, unit: string) {
    {
      let date = new Date(params[0].axisValue);
      return (
        moment(date).format('LL') +
        ', ' +
        moment(date).format('LT') +
        '<br>' +
        '<b>' +
        `<div style="color:${params[0].color}">` +
        params[0].value +
        unit +
        '</div>' +
        '</b>'
      );
    }
  }

  getAiPredictions(sensorId: number) {
    this.sensorService.getPredictionData(sensorId).subscribe({
      next: (res: any) => {
        let predictionArray = res.map((item: any) => ({
          value: item.height,
          created_at: new Date(item.date).toISOString(),
          measure_type_id: 1,
        }));
        this.sensorPredictedHeightData = [...this.sensorPredictedHeightData, ...predictionArray];
        // this.sensorPredictedHeightData = predictionArray;
        this.sortArrayByDate(this.sensorPredictedHeightData);
        this.drawHeightGraph();
        if (this.sensorPredictedHeightData.length > 0) {
          this.thereIsAi = true;
        }
      },
      error: (err: any) => {
        this.alertService.showFailureAlert(err.status, 'Error while requesting AI data').then();
      },
    });
  }

  // processPredictionData(data: any) {
  //   let innerCount = 1;
  //   let innerSum = 0;
  //   let outerSum = 0;
  //
  //   while (innerCount < data.length) {
  //     innerSum += data[innerCount].growth_per_30;
  //     if (innerCount % (24 * this.sensorPredictionInterval) === 0) {
  //       outerSum += +(innerSum / (24 * this.sensorPredictionInterval)).toFixed(3);
  //       this.sensorPredictedHeightData.push({
  //         date: data[innerCount].created_at,
  //         growth: outerSum,
  //       });
  //       innerSum = 0;
  //       this.thereIsAi = true;
  //     }
  //     innerCount += 1;
  //   }
  // }

  @ViewChild('accordionDateFrom', { static: true }) accordionDateFrom: IonAccordionGroup;

  @ViewChild('accordionDateTo', { static: true }) accordionDateTo: IonAccordionGroup;

  toggleStartAccordion = () => {
    const nativeEl = this.accordionDateFrom;
    if (nativeEl.value === this.period.start) {
      nativeEl.value = undefined;
    } else {
      nativeEl.value = this.period.start;
    }
  };

  toggleEndAccordion = () => {
    const nativeEl = this.accordionDateTo;
    if (nativeEl.value === this.period.end) {
      nativeEl.value = undefined;
    } else {
      nativeEl.value = this.period.end;
    }
  };
}
