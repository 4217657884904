/**
 * NIMUS CLOUD API Backend
 * NIMUS Cloud Backend Documentation
 *
 * OpenAPI spec version: 1.0.0
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface FeedbackItemDto {
  /**
   * Identification of feedback
   */
  id?: number;
  /**
   * Hash identification of feedback
   */
  prepared_hash?: string;
  /**
   * Reference to sensor
   */
  sensor_id?: number;
  /**
   * Reference to photo (optional)
   */
  file_id?: number;
  /**
   * Height of the plant
   */
  height: number;
  /**
   * Creation time
   */
  created_at?: string;
  /**
   * Modification time - when feedback was filled
   */
  modified_at?: string;
}
