<ion-header>
  <ion-toolbar class="ion-text-center">
    <ion-title>{{ 'LOGOUT.MODAL.TITLE' | translate }}</ion-title>
  </ion-toolbar>
</ion-header>
<ion-content class="logout-modal">
  <ion-list>
    <ion-item lines="none">
      <ion-label>{{ 'LOGOUT.MODAL.LABEL' | translate }}</ion-label>
    </ion-item>
  </ion-list>
  <ion-row class="ion-justify-content-center">
    <ion-col size="auto">
      <ion-button
        class="ion-align-self-center"
        size="medium"
        color="nimus_red"
        fill="outline"
        (click)="dismiss()"
      >{{ 'LOGOUT.BUTTON.CANCEL' | translate }}</ion-button>

      <ion-button
        class="ion-align-self-center ion-margin-start"
        size="medium"
        fill="solid"
        color="primary"
        (click)="logout()"
      >{{ 'LOGOUT.BUTTON.LOGOUT' | translate }}</ion-button>
    </ion-col>
  </ion-row>
</ion-content>
