import { Component, DestroyRef, inject } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { ActivatedRoute, Router } from '@angular/router';
import { SensorsService } from '../sensors.service';
import { FeedbackItemDto, SensorDataItemDto, SensorItemDto } from '../../../../api';
import { FeedbacksService } from '../feedbacks.service';
import { AlertService } from '../../../services/alert.service';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
  selector: 'app-history',
  templateUrl: './history.component.html',
  styleUrls: ['./history.component.scss'],
})
export class HistoryComponent {
  sensor: SensorItemDto = {
    id: 0,
    interval: 'none',
  };

  isDataFetched: boolean = false;

  sensorData: SensorDataItemDto[] = [];

  segment: string = 'graphs';

  feedbackList: FeedbackItemDto[];

  private destroyRef = inject(DestroyRef);

  constructor(
    private modalCtrl: ModalController,
    private readonly route: ActivatedRoute,
    private readonly sensorsService: SensorsService,
    private alertService: AlertService,
    private readonly feedbacksService: FeedbacksService,
    private readonly router: Router,
  ) {
    this.route.params.subscribe({
      next: (params) => {
        this.sensorsService.getSensorById(params['id']).subscribe({
          next: (res) => {
            this.sensor = res;
            this.getFeedbackList();
            this.getSensorData();
          },
          error: () => {
            this.alertService.showFailureAlert('', 'Error while fetching sensor').then();
          },
        });
      },
    });
    const routerState: any = this.router.getCurrentNavigation()?.extras.state;
    this.segment = routerState?.[0] ?? 'graphs';
    this.feedbacksService.feedbacks.pipe(takeUntilDestroyed(this.destroyRef)).subscribe({
      next: (res) => {
        this.feedbackList = res;
      },
    });
  }

  getFeedbackList() {
    this.feedbacksService.getFeedbackBySensorId(this.sensor.id);
  }

  getSensorData() {
    this.sensorsService
      .getSensorDataById(
        {
          page_index: 0,
          page_size: 10000,
          sort_direction: 'asc',
          filter: [],
          search_new: false,
        },
        this.sensor.id,
      )
      .subscribe({
        next: (response) => {
          this.sensorData = response.data;
          this.isDataFetched = true;
        },
        error: () => {
          this.alertService.showFailureAlert('', 'Error while fetching sensors data').then();
        },
      });
  }

  async openFeedback() {
    this.router.navigateByUrl(`/sensors/${this.sensor.id}/leave-feedback`).then();
  }

  close() {
    return this.modalCtrl.dismiss(null, 'close');
  }

  showSegment(event: any) {
    this.segment = event.detail.value;
  }
}
