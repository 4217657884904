/**
 * NIMUS CLOUD API Backend
 * NIMUS Cloud Backend Documentation
 *
 * OpenAPI spec version: 1.0.0
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface PlantSearchItemResponseDto {
  /**
   * Plant profile id - if user
   */
  id_user?: number;
  /**
   * Plant profile id - if global
   */
  id_global?: number;
  /**
   * Minimal temperature
   */
  temp_min: number;
  /**
   * Maximal temperature
   */
  temp_max: number;
  /**
   * Optimal temperature
   */
  temp_opt?: number;
  /**
   * Minimal humidity
   */
  hum_min: number;
  /**
   * maximal humidity
   */
  hum_max: number;
  /**
   * Optimal humidity
   */
  hum_opt?: number;
  /**
   * Reference id to table which contains photos (Optional). Only for new user plant.
   */
  file_id?: number;
  /**
   * Name translation
   */
  name: string;
  /**
   * Info translation
   */
  info: string;
}
