/**
 * NIMUS CLOUD API Backend
 * NIMUS Cloud Backend Documentation
 *
 * OpenAPI spec version: 1.0.0
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface SensorUpdateDto {
  /**
   * Sensor id, for adding new must be 0
   */
  id: number;
  /**
   * Plant profile id
   */
  plant_profile_id?: number;
  /**
   * Plant profile user id
   */
  plant_profile_user_id?: number;
  /**
   * Name of sensor
   */
  name: string;
  /**
   * Feedback interval
   */
  interval: string;
}
