/**
 * NIMUS CLOUD API Backend
 * NIMUS Cloud Backend Documentation
 *
 * OpenAPI spec version: 1.0.0
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface SensorsSearchResponseItemDto {
  /**
   * Sensor id, for adding new must be 0
   */
  id: number;
  /**
   * Plant profile id
   */
  plant_profile_id?: number;
  /**
   * Name of sensor
   */
  name?: string;
  /**
   * Battery level of sensor in percentage
   */
  battery_level?: number;
  /**
   * Last connected time
   */
  last_connected?: string;
  /**
   * When sensor was created
   */
  created_at?: string;
  /**
   * Feedback interval
   */
  interval: string;
  /**
   * Serial number of sensor
   */
  serial_number?: string;
  /**
   * Sensor type
   */
  type?: string;
  /**
   * Current temperature
   */
  temp?: number;
  /**
   * Current humidity
   */
  hum?: number;
  /**
   * Does sensor have prediction data
   */
  ai?: boolean;
  /**
   * Information about Gateway
   */
  gateway_name?: string;
}
