import { Component, OnInit } from '@angular/core';
import { PlantProfileService } from '../plant-profiles.service';
import { filter } from 'rxjs';
import { NavigationStart, Router } from '@angular/router';

@Component({
  selector: 'app-plant-profiles',
  templateUrl: './plant-profiles.component.html',
  styleUrls: ['./plant-profiles.component.scss'],
})
export class PlantProfilesComponent implements OnInit {
  tableHeaders: {
    name: string;
    value: string;
  }[] = [
    {
      name: 'PP.EDIT.NAME',
      value: 'profile',
    },
  ];

  actions: string[] = ['edit', 'delete'];

  filteredItems: any = this.plantProfilesService.filteredItems;

  constructor(public plantProfilesService: PlantProfileService, private router: Router) {}

  ngOnInit() {
    this.router.events
      .pipe(filter((event) => event instanceof NavigationStart))
      // @ts-ignore
      .subscribe((event: NavigationStart) => {
        // if (event.url === '/plant-profiles') {
        //
        // }
      });
  }
}
