import { NgModule } from '@angular/core';
import { CommonModule, NgOptimizedImage } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { RouterLink, RouterLinkActive, RouterOutlet } from '@angular/router';
import { TabsComponent } from './components/tabs/tabs.component';
import { ListComponent } from './components/list/list.component';
import { SelectComponent } from './components/select/select.component';
import { SortComponent } from './components/sort/sort.component';
import { LanguageButtonComponent } from './components/language-button/language-button.component';
import { TranslateModule } from '@ngx-translate/core';
import { HeaderComponent } from './components/header/header.component';
import { LogoutButtonComponent } from './components/logout-button/logout-button.component';
import { LogoutModalComponent } from './components/logout-modal/logout-modal.component';
import { DateAgoPipe } from './date-ago.pipe';

@NgModule({
  declarations: [
    TabsComponent,
    ListComponent,
    SelectComponent,
    SortComponent,
    LanguageButtonComponent,
    HeaderComponent,
    LogoutButtonComponent,
    LogoutModalComponent,
    DateAgoPipe,
  ],
  imports: [
    CommonModule,
    IonicModule,
    NgOptimizedImage,
    RouterLink,
    RouterLinkActive,
    RouterOutlet,
    TranslateModule,
  ],
  exports: [
    TabsComponent,
    ListComponent,
    SelectComponent,
    SortComponent,
    LanguageButtonComponent,
    HeaderComponent,
    DateAgoPipe,
  ],
})
export class SharedModule {}
