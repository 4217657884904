/**
 * NIMUS CLOUD API Backend
 * NIMUS Cloud Backend Documentation
 *
 * OpenAPI spec version: 1.0.0
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */ /* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams, HttpResponse, HttpEvent } from '@angular/common/http';
import { CustomHttpUrlEncodingCodec } from '../encoder';

import { Observable } from 'rxjs';

import { PredictionResponseDto } from '../model/predictionResponseDto';
import { SensorDataResponseDto } from '../model/sensorDataResponseDto';
import { SensorItemDto } from '../model/sensorItemDto';
import { SensorUpdateDto } from '../model/sensorUpdateDto';
import { SensorsSearchRequestDto } from '../model/sensorsSearchRequestDto';
import { SensorsSearchResponseDto } from '../model/sensorsSearchResponseDto';
import { SuccessDto } from '../model/successDto';

import { BASE_PATH, COLLECTION_FORMATS } from '../variables';
import { Configuration } from '../configuration';

@Injectable()
export class SensorsService {
  protected basePath = 'http://127.0.0.1:3000/api';

  public defaultHeaders = new HttpHeaders();

  public configuration = new Configuration();

  constructor(
    protected httpClient: HttpClient,
    @Optional() @Inject(BASE_PATH) basePath: string,
    @Optional() configuration: Configuration,
  ) {
    if (basePath) {
      this.basePath = basePath;
    }
    if (configuration) {
      this.configuration = configuration;
      this.basePath = basePath || configuration.basePath || this.basePath;
    }
  }

  /**
   * @param consumes string[] mime-types
   * @return true: consumes contains 'multipart/form-data', false: otherwise
   */
  private canConsumeForm(consumes: string[]): boolean {
    const form = 'multipart/form-data';
    for (const consume of consumes) {
      if (form === consume) {
        return true;
      }
    }
    return false;
  }

  /**
   * Cleanup non-paired sensors
   *
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public sensorsControllerCleanupSensor(
    observe?: 'body',
    reportProgress?: boolean,
  ): Observable<SuccessDto>;
  public sensorsControllerCleanupSensor(
    observe?: 'response',
    reportProgress?: boolean,
  ): Observable<HttpResponse<SuccessDto>>;
  public sensorsControllerCleanupSensor(
    observe?: 'events',
    reportProgress?: boolean,
  ): Observable<HttpEvent<SuccessDto>>;
  public sensorsControllerCleanupSensor(
    observe: any = 'body',
    reportProgress: boolean = false,
  ): Observable<any> {
    let headers = this.defaultHeaders;

    // authentication (bearer) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === 'function'
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set('Authorization', 'Bearer ' + accessToken);
    }
    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.request<SuccessDto>('get', `${this.basePath}/sensors/cleanup`, {
      withCredentials: this.configuration.withCredentials,
      headers: headers,
      observe: observe,
      reportProgress: reportProgress,
    });
  }

  /**
   * Sensors search
   *
   * @param body
   * @param sensor_id
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public sensorsControllerDataSearch(
    body: SensorsSearchRequestDto,
    sensor_id: number,
    observe?: 'body',
    reportProgress?: boolean,
  ): Observable<SensorDataResponseDto>;
  public sensorsControllerDataSearch(
    body: SensorsSearchRequestDto,
    sensor_id: number,
    observe?: 'response',
    reportProgress?: boolean,
  ): Observable<HttpResponse<SensorDataResponseDto>>;
  public sensorsControllerDataSearch(
    body: SensorsSearchRequestDto,
    sensor_id: number,
    observe?: 'events',
    reportProgress?: boolean,
  ): Observable<HttpEvent<SensorDataResponseDto>>;
  public sensorsControllerDataSearch(
    body: SensorsSearchRequestDto,
    sensor_id: number,
    observe: any = 'body',
    reportProgress: boolean = false,
  ): Observable<any> {
    if (body === null || body === undefined) {
      throw new Error(
        'Required parameter body was null or undefined when calling sensorsControllerDataSearch.',
      );
    }

    if (sensor_id === null || sensor_id === undefined) {
      throw new Error(
        'Required parameter sensor_id was null or undefined when calling sensorsControllerDataSearch.',
      );
    }

    let headers = this.defaultHeaders;

    // authentication (bearer) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === 'function'
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set('Authorization', 'Bearer ' + accessToken);
    }
    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected: string | undefined =
      this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected != undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    return this.httpClient.request<SensorDataResponseDto>(
      'post',
      `${this.basePath}/sensors/data/${encodeURIComponent(String(sensor_id))}`,
      {
        body: body,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      },
    );
  }

  /**
   * Sensor item delete
   *
   * @param sensor_id
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public sensorsControllerDelete(
    sensor_id: number,
    observe?: 'body',
    reportProgress?: boolean,
  ): Observable<SuccessDto>;
  public sensorsControllerDelete(
    sensor_id: number,
    observe?: 'response',
    reportProgress?: boolean,
  ): Observable<HttpResponse<SuccessDto>>;
  public sensorsControllerDelete(
    sensor_id: number,
    observe?: 'events',
    reportProgress?: boolean,
  ): Observable<HttpEvent<SuccessDto>>;
  public sensorsControllerDelete(
    sensor_id: number,
    observe: any = 'body',
    reportProgress: boolean = false,
  ): Observable<any> {
    if (sensor_id === null || sensor_id === undefined) {
      throw new Error(
        'Required parameter sensor_id was null or undefined when calling sensorsControllerDelete.',
      );
    }

    let headers = this.defaultHeaders;

    // authentication (bearer) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === 'function'
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set('Authorization', 'Bearer ' + accessToken);
    }
    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.request<SuccessDto>(
      'delete',
      `${this.basePath}/sensors/${encodeURIComponent(String(sensor_id))}`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      },
    );
  }

  /**
   * Sensor item get
   *
   * @param sensor_id
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public sensorsControllerGet(
    sensor_id: number,
    observe?: 'body',
    reportProgress?: boolean,
  ): Observable<SensorItemDto>;
  public sensorsControllerGet(
    sensor_id: number,
    observe?: 'response',
    reportProgress?: boolean,
  ): Observable<HttpResponse<SensorItemDto>>;
  public sensorsControllerGet(
    sensor_id: number,
    observe?: 'events',
    reportProgress?: boolean,
  ): Observable<HttpEvent<SensorItemDto>>;
  public sensorsControllerGet(
    sensor_id: number,
    observe: any = 'body',
    reportProgress: boolean = false,
  ): Observable<any> {
    if (sensor_id === null || sensor_id === undefined) {
      throw new Error(
        'Required parameter sensor_id was null or undefined when calling sensorsControllerGet.',
      );
    }

    let headers = this.defaultHeaders;

    // authentication (bearer) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === 'function'
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set('Authorization', 'Bearer ' + accessToken);
    }
    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.request<SensorItemDto>(
      'get',
      `${this.basePath}/sensors/${encodeURIComponent(String(sensor_id))}`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      },
    );
  }

  /**
   * Get growth details of sensor
   *
   * @param sensor_id
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public sensorsControllerGetGrowthDetails(
    sensor_id: number,
    observe?: 'body',
    reportProgress?: boolean,
  ): Observable<any>;
  public sensorsControllerGetGrowthDetails(
    sensor_id: number,
    observe?: 'response',
    reportProgress?: boolean,
  ): Observable<HttpResponse<any>>;
  public sensorsControllerGetGrowthDetails(
    sensor_id: number,
    observe?: 'events',
    reportProgress?: boolean,
  ): Observable<HttpEvent<any>>;
  public sensorsControllerGetGrowthDetails(
    sensor_id: number,
    observe: any = 'body',
    reportProgress: boolean = false,
  ): Observable<any> {
    if (sensor_id === null || sensor_id === undefined) {
      throw new Error(
        'Required parameter sensor_id was null or undefined when calling sensorsControllerGetGrowthDetails.',
      );
    }

    let headers = this.defaultHeaders;

    // authentication (bearer) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === 'function'
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set('Authorization', 'Bearer ' + accessToken);
    }
    // to determine the Accept header
    let httpHeaderAccepts: string[] = [];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.request<any>(
      'get',
      `${this.basePath}/sensors/growth/${encodeURIComponent(String(sensor_id))}`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      },
    );
  }

  /**
   * Get prediction details of sensor
   *
   * @param sensor_id
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public sensorsControllerGetPredictionData(
    sensor_id: number,
    observe?: 'body',
    reportProgress?: boolean,
  ): Observable<PredictionResponseDto>;
  public sensorsControllerGetPredictionData(
    sensor_id: number,
    observe?: 'response',
    reportProgress?: boolean,
  ): Observable<HttpResponse<PredictionResponseDto>>;
  public sensorsControllerGetPredictionData(
    sensor_id: number,
    observe?: 'events',
    reportProgress?: boolean,
  ): Observable<HttpEvent<PredictionResponseDto>>;
  public sensorsControllerGetPredictionData(
    sensor_id: number,
    observe: any = 'body',
    reportProgress: boolean = false,
  ): Observable<any> {
    if (sensor_id === null || sensor_id === undefined) {
      throw new Error(
        'Required parameter sensor_id was null or undefined when calling sensorsControllerGetPredictionData.',
      );
    }

    let headers = this.defaultHeaders;

    // authentication (bearer) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === 'function'
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set('Authorization', 'Bearer ' + accessToken);
    }
    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.request<PredictionResponseDto>(
      'get',
      `${this.basePath}/sensors/prediction/${encodeURIComponent(String(sensor_id))}`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      },
    );
  }

  /**
   * Sensors search
   *
   * @param body
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public sensorsControllerSearch(
    body: SensorsSearchRequestDto,
    observe?: 'body',
    reportProgress?: boolean,
  ): Observable<SensorsSearchResponseDto>;
  public sensorsControllerSearch(
    body: SensorsSearchRequestDto,
    observe?: 'response',
    reportProgress?: boolean,
  ): Observable<HttpResponse<SensorsSearchResponseDto>>;
  public sensorsControllerSearch(
    body: SensorsSearchRequestDto,
    observe?: 'events',
    reportProgress?: boolean,
  ): Observable<HttpEvent<SensorsSearchResponseDto>>;
  public sensorsControllerSearch(
    body: SensorsSearchRequestDto,
    observe: any = 'body',
    reportProgress: boolean = false,
  ): Observable<any> {
    if (body === null || body === undefined) {
      throw new Error(
        'Required parameter body was null or undefined when calling sensorsControllerSearch.',
      );
    }

    let headers = this.defaultHeaders;

    // authentication (bearer) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === 'function'
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set('Authorization', 'Bearer ' + accessToken);
    }
    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected: string | undefined =
      this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected != undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    return this.httpClient.request<SensorsSearchResponseDto>(
      'post',
      `${this.basePath}/sensors/search`,
      {
        body: body,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      },
    );
  }

  /**
   * Update exist sensor
   *
   * @param body
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public sensorsControllerUpdate(
    body: SensorUpdateDto,
    observe?: 'body',
    reportProgress?: boolean,
  ): Observable<SuccessDto>;
  public sensorsControllerUpdate(
    body: SensorUpdateDto,
    observe?: 'response',
    reportProgress?: boolean,
  ): Observable<HttpResponse<SuccessDto>>;
  public sensorsControllerUpdate(
    body: SensorUpdateDto,
    observe?: 'events',
    reportProgress?: boolean,
  ): Observable<HttpEvent<SuccessDto>>;
  public sensorsControllerUpdate(
    body: SensorUpdateDto,
    observe: any = 'body',
    reportProgress: boolean = false,
  ): Observable<any> {
    if (body === null || body === undefined) {
      throw new Error(
        'Required parameter body was null or undefined when calling sensorsControllerUpdate.',
      );
    }

    let headers = this.defaultHeaders;

    // authentication (bearer) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === 'function'
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set('Authorization', 'Bearer ' + accessToken);
    }
    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected: string | undefined =
      this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected != undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    return this.httpClient.request<SuccessDto>('put', `${this.basePath}/sensors`, {
      body: body,
      withCredentials: this.configuration.withCredentials,
      headers: headers,
      observe: observe,
      reportProgress: reportProgress,
    });
  }
}
