import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthGuard } from '@auth0/auth0-angular';
import { AuthComponent } from './pages/auth/auth.component';
import { TabsComponent } from './shared/components/tabs/tabs.component';
import { LeaveFeedbackComponent } from './pages/sensors/leave-feedback/leave-feedback.component';
import { PolicyComponent } from './pages/policy/policy.component';

const routes: Routes = [
  {
    path: 'unlogged/feedback/:id',
    component: LeaveFeedbackComponent,
    pathMatch: 'full',
    data: { internal: false },
  },
  {
    path: 'policy',
    component: PolicyComponent,
    pathMatch: 'full',
  },
  {
    path: 'file-api/policy/get/:id',
    redirectTo: 'file-api/policy/get/:id',
    pathMatch: 'full',
  },
  {
    path: '',
    redirectTo: 'auth',
    pathMatch: 'full',
  },
  {
    path: 'auth',
    component: AuthComponent,
  },
  {
    path: '',
    component: TabsComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: '',
        pathMatch: 'full',
        redirectTo: '/sensors',
      },
      {
        path: 'profile',
        loadChildren: () =>
          import('./pages/user-profile/user-profile.module').then((m) => m.UserProfileModule),
      },
      {
        path: 'plant-profiles',
        loadChildren: () =>
          import('./pages/plant-profiles/plant-profiles.module').then((m) => m.PlantProfilesModule),
      },
      {
        path: 'sensors',
        loadChildren: () => import('./pages/sensors/sensors.module').then((m) => m.SensorsModule),
      },
      {
        path: 'gateways',
        loadChildren: () =>
          import('./pages/gateways/gateways.module').then((m) => m.GatewaysModule),
      },
    ],
  },
  { path: '**', redirectTo: 'auth' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
