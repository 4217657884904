import { Component } from '@angular/core';
import { userFeedbackHistory } from '../../../../shared/mock-data/user-feedback-history';
import { ToastController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-feedback-history-table',
  templateUrl: './feedback-history-table.component.html',
  styleUrls: ['./feedback-history-table.component.scss'],
})
export class FeedbackHistoryTableComponent {
  constructor(
    private readonly toastCtrl: ToastController,
    protected readonly translate: TranslateService,
  ) {}

  tableHeaders: {
    name: string;
    value: string;
  }[] = [
    {
      name: 'Timestamp',
      value: 'timestamp',
    },
    {
      name: 'Name',
      value: 'name',
    },
    {
      name: 'Profile',
      value: 'profile',
    },
    {
      name: 'actions',
      value: 'actions',
    },
  ];

  tableData: any[] = userFeedbackHistory;

  sortData($event: any) {}

  applySearchFilter(event: Event) {
    let searchValue = (event.target as HTMLInputElement).value;
    searchValue = searchValue.trim();
    searchValue = searchValue.toLowerCase();
  }

  openEdit(item: any) {}

  async showDeleteAlert(item: any) {
    const toast = await this.toastCtrl.create({
      message: `Feedback deleted successfully`,
      duration: 3000,
      position: 'top',
      cssClass: 'success-alert',
      icon: 'checkmark-outline',
    });

    await toast.present();
  }
}
