<app-header *ngIf="internalRoute"></app-header>
<ion-header class="ion-no-border" mode="md" *ngIf="!internalRoute">
  <ion-toolbar color="nimus-md3-green-bg" class="toolbar ion-padding" style="padding-top: 16px" mode="md">
    <div class="header-content">
      <ion-title size="md" class="header-content__title"> NIMUS </ion-title>
    </div>
  </ion-toolbar>
  <div class="header-content__buttons">
    <app-language-button></app-language-button>
  </div>
</ion-header>

<ion-content color="nimus-md3-green">
  <ion-card class="page-card border-radius-25">
    <ion-card-header class="animate__animated animate__fadeIn animate__faster">
      <ion-card-title class="ion-text-center ion-margin-bottom">
        {{ 'SENSOR.FEEDBACK.TITLE' | translate }}
      </ion-card-title>
    </ion-card-header>
    <ion-card-content class="animate__animated animate__fadeIn animate__faster">
      <ion-grid>

        <ion-item lines="none">
          <ion-input
            label="{{ 'SENSOR.NAME' | translate }}"
            label-placement="stacked"
            type="text"
            [value]="newFeedback.sensor_name"
            [readonly]="true"
          ></ion-input>
        </ion-item>

        <ion-item lines="none" *ngIf="plantProfile">
          <ion-input
            label="{{ 'SENSOR.FEEDBACK.PROFILE' | translate }}"
            label-placement="stacked"
            type="text"
            [value]="plantProfile"
            [readonly]="true"
          ></ion-input>
        </ion-item>

        <ng-container *ngIf="!isDataFetched">
          <ion-item *ngFor="let item of [1, 2]" lines="none">
            <ion-label>
              <p style="width: 30%">
                <ion-skeleton-text [animated]="true"></ion-skeleton-text>
              </p>
              <p style="width: 100%">
                <ion-skeleton-text [animated]="true"></ion-skeleton-text>
              </p>
            </ion-label>
          </ion-item>
        </ng-container>

        <form [formGroup]="feedbackForm" class="ion-margin-top">
          <ion-item lines="none">
            <ion-input
              label="{{ 'SENSOR.PLANT_HEIGHT' | translate }} (cm)"
              label-placement="stacked"
              type="number"
              formControlName="height"
              errorText="{{'RAPORT.INPUT.ERROR' | translate}}"
            ></ion-input>
          </ion-item>
        </form>

        <div
          class="photo-menu"
          *ngIf="!showWebcam">
          <ion-button
            class="ion-align-self-center ion-margin-start"
            color="primary"
            shape="round"
            size="medium"
            fill="outline"
            (click)="showWebcam = true"
            *ngIf="webcamImage"
          >Change photo</ion-button>

          <ion-button
            color="primary"
            shape="round"
            size="medium"
            fill="outline"
            (click)="showWebcam = true"
            *ngIf="!webcamImage">
            <ion-icon
              slot="icon-only"
              name="camera-outline"
            ></ion-icon>
          </ion-button>

        </div>
        <div class="photo-menu" *ngIf="showWebcam">
          <ion-button
            color="primary"
            shape="round"
            size="medium"
            fill="outline"
            (click)="takePhoto()">
            <ion-icon
              slot="icon-only"
              name="camera-outline"
            ></ion-icon>
          </ion-button>
        </div>

        <div class="photo-content">
          <ion-img
            *ngIf="webcamImage && !showWebcam"
            id="feedback-image"
            [src]="webcamImage.imageAsDataUrl"
            class="image-box"
            alt="feedback image">
          </ion-img>

          <!--          [allowCameraSwitch]="allowCameraSwitch"-->
          <!--          [switchCamera]="nextWebcamObservable"-->
          <!--          (cameraSwitched)="cameraWasSwitched($event)"-->
          <!--          (initError)="handleInitError($event)"-->

          <webcam
            class="photo-webcam"
            *ngIf="showWebcam"
            [height]="500"
            [width]="500"
            [imageQuality]="1"
            [trigger]="triggerObservable"
            (imageCapture)="handleImage($event)"
          ></webcam>
        </div>


      </ion-grid>
    </ion-card-content>

    <!-- Buttons -->
    <ion-grid>
      <ion-row class="ion-justify-content-center">
        <ion-col size="auto">
          <ion-button
            class="ion-align-self-center"
            fill="outline"
            color="nimus_red"
            size="medium"
            routerLink="/sensors"
            >{{ 'COMMON.CANCEL' | translate }}
          </ion-button>
          <ion-button
            class="ion-align-self-center ion-margin-start"
            fill="solid"
            color="primary"
            size="medium"
            (click)="save()"
            [disabled]="feedbackForm.invalid || buttonBlocker"
            >{{ 'COMMON.SAVE' | translate }}
          </ion-button>
        </ion-col>
      </ion-row>
    </ion-grid>
  </ion-card>
</ion-content>
