import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {
  FeedbackItemDto,
  FeedbackSearchRequestDto,
  FeedbacksService as ApiFeedbacksService,
  SuccessDto,
} from '../../../api';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { AlertService } from 'src/app/services/alert.service';

@Injectable({
  providedIn: 'root',
})
export class FeedbacksService {
  feedbacksService: ApiFeedbacksService;

  feedbacks = new BehaviorSubject<FeedbackItemDto[]>([]);

  constructor(private readonly http: HttpClient, private alertService: AlertService) {
    this.feedbacksService = new ApiFeedbacksService(
      this.http,
      environment.apiURL,
      // @ts-ignore
      undefined,
    );
  }

  getFeedbackBySensorId(id: number): void {
    this.feedbacksService.feedbacksControllerGetBySensorId(id).subscribe({
      next: (res) => {
        this.feedbacks.next(res);
      },
      error: () => {
        this.alertService.showFailureAlert('', 'Error while fetching feedbacks list').then();
      },
    });
  }

  getFeedbacksSortList(body: FeedbackSearchRequestDto): Observable<FeedbackItemDto[]> {
    return this.feedbacksService.feedbacksControllerSearchFeedback(body);
  }

  getFeedbackByHash(hash: string): Observable<FeedbackItemDto> {
    return this.feedbacksService.feedbacksControllerGetFeedbackByHash(hash);
  }

  getFeedback(hash: string): Observable<FeedbackItemDto> {
    return this.feedbacksService.feedbacksControllerGet(hash);
  }

  saveFeedback(request: FeedbackItemDto): Observable<SuccessDto> {
    return this.feedbacksService.feedbacksControllerSave(request);
  }

  saveUnloggedFeedback(feedback: FeedbackItemDto) {
    return this.feedbacksService.feedbacksControllerSaveFeedbackByHash(feedback);
  }

  deleteFeedback(hash: string): Observable<SuccessDto> {
    return this.feedbacksService.feedbacksControllerDelete(hash);
  }

  getSensorsAwaitingFeedbacks() {
    return this.feedbacksService.feedbacksControllerGetSensorsWhichRequireLeaveFeedback();
  }
}
