<ion-row>
  <ion-col size="12">
    <ion-searchbar
      [debounce]="500"
      (ionInput)="searchList($event)"
      [placeholder]="translate.instant('COMMON.SEARCH')">
    </ion-searchbar>
    <app-sort [tableHeaders]="listHeaders" (sortEvent)="setListSorting($event)"></app-sort>
  </ion-col>
</ion-row>

<ion-row
  *ngIf="!howManyProfiles && loaded"
  class="ion-justify-content-center animate__animated animate__fadeIn animate__fast">
  <ion-col size="auto">
    <ion-item lines="none">{{ 'NO_DATA' | translate }}</ion-item>
  </ion-col>
</ion-row>

<ion-row *ngIf="howManyProfiles > 0">
  <ion-col size="12">
    <ng-container>
      <ion-list *ngIf="loaded; else loadingBlock">
        <app-plant-item *ngFor="let item of listItems" [item]="item"></app-plant-item>
      </ion-list>
      <ng-template #loadingBlock>
        <ion-list>
          <ion-item *ngFor="let _ of [1, 2, 3, 4, 5]">
            <ion-thumbnail slot="start">
              <ion-skeleton-text [animated]="true"></ion-skeleton-text>
            </ion-thumbnail>
            <ion-label>
              <h3>
                <ion-skeleton-text [animated]="true" style="width: 80%"></ion-skeleton-text>
              </h3>
            </ion-label>
          </ion-item>
        </ion-list>
      </ng-template>
      <ion-content>
        <ion-infinite-scroll (ionInfinite)="onScrolled($event)">
          <ion-infinite-scroll-content class="scroll_label"
          loadingText="{{ 'PP.SCROLL_LOADING' | translate }}"
          *ngIf="keepLoadingScrollElements">
        </ion-infinite-scroll-content>
      </ion-infinite-scroll>
    </ion-content>
      <ion-label class="scroll_label" *ngIf="!keepLoadingScrollElements">{{
        'PP.SCROLL_LOADED' | translate
      }}</ion-label>

      <ion-row class="load-more-button-column">
        <ion-col>
          <ion-button
            expand="block"
            class="ion-text-wrap button-load-more"
            fill="outline"
            *ngIf="!hideShowMoreButton && howManyProfiles"
            (click)="loadMore()">
            {{ 'BUTTON.LOAD_MORE' | translate }}
          </ion-button>
        </ion-col>
      </ion-row>

    </ng-container>
  </ion-col>
</ion-row>
