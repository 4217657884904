<app-header></app-header>
<ion-content color="nimus-md3-green">
  <ion-card class="plant-edit-card border-radius-25">

    <ion-card-header class="ion-align-items-center">
      <div class="header-content">
        <ion-card-title text-wrap="true" style="font-size: 26px">{{ 'PP.ADD.TITLE' | translate }}&nbsp;</ion-card-title>
      </div>
    </ion-card-header>

    <ion-card-content class="animate__animated animate__fadeIn animate__faster">
      <ng-container>
        <div class="ion-no-padding animate__animated animate__fadeIn animate__faster">
          <div class="image-box ion-padding-bottom">
            <img
              *ngIf="!imgPreview"
              id="profile-image"
              [defaultImage]="defaultImage"
              [lazyLoad]="blob"
              class="plant-image"
              alt="plant image"/>
            <img
              *ngIf="imgPreview"
              [src]="imgPreview"
              [lazyLoad]="blob"
              class="plant-image"
              alt="plant image"/>

            <ion-button
              color="primary"
              shape="round"
              size="small"
              class="image-box__image-button ion-no-padding"
              (click)="openFileDialog()"
              slot="end">
              <input
                type="file"
                id="file-upload"
                (change)="onFileChange($event)"
                style="display: none"/>
              <ion-icon
                class="image-box__image-button__photo-icon"
                slot="icon-only"
                name="camera-outline"
              ></ion-icon>
            </ion-button>
          </div>

          <ion-row>
            <ion-col>
              <ion-item>
                <ion-input
                  type="text"
                  placeholder="{{ 'PP.EDIT.NAME_PLACEHOLDER' | translate }}"
                  label="{{ 'PP.EDIT.NAME' | translate }}"
                  labelPlacement="stacked"
                  [(ngModel)]="newProfile.name_lang[0].value">
                </ion-input>
                <div slot="end" *ngIf="!is_user_admin">
                  <ion-button class="button-right ion-no-padding" expand="block" size="small" shape="round"  (click)="openPlantProfilesList()">
                    <ion-icon name="caret-down-circle-outline" size="large"></ion-icon>
                  </ion-button>
                </div>
              </ion-item>
            </ion-col>
          </ion-row>

          <ion-row>
            <ion-col>
              <ion-item>
                <ion-input
                  type="text"
                  placeholder="{{ 'PP.EDIT.DESCRIPTION_PLACEHOLDER' | translate }}"
                  label="{{ 'PP.EDIT.DESCRIPTION' | translate }}"
                  labelPlacement="stacked"
                  maxlength="800"
                  [(ngModel)]="newProfile.info_lang[0].value">
                </ion-input>
              </ion-item>
            </ion-col>
          </ion-row>

          <ion-row class="ion-padding-top ion-margin-top">
            <ion-col>
              <ion-item button="true" id="open-translations-modal" lines="none">
                <ion-label>{{ 'PP.ADD.TRANSLATION' | translate }}</ion-label>
                <ion-button slot="end">
                  <ion-icon name="language-outline"></ion-icon>
                </ion-button>
              </ion-item>
            </ion-col>
          </ion-row>

          <ion-item>
            <ion-select
              mode="ios"
              justify="space-between"
              label="{{ 'SENSOR.INTERVAL' | translate }}"
              placeholder="{{ 'SENSOR.SELECT_INTERVAL' | translate }}"
              [value]="newProfile.interval"
              (ionChange)="selectInterval($event)">
              <ion-select-option value="none" >{{
                'SENSOR.INTERVAL_OPTION_NONE' | translate
              }}</ion-select-option>
              <ion-select-option value="7 days">{{
                'SENSOR.INTERVAL_OPTION_7' | translate
              }}</ion-select-option>
              <ion-select-option value="14 days">{{
                'SENSOR.INTERVAL_OPTION_14' | translate
              }}</ion-select-option>
              <ion-select-option value="30 days">{{
                'SENSOR.INTERVAL_OPTION_30' | translate
              }}</ion-select-option>
            </ion-select>
          </ion-item>

          <ion-title class="ion-text-center ion-padding-top" mode="md">{{
            'PP.EDIT.TEMP' | translate
          }}</ion-title>

          <ion-row class="ion-justify-content-center" mode="md">
            <ion-range
              aria-label="Temperature"
              class="plant-card-width"
              [dualKnobs]="true"
              [value]="{ lower: newProfile.temp_min, upper: newProfile.temp_max }"
              [ticks]="true"
              [snaps]="true"
              [pin]="true"
              [pinFormatter]="tempPinFormatter"
              [min]="-20"
              [max]="50"
              (ionChange)="onTempChange($event)">
              <ion-icon
                slot="start"
                name="thermometer-outline"
                color="primary"
                size="small"
              ></ion-icon>
              <ion-icon
                slot="end"
                name="thermometer-outline"
                color="danger"
                size="large"
              ></ion-icon>
            </ion-range>
          </ion-row>

          <ion-title class="ion-text-center ion-padding-top" mode="md">{{
            'PP.EDIT.HUMID' | translate
          }}</ion-title>

          <ion-row class="ion-justify-content-center" mode="md">
            <ion-range
              aria-label="Humidity"
              class="plant-card-width"
              [dualKnobs]="true"
              [value]="{ lower: newProfile.hum_min, upper: newProfile.hum_max }"
              [ticks]="true"
              [snaps]="true"
              [pin]="true"
              [pinFormatter]="humidPinFormatter"
              [min]="0"
              [max]="100"
              (ionChange)="onHumidChange($event)">
              <ion-icon slot="start" name="water-outline" color="medium" size="small"></ion-icon>
              <ion-icon slot="end" name="water-outline" color="primary" size="large"></ion-icon>
            </ion-range>
          </ion-row>
        </div>
      </ng-container>
    </ion-card-content>

    <ion-row class="ion-justify-content-center">
      <ion-col size="auto">
        <ion-button
          class="ion-align-self-center"
          fill="outline"
          color="nimus_red"
          size="medium"
          (click)="cancel()"
          >{{ 'PP.BUTTON.CANCEL' | translate }}
        </ion-button>
        <ion-button
          class="ion-align-self-center ion-margin-start"
          fill="solid"
          color="primary"
          size="medium"
          (click)="save()"
          >{{ 'PP.BUTTON.SAVE' | translate }}
        </ion-button>
      </ion-col>
    </ion-row>
  </ion-card>
  <ion-modal #modal trigger="open-translations-modal">
    <ng-template>
      <ion-content>
        <ion-toolbar color="nimus-md3-green-bg" class="toolbar" mode="md">
          <ion-title size="md" style="font-size: 20px"
            >{{ 'PP.ADD.TRANSLATION' | translate }}</ion-title>
          <div class="exit-button" (click)="modal.dismiss()" slot="end">
            <ion-icon class="item-red" name="close-outline" slot="end" size="large"></ion-icon>
          </div>
        </ion-toolbar>

        <ion-row>
          <ion-col style="text-align: center; padding-bottom: 20px; padding-top: 10px">
            <h3>{{ 'LANG.LANG' | translate }} {{ getDifferentLanguageName() }}</h3>
          </ion-col>
        </ion-row>

        <ion-list>
          <ion-item text-wrap="true">
            <ion-input [clearInput]="true" [(ngModel)]="newProfile.name_lang[1].value" label="{{ 'GATEWAYS.PAGE.ELEMENT_NAME' | translate }}:" placeholder="{{ 'LANG.NAME' | translate }}"></ion-input>
          </ion-item>

          <ion-item>
            <ion-textarea [(ngModel)]="newProfile.info_lang[1].value" label="{{ 'PP.EDIT.DESCRIPTION' | translate }}:" placeholder="{{ 'LANG.DESC' | translate }}"></ion-textarea>
          </ion-item>
        </ion-list>
      </ion-content>

      <ion-button
        class="ion-align-self-center"
        size="medium"
        color="primary"
        fill="solid"
        (click)="modal.dismiss()">
        {{ 'PP.BUTTON.SAVE' | translate }}
      </ion-button>
    </ng-template>
  </ion-modal>
  <ion-toast
    [isOpen]="openToast"
    icon="language-outline"
    [message]="this.toastMessage"
    [duration]="5000"
    (didDismiss)="setToastState(false)">
  </ion-toast>
</ion-content>
