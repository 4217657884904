import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { formatDate } from '@angular/common';

@Pipe({
  name: 'dateAgo',
})
export class DateAgoPipe implements PipeTransform {
  dateFormat = 'YYYY-MM-dd, HH:mm';

  locale = 'pl';

  constructor(public translate: TranslateService) {
    this.translate.onLangChange.subscribe({
      next: () => {
        this.setDateFormat();
      },
    });
    this.setDateFormat();
  }

  setDateFormat() {
    const currentLang = this.translate.currentLang;
    switch (currentLang) {
      case 'en':
        this.dateFormat = 'MM/d/y, HH:mm:ss';
        this.locale = 'en';
        break;
      default:
        this.dateFormat = 'YYYY-MM-dd, HH:mm';
        this.locale = 'pl';
    }
  }

  transform(value: string | undefined, displayDaysAgoFormat: boolean): string {
    if (value === undefined || value === null) {
      return this.translate.instant('NO_DATA');
    }
    const date = new Date(value);
    const currentDate = new Date();
    const timeDifference = currentDate.getTime() - date.getTime();
    const daysDifference = Math.floor(timeDifference / (1000 * 3600 * 24));

    if (!displayDaysAgoFormat) {
      return formatDate(
        date,
        this.dateFormat,
        this.locale,
        Intl.DateTimeFormat().resolvedOptions().timeZone,
      );
    }
    if (daysDifference == null) {
      return this.translate.instant('NO_DATA');
    }
    if (daysDifference === 0) {
      return this.translate.instant('DATE.TODAY');
    }
    if (daysDifference === 1) {
      return this.translate.instant('DATE.YESTERDAY');
    }
    return String(daysDifference + ' ' + this.translate.instant('DATE.DAYS_AGO'));
  }
}
