import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { PlantSearchRequestDto } from '../../api';
import LangEnum = PlantSearchRequestDto.LangEnum;

@Injectable({
  providedIn: 'root',
})
export class LangService {
  public supportedLanguages: LangEnum[] = ['en', 'pl'];

  constructor(private readonly translateService: TranslateService) {
    this.translateService.addLangs(this.supportedLanguages);
    const storageLanguage = localStorage.getItem('lang') ?? 'en';
    this.translateService.use(storageLanguage);
  }

  initialize() {
    const storageLanguage = localStorage.getItem('lang');

    if (!storageLanguage) {
      const browserLanguage = this.translateService.getBrowserLang();

      if (!browserLanguage?.includes('en') && !browserLanguage?.includes('pl')) {
        localStorage.setItem('lang', 'en');
      } else {
        localStorage.setItem('lang', browserLanguage);
        this.translateService.use(browserLanguage);
      }
    }
  }

  get language() {
    return <LangEnum>this.translateService.currentLang;
  }

  set language(language: LangEnum) {
    localStorage.setItem('lang', language);
    this.translateService.use(language);
    this.translateService.reloadLang(language);
  }
}
