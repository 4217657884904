import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { SharedModule } from '../../shared/shared.module';
import { IonicModule } from '@ionic/angular';
import { PolicyComponent } from './policy.component';
import { RouterLink } from '@angular/router';

@NgModule({
  declarations: [PolicyComponent],
  imports: [CommonModule, TranslateModule, SharedModule, IonicModule, RouterLink],
})
export class PolicyModule {}
