import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-plant-profile-select',
  templateUrl: './select.component.html',
  styleUrls: ['./select.component.scss'],
})
export class SelectComponent implements OnInit {
  constructor(private modalCtrl: ModalController, protected readonly translate: TranslateService) {}

  @Input() items: any[] = [];

  @Input() selectedItem: {
    name: string;
    value: string;
  } = {
    name: '',
    value: '',
  };

  filteredItems: any[] = [];

  ngOnInit() {
    this.filteredItems = [...this.items];
  }

  cancel() {
    this.modalCtrl.dismiss(null, 'cancel').then();
  }

  onSelect(event: any) {
    this.selectedItem = event.detail.value;
  }

  save() {
    this.modalCtrl.dismiss(this.selectedItem, 'save').then();
  }

  searchbarInput(event: any) {
    this.filterList(event.target.value);
  }

  filterList(searchQuery: string | undefined) {
    if (searchQuery === undefined) {
      this.filteredItems = [...this.items];
    } else {
      const normalizedQuery = searchQuery.toLowerCase();
      this.filteredItems = this.items.filter((item) => {
        return item.name.toLowerCase().includes(normalizedQuery);
      });
    }
  }
}
