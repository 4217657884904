/**
 * NIMUS CLOUD File API Backend
 * NIMUS Cloud File Backend Documentation
 *
 * OpenAPI spec version: 1.0.1
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */ /* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams, HttpResponse, HttpEvent } from '@angular/common/http';
import { CustomHttpUrlEncodingCodec } from '../encoder';

import { Observable } from 'rxjs';

import { PolicyDocumentListDto } from '../model/policyDocumentListDto';

import { BASE_PATH, COLLECTION_FORMATS } from '../variables';
import { Configuration } from '../configuration';

@Injectable()
export class PolicyService {
  protected basePath = 'http://127.0.0.1:3000/file-api';

  public defaultHeaders = new HttpHeaders();

  public configuration = new Configuration();

  constructor(
    protected httpClient: HttpClient,
    @Optional() @Inject(BASE_PATH) basePath: string,
    @Optional() configuration: Configuration,
  ) {
    if (basePath) {
      this.basePath = basePath;
    }
    if (configuration) {
      this.configuration = configuration;
      this.basePath = basePath || configuration.basePath || this.basePath;
    }
  }

  /**
   * @param consumes string[] mime-types
   * @return true: consumes contains 'multipart/form-data', false: otherwise
   */
  private canConsumeForm(consumes: string[]): boolean {
    const form = 'multipart/form-data';
    for (const consume of consumes) {
      if (form === consume) {
        return true;
      }
    }
    return false;
  }

  /**
   * Get policy document
   *
   * @param document_id Document id
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public policyControllerGetDocument(
    document_id: string,
    observe?: 'body',
    reportProgress?: boolean,
  ): Observable<Blob>;
  public policyControllerGetDocument(
    document_id: string,
    observe?: 'response',
    reportProgress?: boolean,
  ): Observable<HttpResponse<Blob>>;
  public policyControllerGetDocument(
    document_id: string,
    observe?: 'events',
    reportProgress?: boolean,
  ): Observable<HttpEvent<Blob>>;
  public policyControllerGetDocument(
    document_id: string,
    observe: any = 'body',
    reportProgress: boolean = false,
  ): Observable<any> {
    if (document_id === null || document_id === undefined) {
      throw new Error(
        'Required parameter document_id was null or undefined when calling policyControllerGetDocument.',
      );
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['application/pdf'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.request<Blob>(
      'get',
      `${this.basePath}/policy/get/${encodeURIComponent(String(document_id))}`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
        responseType: 'blob' as 'json',
      },
    );
  }

  /**
   * Get policy document list
   *
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public policyControllerGetDocumentList(
    observe?: 'body',
    reportProgress?: boolean,
  ): Observable<PolicyDocumentListDto>;
  public policyControllerGetDocumentList(
    observe?: 'response',
    reportProgress?: boolean,
  ): Observable<HttpResponse<PolicyDocumentListDto>>;
  public policyControllerGetDocumentList(
    observe?: 'events',
    reportProgress?: boolean,
  ): Observable<HttpEvent<PolicyDocumentListDto>>;
  public policyControllerGetDocumentList(
    observe: any = 'body',
    reportProgress: boolean = false,
  ): Observable<any> {
    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.request<PolicyDocumentListDto>('get', `${this.basePath}/policy/list`, {
      withCredentials: this.configuration.withCredentials,
      headers: headers,
      observe: observe,
      reportProgress: reportProgress,
    });
  }
}
