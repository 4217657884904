import { Component, Input, OnInit } from '@angular/core';
import { InfiniteScrollCustomEvent } from '@ionic/angular';
import {
  GatewaySearchRequestDto,
  SensorDataItemDto,
  SensorsSearchRequestDto,
} from '../../../../../api';
import { SensorsService } from '../../sensors.service';
import { LangService } from '../../../../services/lang.service';
import { Observable, Subject } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import SortDirectionEnum = GatewaySearchRequestDto.SortDirectionEnum;
import { SortParams } from '../../../../shared/components/sort/sort.component';
import { animate, AnimationBuilder, keyframes, style } from '@angular/animations';
import { AlertService } from '../../../../services/alert.service';

@Component({
  selector: 'app-data',
  templateUrl: './data.component.html',
  styleUrls: ['./data.component.scss'],
})
export class DataComponent implements OnInit {
  constructor(
    private readonly sensorsService: SensorsService,
    protected readonly langService: LangService,
    protected readonly translate: TranslateService,
    private animationBuilder: AnimationBuilder,
    private alertService: AlertService,
  ) {}

  @Input() actions: string[] = [];

  @Input() sensorId: number;

  sensorData: SensorDataItemDto[] = [];

  hideShowMoreButton: boolean = false;

  tableHeaders: {
    name: string;
    value: string;
  }[] = [
    {
      name: 'SENSOR.SORT',
      value: 'created_at',
    },
    {
      name: 'actions',
      value: 'actions',
    },
  ];

  sortParams: {
    field: string;
    direction: SortDirectionEnum;
  } = {
    field: 'created_at',
    direction: 'asc',
  };

  isDataFetched: boolean = false;

  searchValue: string = '';

  listRequest: SensorsSearchRequestDto = {
    filter: [],
    page_index: 0,
    page_size: 10,
    sort_direction: 'asc',
    search_new: false,
  };

  numberOfLoadedItems: number = 0;

  isAllSensorDataLoaded: boolean = false;

  startSort: SortParams = {
    value: 'created_at',
    name: 'SENSOR.SORT',
    direction: 'desc',
  };

  ngOnInit() {
    this.sortData({ field: 'created_at', direction: 'desc' });
  }

  getSensorData(): Observable<void> {
    // console.log('getSensorData', JSON.stringify(this.listRequest));
    const completed$ = new Subject<void>();
    this.sensorsService.getSensorDataById(this.listRequest, this.sensorId).subscribe({
      next: (res) => {
        for (let sensor of res.data) {
          this.sensorData = [...this.sensorData, sensor];
        }
        this.numberOfLoadedItems += this.listRequest.page_size;
        // console.log('Add page to ', this.listRequest.page_index);
        this.listRequest.page_index += 1;
        this.isDataFetched = true;

        if (res.cnt < this.listRequest.page_size) {
          this.isAllSensorDataLoaded = true;
          this.hideShowMoreButton = true;
          if (this.checkIfData()) {
            this.sensorsService
              .showSuccessAlert(this.translate.instant('SENSOR.SENSOR_DATA_LOADED'))
              .then();
          }
        } else {
          this.isAllSensorDataLoaded = false;
        }
        completed$.next();
      },
      error: () => {
        this.alertService.showFailureAlert('', 'Error while fetching sensors').then();
      },
    });
    return completed$.asObservable();
  }

  sortData($event: any) {
    this.sortParams = JSON.parse(JSON.stringify($event));
    this.listRequest.page_index = 0;
    this.numberOfLoadedItems = 0;
    this.listRequest = {
      filter: this.searchValue
        ? [
            {
              field: 'name',
              value: `%${this.searchValue}%`,
            },
          ]
        : [],
      page_index: this.listRequest.page_index,
      page_size: this.listRequest.page_size,
      sort: this.sortParams.field ? [this.sortParams.field] : [],
      search_new: false,
      sort_direction: this.sortParams.direction ? this.sortParams.direction : '',
    };
    this.sensorData = [];
    this.isDataFetched = false;
    this.getSensorData();
  }

  refreshData() {
    this.sortData({ field: 'created_at', direction: 'desc' });
    this.hideShowMoreButton = false;
    let element = document.getElementById('refreshButtonData');
    if (element) {
      const animation = this.rotateAnimation();
      animation.create(element).play();
    }
  }

  rotateAnimation() {
    return this.animationBuilder.build([
      style({ transform: 'scale(1) rotate(0deg)' }),
      animate(
        '400ms ease-in-out',
        keyframes([
          style({ transform: 'scale(1) rotate(0deg)' }),
          style({ transform: 'scale(1.2) rotate(180deg)' }),
          style({ transform: 'scale(1) rotate(360deg)' }),
        ]),
      ),
    ]);
  }

  checkIfData() {
    return this.sensorData.length > 0;
  }

  onIonInfinite(event: Event) {
    this.hideShowMoreButton = true;
    if (this.sensorData.length === 0) {
      (event as InfiniteScrollCustomEvent).target.complete().then();
      return;
    }
    if (!this.isAllSensorDataLoaded) {
      this.getSensorData().subscribe(() => {
        if ((event as InfiniteScrollCustomEvent).target) {
          (event as InfiniteScrollCustomEvent).target.complete().then();
        }
      });
    } else {
      (event as InfiniteScrollCustomEvent).target.complete().then();
    }
  }

  loadMore() {
    const event = new Event('ionInfinite');
    this.onIonInfinite(event);
    this.hideShowMoreButton = true;
  }
}
