/**
 * NIMUS CLOUD API Backend
 * NIMUS Cloud Backend Documentation
 *
 * OpenAPI spec version: 1.0.0
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { PlantSearchItemDto } from './plantSearchItemDto';

export interface PlantSearchRequestDto {
  /**
   * List filtered fields with search values
   */
  filter?: Array<PlantSearchItemDto>;
  /**
   * Sort direction
   */
  sort_direction: PlantSearchRequestDto.SortDirectionEnum;
  /**
   * Fields to be sorted
   */
  sort?: Array<string>;
  /**
   * Searched language
   */
  lang: PlantSearchRequestDto.LangEnum;
  /**
   * Page index
   */
  page_index: number;
  /**
   * Page size
   */
  page_size: number;
}
export namespace PlantSearchRequestDto {
  export type SortDirectionEnum = 'asc' | 'desc' | '';
  export const SortDirectionEnum = {
    Asc: 'asc' as SortDirectionEnum,
    Desc: 'desc' as SortDirectionEnum,
    Empty: '' as SortDirectionEnum,
  };
  export type LangEnum = 'en' | 'pl';
  export const LangEnum = {
    En: 'en' as LangEnum,
    Pl: 'pl' as LangEnum,
  };
}
