/**
 * NIMUS CLOUD File API Backend
 * NIMUS Cloud File Backend Documentation
 *
 * OpenAPI spec version: 1.0.1
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface PolicyDocumentDto {
  /**
   * Policy document url
   */
  uid: string;
  /**
   * Policy document name
   */
  name: string;
  /**
   * File size
   */
  size: number;
  /**
   * Last modified date
   */
  last_modified: string;
}
