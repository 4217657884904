/**
 * NIMUS CLOUD API Backend
 * NIMUS Cloud Backend Documentation
 *
 * OpenAPI spec version: 1.0.0
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface SensorDataItemDto {
  /**
   * Value references measure type e.g: temperature
   */
  value?: number;
  /**
   * Measure type id - could point to humidity or temperature
   */
  measure_type_id?: number;
  /**
   * Time when created
   */
  created_at?: string;
}
