<app-header></app-header>
<ion-content color="nimus-md3-green">
  <ion-card class="page-card border-radius-25">
    <ion-card-header class="animate__animated animate__fadeIn animate__faster">
      <ion-card-title class="ion-text-center ion-margin-bottom mt-5">{{
        'SENSOR.ADD' | translate
      }}</ion-card-title>
      <ion-segment [value]="segment" (ionChange)="showSegment($event)">
        <ion-segment-button value="step1" [disabled]="isDisabled.step1">
          <ion-label>{{ 'SENSOR.STEP_ONE' | translate }}</ion-label>
        </ion-segment-button>
        <ion-segment-button value="step2" [disabled]="isDisabled.step2">
          <ion-label>{{ 'SENSOR.STEP_TWO' | translate }}</ion-label>
        </ion-segment-button>
        <ion-segment-button value="step3" [disabled]="isDisabled.step3">
          <ion-label>{{ 'SENSOR.STEP_THREE' | translate }}</ion-label>
        </ion-segment-button>
      </ion-segment>
    </ion-card-header>
    <ion-card-content class="animate__animated animate__fadeIn animate__faster">
      <ng-container *ngIf="segment === 'step1'">
        <div class="ion-no-padding animate__animated animate__fadeIn animate__faster">
          <ion-grid>
            <ion-row class="ion-justify-content-center" style="display:flex; flex-direction: column;">
              <div style="display:flex;flex-direction: column-reverse;align-items: center;">
                <ion-text color="dark"
                >{{ 'SENSOR.PAIR' | translate }}
              </ion-text>


              <img
              src="../../../../assets/pair.png"
              alt="pairing image"
              width="250"
              height="250"
              class="img-center mt-5"
              />
            </div>

            </ion-row>
          </ion-grid>
        </div>
      </ng-container>
      <ng-container *ngIf="segment === 'step2'">
        <div class="ion-no-padding animate__animated animate__fadeIn animate__faster">
          <ion-grid>
            <ion-row class="ion-justify-content-center">
              <ion-col size="auto" class="ion-text-center">
                <ion-text color="dark">{{ 'SENSOR.PLACE_BATTERY' | translate }}</ion-text>
              </ion-col>
            </ion-row>
            <ion-row class="ion-justify-content-center">
              <ion-col size="auto">
                <ion-icon
                  class="battery-icon icon-1"
                  color="nimus_blue"
                  name="battery-dead-outline"
                ></ion-icon>
                <ion-icon
                  class="battery-icon icon-2"
                  color="nimus_blue"
                  name="battery-charging-outline"
                ></ion-icon>
              </ion-col>
            </ion-row>
            <ion-row class="ion-justify-content-center ion-padding-vertical">
              <ion-spinner
                *ngIf="!newSensor"
                color="nimus_blue"
                class="ion-spinner"
                name="bubbles"
              ></ion-spinner>
            </ion-row>
          </ion-grid>
        </div>
      </ng-container>
      <ng-container *ngIf="segment === 'step3'">
        <div class="ion-no-padding animate__animated animate__fadeIn animate__faster">
          <ion-grid>
            <ion-row class="ion-justify-content-center">
              <ion-icon size="large" color="nimus_green" name="checkmark-circle-outline"></ion-icon>
              <ion-col size="12" class="ion-text-center">
                <ion-text color="nimus_green">
                  {{ 'SENSOR.PAIR_SUCCESS_TEXT1' | translate }}
                </ion-text>
              </ion-col>
            </ion-row>
            <ion-row class="ion-justify-content-center">
              <ion-col size="auto" class="ion-text-center ion-padding">
                <ion-text color="dark"> {{ 'SENSOR.PAIR_SUCCESS_TEXT2' | translate }} </ion-text>
              </ion-col>
            </ion-row>
            <form [formGroup]="sensorForm">
              <ion-item>
                <ion-input
                  label="{{ 'SENSOR.NAME' | translate }}"
                  label-placement="stacked"
                  type="text"
                  formControlName="name"
                ></ion-input>
              </ion-item>
            </form>
            <ion-row class="inline mt-5">
              <ion-col>
                <ion-list>
                  <ion-item
                    [button]="true"
                    [detail]="false"
                    (click)="openPlantProfilesList($event)"
                  >
                    <ion-label>{{ 'SENSOR.PROFILE' | translate }}</ion-label>
                    <div slot="end" [class.opacity-6]="!this.selectedPlantProfileName">
                      {{
                        this.selectedPlantProfileName
                          ? this.selectedPlantProfileName
                          : ('SENSOR.SELECT_PROFILE' | translate)
                      }}
                      <ion-icon role="img" src="/assets/svg/arrow.svg" class="mr-2"></ion-icon>
                    </div>
                  </ion-item>
                </ion-list>
              </ion-col>
              <ion-col>
                <ion-list>
                  <ion-item>
                    <ion-select
                      interface="popover"
                      justify="space-between"
                      label="{{ 'SENSOR.INTERVAL' | translate }}"
                      placeholder="{{ 'SENSOR.SELECT_INTERVAL' | translate }}"
                      [value]="sensorForm.get('interval')?.value"
                      (ionChange)="selectInterval($event)"
                    >
                      <ion-select-option
                        *ngFor="let option of intervalOptions"
                        [value]="option.value"
                        >{{ translate.instant(option.name) }}</ion-select-option
                      >
                    </ion-select>
                  </ion-item>
                </ion-list>
              </ion-col>
            </ion-row>
            <ion-row class="ion-padding-top">
              <ion-col size="12">
                <ion-item lines="none">
                  <ion-label position="stacked">S/N</ion-label>
                  <ion-text>{{ newSensor?.serial_number }}</ion-text>
                </ion-item>
              </ion-col>
<!--              <ion-col size="6">-->
<!--                <ion-item lines="none">-->
<!--                  <ion-label position="stacked">{{ 'SENSOR.TYPE' | translate }}</ion-label>-->
<!--                  <ion-text>{{ newSensor?.type }}</ion-text>-->
<!--                </ion-item>-->
<!--              </ion-col>-->
            </ion-row>
            <ion-row>
              <ion-col size="6">
                <ion-item lines="none" *ngIf="newSensorData.temp">
                  <ion-label position="stacked">Temperature</ion-label>
                  <ion-text> {{ newSensorData.temp }}°C</ion-text>
                </ion-item>
              </ion-col>
              <ion-col size="6">
                <ion-item lines="none" *ngIf="newSensorData.hum">
                  <ion-label position="stacked">Humidity</ion-label>
                  <ion-text> {{ newSensorData.hum }}%</ion-text>
                </ion-item>
              </ion-col>
            </ion-row>
          </ion-grid>
        </div>
      </ng-container>
    </ion-card-content>
    <ion-grid>
      <ion-row class="ion-justify-content-center">
        <ion-col size="auto">
          <ion-button
            class="ion-align-self-center"
            fill="outline"
            color="nimus_red"
            size="medium"
            (click)="close()"
            >{{ 'COMMON.CANCEL' | translate }}
          </ion-button>
          <ion-button
            *ngIf="segment === 'step1'"
            class="ion-align-self-center ion-margin-start"
            fill="solid"
            color="primary"
            size="medium"
            (click)="showNextStep()"
            >{{ 'COMMON.NEXT' | translate }}
          </ion-button>
          <ion-button
            *ngIf="segment === 'step3'"
            class="ion-align-self-center ion-margin-start"
            fill="solid"
            color="primary"
            size="medium"
            (click)="saveSensor()"
            [disabled]="sensorForm.invalid || !selectedPlantProfile"
            >{{ 'COMMON.SAVE' | translate }}
          </ion-button>
        </ion-col>
      </ion-row>
    </ion-grid>
  </ion-card>
</ion-content>
