// dev.panel.nimus.pl
export const environment = {
  production: true,
  useMockData: false,
  auth0: {
    domain: 'dev-nimus.eu.auth0.com',
    clientId: '0aS3Mjfdx3PhwUQH2rwGWykZqSZahJWF',
  },
  apiURL: 'https://dev.panel.nimus.pl/api',
  fileApiURL: 'https://dev.panel.nimus.pl/file-api',
  googleApiKey: 'AIzaSyBQWKon_dI6fVjyXsDofVRAm7ULkwNYkXg',
  defaultLocation: {
    latitude: 52.377956,
    longitude: 4.89707,
  },
  pushPublicKey:
    'BLQa90UQtToiNtGsEJV8UWfZVDuL6hS9rAuHavlwiPz5eNnDB64Qr_ICgwVhc0EcMPVMtaeyIz5dsElj1y6lLkE',
};
