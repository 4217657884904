import { Component } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { UserService } from '../../../services/user.service';

@Component({
  selector: 'app-logout-modal',
  templateUrl: './logout-modal.component.html',
  styleUrls: ['./logout-modal.component.scss'],
})
export class LogoutModalComponent {
  constructor(private modalController: ModalController, private userService: UserService) {}

  logout() {
    this.userService.sessionLogout();
    localStorage.clear();
    this.modalController.dismiss().then();
  }

  dismiss() {
    this.modalController.dismiss().then();
  }
}
