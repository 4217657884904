import {
  Component,
  DestroyRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
  inject,
} from '@angular/core';
import {
  ActionSheetController,
  ModalController,
  ToastController,
  ViewWillEnter,
} from '@ionic/angular';
import { animate, AnimationBuilder, keyframes, style } from '@angular/animations';
import { Components } from '@ionic/core';
import {
  FeedbackItemDto,
  FeedbackSearchRequestDto,
  GatewaySearchRequestDto,
} from '../../../../../api';
import { FeedbacksService } from '../../feedbacks.service';
import IonAccordionGroup = Components.IonAccordionGroup;
import { catchError, map, Observable, of, take, tap } from 'rxjs';
import { FilesService } from '../../../plant-profiles/files.service';
import { SortParams } from '../../../../shared/components/sort/sort.component';
import SortDirectionEnum = GatewaySearchRequestDto.SortDirectionEnum;
import { ActivatedRoute } from '@angular/router';
import { AlertService } from '../../../../services/alert.service';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
  selector: 'app-feedback',
  templateUrl: './feedback.component.html',
  styleUrls: ['./feedback.component.scss'],
})
export class FeedbackComponent implements OnInit, ViewWillEnter {
  @Input() actions: string[] = [];

  @Output() editEvent = new EventEmitter<any>();

  headerValues: string[] = [];

  headerNames: string[] = [];

  startSort: SortParams = {
    value: 'created_at',
    name: 'SENSOR.SORT',
    direction: 'desc',
  };

  blob: Observable<string>;

  imageLoaded: boolean = false;

  isDataFetched: boolean = false;

  listRequest: any = {
    sensor_id: 0,
    page_index: 0,
    page_size: 100000,
    sort: ['modified_at'],
    sort_direction: 'asc',
  };

  @Input() sensorId: number;

  listData: FeedbackItemDto[] = [];

  sorting: {
    value: string;
    name?: string;
    direction: string;
  } = {
    value: '',
    name: '',
    direction: '',
  };

  listHeaders: {
    name: string;
    value: string;
  }[] = [
    {
      name: 'COMMON.DATE',
      value: 'date',
    },
  ];

  sortParams: {
    field: string;
    direction: SortDirectionEnum;
  } = {
    field: 'created_at',
    direction: 'desc',
  };

  @ViewChild('accordionGroup', { static: true }) accordionGroup!: IonAccordionGroup;

  private destroyRef = inject(DestroyRef);

  constructor(
    private readonly actionSheetCtrl: ActionSheetController,
    private readonly feedbacksService: FeedbacksService,
    private readonly toastCtrl: ToastController,
    private readonly filesService: FilesService,
    private readonly modalCtrl: ModalController,
    private animationBuilder: AnimationBuilder,
    private readonly route: ActivatedRoute,
    private alertService: AlertService,
  ) {}

  ngOnInit() {
    this.headerValues = this.listHeaders.map((item) => item.value);
    this.headerNames = this.listHeaders.map((item) => item.name);
    this.feedbacksService.feedbacks.pipe(takeUntilDestroyed(this.destroyRef)).subscribe({
      next: (res) => {
        this.isDataFetched = true;
        this.listData = res;
      },
    });
  }

  ionViewWillEnter() {
    this.sortData();
  }

  private requestList(requestBody: FeedbackSearchRequestDto) {
    this.listData = [];
    if (!this.sensorId) {
      console.error('No sensor id');
      return;
    }
    this.feedbacksService
      .getFeedbacksSortList(requestBody)
      .pipe(
        tap(() => {
          this.isDataFetched = true;
        }),
      )
      .subscribe({
        next: (response: any) => {
          this.feedbacksService.feedbacks.next(response.data);
        },
        error: () => {
          this.alertService.showFailureAlert('', 'Error while fetching feedbacks list').then();
        },
      });
  }

  refreshData() {
    this.isDataFetched = false;
    this.sortData();
    // this.hideShowMoreButton = false;
    let element = document.getElementById('refreshButtonFeedback');
    if (element) {
      const animation = this.rotateAnimation();
      animation.create(element).play();
    }
  }

  sortData($event?: any) {
    if ($event) {
      this.sortParams = JSON.parse(JSON.stringify($event));
    }
    this.listRequest = {
      sensor_id: this.sensorId,
      page_index: 0,
      page_size: 100000,
      sort: ['modified_at'],
      sort_direction: this.sortParams.direction ? this.sortParams.direction : 'asc',
    };
    this.requestList(this.listRequest);
  }

  rotateAnimation() {
    return this.animationBuilder.build([
      style({ transform: 'scale(1) rotate(0deg)' }),
      animate(
        '400ms ease-in-out',
        keyframes([
          style({ transform: 'scale(1) rotate(0deg)' }),
          style({ transform: 'scale(1.2) rotate(180deg)' }),
          style({ transform: 'scale(1) rotate(360deg)' }),
        ]),
      ),
    ]);
  }

  checkIfData() {
    return this.listData?.length > 0;
  }

  closeSorting = () => {
    const nativeEl = this.accordionGroup;
    if (nativeEl.value === 'second') {
      nativeEl.value = undefined;
    } else {
      nativeEl.value = 'second';
    }
  };

  async showDeleteModal(item: FeedbackItemDto) {
    const actionSheet = await this.actionSheetCtrl.create({
      cssClass: 'action-sheet-delete',
      header: `Are you sure you want to delete ${item.height}?`,
      buttons: [
        {
          text: 'Delete',
          role: 'destructive',
          data: {
            action: 'delete',
          },
        },
        {
          text: 'Cancel',
          role: 'cancel',
          data: {
            action: 'cancel',
          },
        },
      ],
    });
    await actionSheet.present();
    const { data, role } = await actionSheet.onWillDismiss();
    if (data?.action === 'delete') {
      this.feedbacksService.deleteFeedback(item.prepared_hash!).subscribe({
        next: (response) => {
          if (response.code === 202) {
            this.showDeleteAlert();
            this.sortData();
          }
        },
        error: () => {
          this.showErrorAlert();
          this.sortData();
        },
      });
    }
  }

  async showDeleteAlert() {
    const toast = await this.toastCtrl.create({
      message: `Sensor deleted successfully`,
      duration: 3000,
      position: 'top',
      cssClass: 'success-alert',
      icon: 'checkmark-outline',
    });
    await toast.present();
  }

  async showErrorAlert() {
    const toast = await this.toastCtrl.create({
      message: `Error! This sensor cannot be deleted`,
      duration: 3000,
      position: 'top',
      cssClass: 'error-alert',
      icon: 'checkmark-outline',
    });

    await toast.present();
  }

  getFeedbackThumbnail(item: FeedbackItemDto) {
    if (!item?.file_id) {
      return of('/assets/svg/default.svg');
    }

    if (!this.blob) {
      this.imageLoaded = false;
      this.blob = this.filesService.getThumbnail(item.file_id).pipe(
        take(1),
        map((blob) => {
          this.imageLoaded = true;
          return URL.createObjectURL(blob);
        }),
        catchError(() => of('/assets/svg/default.svg')),
      );
    }
    return this.blob;
  }

  cancel() {
    this.modalCtrl.dismiss(null, 'cancel').then();
  }
}
