import { Component } from '@angular/core';
import { ModalController, RangeCustomEvent } from '@ionic/angular';
import { RangeValue } from '@ionic/core';
import { PlantProfileService } from '../plant-profiles.service';
import { PlantSaveRequestDto } from '../../../../api';
import { ProfileSelectModalComponent } from './profile-select-modal/profile-select-modal.component';
import { Router } from '@angular/router';
import { LangService } from '../../../services/lang.service';
import { UserService } from '../../../services/user.service';
import { catchError, map, of, take, tap } from 'rxjs';
import { FilesService } from '../files.service';
import { TranslateService } from '@ngx-translate/core';
import { AlertService } from '../../../services/alert.service';

@Component({
  selector: 'app-profile-add',
  templateUrl: './profile-add.component.html',
  styleUrls: ['./profile-add.component.scss'],
})
export class ProfileAddComponent {
  filteredItems: any = [];

  openToast: boolean = false;

  toastMessage: string = '';

  formData = new FormData();

  tempPictureArray: number[] = [];

  blob: any;

  defaultImage: string = '/assets/svg/default.svg';

  is_user_admin: boolean = false;

  imgPreview: any;

  newProfile: PlantSaveRequestDto = {
    file_id: null!,
    hum_max: 100,
    hum_min: 0,
    id_global: null!,
    id_user: null!,
    info_lang: [
      { lang: '', value: '' },
      { lang: '', value: '' },
    ],
    name_lang: [
      { lang: '', value: '' },
      { lang: '', value: '' },
    ],
    temp_max: 50,
    temp_min: 0,
    interval: null!,
  };

  constructor(
    private modalCtrl: ModalController,
    public profilesService: PlantProfileService,
    private alertService: AlertService,
    private router: Router,
    private langService: LangService,
    private userService: UserService,
    private filesService: FilesService,
    private translate: TranslateService,
  ) {
    this.getList();
    this.is_user_admin = userService.userRole === 'admin';
  }

  onTempChange(ev: Event) {
    let changedValues: RangeValue = (ev as RangeCustomEvent).detail.value;
    if (typeof changedValues !== 'number') {
      this.newProfile.temp_min = changedValues.lower;
      this.newProfile.temp_max = changedValues.upper;
    }
  }

  getProfileThumbnail(buffer?: any) {
    if (!this.newProfile?.file_id) {
      return of('/assets/svg/default.svg');
    }

    if (buffer) {
      this.blob = undefined!;
    }

    if (!this.blob) {
      this.blob = this.filesService.getImage(this.newProfile.file_id).pipe(
        take(1),
        map((blob) => {
          // TODO: Find better solution for that
          (document.getElementById('new-profile-image') as HTMLImageElement).src =
            URL.createObjectURL(blob);
          return URL.createObjectURL(blob);
        }),
        catchError(() => of('/assets/svg/default.svg')),
      );
    }
    return this.blob;
  }

  onHumidChange(ev: Event) {
    let changedValues: RangeValue = (ev as RangeCustomEvent).detail.value;
    if (typeof changedValues !== 'number') {
      this.newProfile.hum_min = changedValues.lower;
      this.newProfile.hum_max = changedValues.upper;
    }
  }

  selectInterval(event: Event) {
    this.newProfile.interval = (<any>event).detail.value;
  }

  blockElementFromUser() {
    return this.userService.userRole === 'user';
  }

  humidPinFormatter(value: number) {
    return `${value}%`;
  }

  tempPinFormatter(value: number) {
    return `${value}°C`;
  }

  getList() {
    this.profilesService
      .getProfilesList({
        filter: [],
        page_index: 0,
        page_size: 1000,
        sort_direction: 'asc',
        lang: this.langService.language,
      })
      .subscribe({
        next: (res) => {
          if (res.cnt !== 0) {
            this.filteredItems = res.data;
          }
        },
        error: (err) => {
          this.alertService
            .showFailureAlert(err.message, 'Getting profiles list failed: got empty list')
            .then();
        },
      });
  }

  cancel() {
    this.router.navigateByUrl('/plant-profiles').then();

    this.tempPictureArray.forEach((fileId) => {
      this.filesService
        .deleteImage(fileId)
        .pipe(tap(() => {}))
        .subscribe();
    });
  }

  getDifferentLanguageName(): string {
    return this.langService.language === 'pl'
      ? this.translate.instant('LANG.ENG')
      : this.translate.instant('LANG.PL');
  }

  getDifferentLanguage(): string {
    return this.langService.language === 'en' ? 'pl' : 'en';
  }

  save() {
    if (!(this.newProfile.name_lang[0].value !== '' && this.newProfile.info_lang[0].value !== '')) {
      this.toastMessage = this.translate.instant('PP.TOAST.NAME');
      this.setToastState(true);
      return;
    }
    if (!(this.newProfile.name_lang[1].value !== '' && this.newProfile.info_lang[1].value !== '')) {
      if (this.userService.userRole === 'admin') {
        this.toastMessage = this.translate.instant('PP.TOAST.TRANSLATIONS');
        this.setToastState(true);
        return;
      }
      this.newProfile.name_lang[1].value = this.newProfile.name_lang[0].value;
      this.newProfile.info_lang[1].value = this.newProfile.info_lang[0].value;
    }
    if (!this.newProfile.interval) {
      this.toastMessage = this.translate.instant('PP.TOAST.INTERVAL');
      this.setToastState(true);
      return;
    }

    this.newProfile.name_lang[0].lang = this.langService.language;
    this.newProfile.info_lang[0].lang = this.langService.language;
    this.newProfile.name_lang[1].lang = this.getDifferentLanguage();
    this.newProfile.info_lang[1].lang = this.getDifferentLanguage();

    this.profilesService.addProfile(this.newProfile).subscribe({
      next: (res) => {
        if (res.code === 200) {
          this.alertService
            .showSuccessAlert(this.translate.instant('PP.TOAST.SAVE_SUCCESS'))
            .then();
          this.router.navigate(['/plant-profiles']).then();
        } else {
          this.alertService
            .showFailureAlert('', this.translate.instant('PP.TOAST.SAVE_ERROR'))
            .then();
        }
      },
      error: (err) => {
        this.alertService
          .showFailureAlert('', this.translate.instant('PP.TOAST.SAVE_ERROR'))
          .then();
      },
    });
  }

  async openPlantProfilesList() {
    const modal = await this.modalCtrl.create({
      component: ProfileSelectModalComponent,
      componentProps: {
        listData: this.filteredItems,
        selectedItem: this.newProfile,
      },
    });
    await modal.present();
    const { data, role } = await modal.onWillDismiss();
    if (role === 'save' && !data) {
      await this.alertService.showFailureAlert(
        '',
        this.translate.instant('PP.TOAST.NOTHING_SELECTED'),
      );
      return;
    }
    if (role === 'cancel') {
      return;
    }
    this.newProfile.name_lang[0].lang = this.langService.language;
    this.newProfile.name_lang[0].value = data.name;
    this.newProfile.info_lang[0].lang = this.langService.language;
    this.newProfile.info_lang[0].value = data.info;
    this.newProfile.id_global = data.id_global;
    this.newProfile.hum_min = data.hum_min;
    this.newProfile.hum_max = data.hum_max;
    this.newProfile.temp_min = data.temp_min;
    this.newProfile.temp_max = data.temp_max;
  }

  setToastState(isOpen: boolean) {
    this.openToast = isOpen;
  }

  onFileChange(event: Event) {
    // @ts-ignore
    const image: File = event.target.files[0];
    if (!image.name.includes('.jpg')) {
      this.alertService.showFailureAlert('', this.translate.instant('PP.TOAST.WRONG_IMAGE')).then();
      return;
    }
    if (image) {
      let reader = new FileReader();
      reader.onload = ($event: any) => {
        this.imgPreview = $event.target.result;
      };
      // @ts-ignore
      reader.readAsDataURL(event.target.files[0]);

      this.formData.delete('source');
      this.formData.delete('file');
      this.formData.append('source', this.is_user_admin ? 'plant_global' : 'plant');
      this.formData.append('file', image);
      this.filesService.postFile(this.formData).subscribe({
        next: (res) => {
          this.tempPictureArray.push(res.id);
          this.newProfile.file_id = res.id;
          this.getProfileThumbnail(res.buffer);
        },
        error: (err) => {
          this.alertService
            .showFailureAlert('', this.translate.instant('PP.TOAST.IMAGE_ERROR'))
            .then();
        },
      });
    }
  }

  openFileDialog() {
    (document as any).getElementById('file-upload').click();
  }
}
