/**
 * NIMUS CLOUD API Backend
 * NIMUS Cloud Backend Documentation
 *
 * OpenAPI spec version: 1.0.0
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { SensorSearchItemDto } from './sensorSearchItemDto';

export interface SensorsSearchRequestDto {
  /**
   * List filtered fields with search values
   */
  filter?: Array<SensorSearchItemDto>;
  /**
   * Sort direction
   */
  sort_direction: SensorsSearchRequestDto.SortDirectionEnum;
  /**
   * Show only new sensors
   */
  search_new: boolean;
  /**
   * Fields to be sorted
   */
  sort?: Array<string>;
  /**
   * Page index
   */
  page_index: number;
  /**
   * Page size
   */
  page_size: number;
  /**
   * Date from filter
   */
  date_from?: string;
  /**
   * Date to filter
   */
  date_to?: string;
}
export namespace SensorsSearchRequestDto {
  export type SortDirectionEnum = 'asc' | 'desc' | '';
  export const SortDirectionEnum = {
    Asc: 'asc' as SortDirectionEnum,
    Desc: 'desc' as SortDirectionEnum,
    Empty: '' as SortDirectionEnum,
  };
}
