/**
 * NIMUS CLOUD File API Backend
 * NIMUS Cloud File Backend Documentation
 *
 * OpenAPI spec version: 1.0.1
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface FileRequestDto {
  /**
   * Source from what we upload this file/photo (plant | feedback | sensor | plant_global).
   */
  source: FileRequestDto.SourceEnum;
  /**
   * Attached file. Only JPEG allowed
   */
  file: Blob;
}
export namespace FileRequestDto {
  export type SourceEnum = 'plant' | 'feedback' | 'sensor' | 'plant_global';
  export const SourceEnum = {
    Plant: 'plant' as SourceEnum,
    Feedback: 'feedback' as SourceEnum,
    Sensor: 'sensor' as SourceEnum,
    PlantGlobal: 'plant_global' as SourceEnum,
  };
}
