import { ApiModule as FileApiModule } from './../file-api/api.module';
import { APP_INITIALIZER, LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { UserProfileModule } from './pages/user-profile/user-profile.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AuthModule as Auth0Module } from '@auth0/auth0-angular';
import { PlantProfilesModule } from './pages/plant-profiles/plant-profiles.module';
import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from '@angular/common/http';
import { CookieModule } from 'ngx-cookie';
import { ConfigService } from './services/config.service';
import { AuthComponent } from './pages/auth/auth.component';
import { GuestLayoutComponent } from './layout/guest-layout/guest-layout.component';
import { MainLayoutComponent } from './layout/main-layout/main-layout.component';
import { SharedModule } from './shared/shared.module';
import { NgOptimizedImage, registerLocaleData } from '@angular/common';
import { SensorsModule } from './pages/sensors/sensors.module';
import { ApiModule } from '../api';
import { ServiceWorkerModule } from '@angular/service-worker';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { PwaService } from './services/pwa.service';
import { Auth0Interceptor } from './auth0.interceptor';
import { DBConfig, NgxIndexedDBModule } from 'ngx-indexed-db';
import { LazyLoadImageModule } from 'ng-lazyload-image';
import * as echarts from 'echarts/core';
import { TooltipComponent } from 'echarts/components';
import { NgxEchartsModule } from 'ngx-echarts';
import { environment } from '../environments/environment';
import { PolicyModule } from './pages/policy/policy.module';
import { WebcamModule } from 'ngx-webcam';
import localePl from '@angular/common/locales/pl';

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

const dbConfig: DBConfig = {
  name: 'cache',
  version: 1,
  objectStoresMeta: [
    {
      store: 'plant-images',
      storeConfig: { keyPath: 'id', autoIncrement: false },
      storeSchema: [{ name: 'img', keypath: 'img', options: { unique: false } }],
    },
  ],
};

echarts.use(TooltipComponent);

registerLocaleData(localePl);

const initializer = (pwaService: PwaService) => () => pwaService.initPwaPrompt();

@NgModule({
  declarations: [AppComponent, AuthComponent, GuestLayoutComponent, MainLayoutComponent],
  imports: [
    BrowserModule,
    IonicModule.forRoot(),
    AppRoutingModule,
    UserProfileModule,
    PlantProfilesModule,
    BrowserAnimationsModule,
    Auth0Module.forRoot(ConfigService.getAuthConfig()),
    CookieModule.withOptions(),
    SharedModule,
    NgOptimizedImage,
    SensorsModule,
    PolicyModule,
    ApiModule,
    FileApiModule,
    HttpClientModule,
    LazyLoadImageModule,
    WebcamModule,
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.production,
      registrationStrategy: 'registerImmediately',
    }),
    TranslateModule.forRoot({
      defaultLanguage: 'en',
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient],
      },
    }),
    NgxIndexedDBModule.forRoot(dbConfig),
    NgxEchartsModule.forRoot({ echarts }),
  ],
  providers: [
    { provide: APP_INITIALIZER, useFactory: initializer, deps: [PwaService], multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: Auth0Interceptor, multi: true },
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    { provide: LOCALE_ID, useValue: 'pl' },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
