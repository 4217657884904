/**
 * NIMUS CLOUD API Backend
 * NIMUS Cloud Backend Documentation
 *
 * OpenAPI spec version: 1.0.0
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface LangItemDto {
  /**
   * Language short name -> \"en\" or \"pl\"
   */
  lang: string;
  /**
   * Value of something
   */
  value: string;
}
