import { Component } from '@angular/core';
import { SwUpdate } from '@angular/service-worker';
import { LangService } from './services/lang.service';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {
  constructor(private swUpdate: SwUpdate, private readonly langService: LangService) {
    this.langService.initialize();

    // See https://angular.io/api/service-worker/SwUpdate
    if (!this.swUpdate.isEnabled) {
      return;
    }
    this.swUpdate.versionUpdates.subscribe((event) => {
      if (event.type !== 'VERSION_READY') {
        return;
      }
      if (!confirm('Dostępna jest nowa wersja. Zaktualizować ?')) {
        return;
      }
      this.swUpdate
        .checkForUpdate()
        .then((_) => {
          window.location.reload();
        })
        .catch((e) => console.error(e));
    });
  }
}
