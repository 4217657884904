export let userFeedbackHistory: any[] = [
  {
    timestamp: '2023-03-19, 1:00',
    name: 'Oak_1',
    profile: 'Oak',
  },
  {
    timestamp: '2023-03-18, 2:00',
    name: 'Oak_1',
    profile: 'Oak',
  },
  {
    timestamp: '2023-03-26, 3:00',
    name: 'Cherry_1',
    profile: 'Cherry',
  },
  {
    timestamp: '2023-02-25, 4:00',
    name: 'Cherry_2',
    profile: 'Cherry',
  },
  {
    timestamp: '2023-02-19, 5:00',
    name: 'Oak_1',
    profile: 'Oak',
  },
];
