import { Injectable } from '@angular/core';
import { environment as env } from '../../environments/environment';
import { AuthConfig } from '@auth0/auth0-angular';

@Injectable({
  providedIn: 'root',
})
export class ConfigService {
  /**
   * Response with parameters for Auth Module, set application title
   * https://auth0.com/docs/quickstart/spa/angular/01-login
   */
  static getAuthConfig(): AuthConfig {
    if (!env.auth0.clientId || !env.auth0.domain) {
      console.error('Configuration for auth0 not found for origin ', window.location.origin);
      // @ts-ignore
      return null;
    }

    const authApi = window.location.origin + '/api';

    return {
      domain: env.auth0.domain,
      clientId: env.auth0.clientId,
      authorizationParams: {
        redirect_uri: window.location.origin,
      },
      httpInterceptor: {
        allowedList: [
          {
            uri: authApi + '/version',
            allowAnonymous: true,
          },
          authApi,
          authApi + '/*',
          `https://${window.location.host}/api/*`,
        ],
      },
    };
  }
}
