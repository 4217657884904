import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { SensorsPageComponent } from './sensors-page/sensors-page.component';
import { SensorAddComponent } from './sensor-add/sensor-add.component';
import { HistoryComponent } from './history/history.component';
import { LeaveFeedbackComponent } from './leave-feedback/leave-feedback.component';
import { SensorEditComponent } from './sensor-edit/sensor-edit.component';

const routes: Routes = [
  {
    path: '',
    component: SensorsPageComponent,
  },
  {
    path: 'add',
    component: SensorAddComponent,
  },
  {
    path: ':id/history',
    component: HistoryComponent,
  },
  {
    path: ':id/leave-feedback',
    component: LeaveFeedbackComponent,
    data: { internal: true },
  },
  {
    path: ':id/edit',
    component: SensorEditComponent,
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class SensorsRoutingModule {}
