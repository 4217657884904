import { Injectable } from '@angular/core';
import { ToastController } from '@ionic/angular';

@Injectable({
  providedIn: 'root',
})
export class AlertService {
  constructor(private readonly toastCtrl: ToastController) {}

  async showSuccessAlert(message: string) {
    const toast = await this.toastCtrl.create({
      message: `${message}`,
      duration: 2000,
      position: 'top',
      cssClass: 'success-alert',
      icon: 'checkmark-outline',
    });

    await toast.present();
  }

  async showFailureAlert(code: any, message: string) {
    const toast = await this.toastCtrl.create({
      message: `${message} ${code}`,
      duration: 4000,
      position: 'top',
      cssClass: 'error-alert',
      icon: 'close-outline',
    });

    await toast.present();
  }
}
