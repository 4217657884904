import { Component } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { userFeedbackHistory } from '../../../shared/mock-data/user-feedback-history';
import { Router } from '@angular/router';

@Component({
  selector: 'app-feedback-history',
  templateUrl: './feedback-history.component.html',
  styleUrls: ['./feedback-history.component.scss'],
})
export class FeedbackHistoryComponent {
  constructor(private modalCtrl: ModalController, private router: Router) {}

  tableHeaders: {
    name: string;
    value: string;
  }[] = [
    {
      name: 'Timestamp',
      value: 'timestamp',
    },
    {
      name: 'Name',
      value: 'name',
    },
    {
      name: 'Profile',
      value: 'profile',
    },
    {
      name: 'actions',
      value: 'actions',
    },
  ];

  actions: string[] = ['edit', 'delete'];

  tableData: any[] = userFeedbackHistory;

  close() {
    return this.modalCtrl.dismiss(null, 'close');
  }
}
