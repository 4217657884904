/**
 * NIMUS CLOUD API Backend
 * NIMUS Cloud Backend Documentation
 *
 * OpenAPI spec version: 1.0.0
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { GatewaySearchItemDto } from './gatewaySearchItemDto';

export interface GatewaySearchRequestDto {
  /**
   * List filtered fields with search values
   */
  filter?: Array<GatewaySearchItemDto>;
  /**
   * Sort direction
   */
  sort_direction: GatewaySearchRequestDto.SortDirectionEnum;
  /**
   * Fields to be sorted
   */
  sort?: Array<string>;
  /**
   * Page index
   */
  page_index: number;
  /**
   * Page size
   */
  page_size: number;
}
export namespace GatewaySearchRequestDto {
  export type SortDirectionEnum = 'asc' | 'desc' | '';
  export const SortDirectionEnum = {
    Asc: 'asc' as SortDirectionEnum,
    Desc: 'desc' as SortDirectionEnum,
    Empty: '' as SortDirectionEnum,
  };
}
