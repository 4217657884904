import { Component, ViewChild } from '@angular/core';
import { SensorsTableComponent } from '../sensors-table/sensors-table.component';
import { InfiniteScrollCustomEvent } from '@ionic/angular';
// eslint-disable-next-line import/no-extraneous-dependencies,import/no-unresolved
// @ts-ignore
import * as moment from 'moment';

@Component({
  selector: 'app-sensors',
  templateUrl: './sensors-page.component.html',
  styleUrls: ['./sensors-page.component.scss'],
})
export class SensorsPageComponent {
  // @ts-ignore
  @ViewChild(SensorsTableComponent) sensorsTable: SensorsTableComponent;

  allSensorsLoaded: boolean = false;

  isConnectionLost: boolean = false;

  areThereAnySensors: boolean = true;

  hideShowMoreButton: boolean = false;

  // eslint-disable-next-line @typescript-eslint/no-useless-constructor
  constructor() {}

  ionViewDidEnter() {
    if (this.sensorsTable.numberOfLoadedItems > 0) {
      this.sensorsTable.restoreSensors();
    } else {
      // this.sensorsTable.fetchSensors();
    }
  }

  onIonInfinite(event: Event) {
    this.hideShowMoreButton = true;
    if (this.sensorsTable.tableData.length === 0) {
      (event as InfiniteScrollCustomEvent).target.complete().then();
      return;
    }
    if (!this.allSensorsLoaded) {
      this.sensorsTable.fetchSensors().subscribe(() => {
        if ((event as InfiniteScrollCustomEvent).target) {
          (event as InfiniteScrollCustomEvent).target.complete().then();
        }
      });
    } else {
      (event as InfiniteScrollCustomEvent).target.complete().then();
    }
  }

  onAllLoadedChange(state: boolean) {
    this.allSensorsLoaded = state;
  }

  ionViewDidLeave() {
    this.sensorsTable.clearSensorsTable();
    this.sensorsTable.isDataFetched = false;
    this.allSensorsLoaded = false;
  }

  checkSensorConnection(sensors: any) {
    this.isConnectionLost = sensors.some(
      (sensor: any) => moment().diff(sensor.last_connected, 'hours') >= 24,
    );
  }

  howManyLoaded(howMany: number) {
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    howMany === 0 ? (this.areThereAnySensors = false) : (this.areThereAnySensors = true);
  }

  loadMore() {
    const event = new Event('ionInfinite');
    this.onIonInfinite(event);
    this.hideShowMoreButton = true;
  }
}
