import { NgModule } from '@angular/core';
import { CommonModule, NgOptimizedImage } from '@angular/common';
import { PlantProfilesComponent } from './plant-profiles/plant-profiles.component';
import { PlantProfilesRoutingModule } from './plant-profiles-routing.module';
import { IonicModule } from '@ionic/angular';
import { SharedModule } from '../../shared/shared.module';
import { ProfileEditComponent } from './profile-edit/profile-edit.component';
import { PlantListComponent } from './plant-profiles/plant-list/plant-list.component';
import { ProfileAddComponent } from './profile-add/profile-add.component';
import { FormsModule } from '@angular/forms';
import { ProfileSelectModalComponent } from './profile-add/profile-select-modal/profile-select-modal.component';
import { PlantItemComponent } from './plant-item/plant-item.component';
import { TranslateModule } from '@ngx-translate/core';
import { LazyLoadImageModule } from 'ng-lazyload-image';

@NgModule({
  declarations: [
    PlantProfilesComponent,
    ProfileEditComponent,
    PlantListComponent,
    ProfileAddComponent,
    ProfileSelectModalComponent,
    PlantItemComponent,
  ],
  imports: [
    CommonModule,
    PlantProfilesRoutingModule,
    IonicModule,
    SharedModule,
    FormsModule,
    NgOptimizedImage,
    TranslateModule,
    LazyLoadImageModule,
  ],
})
export class PlantProfilesModule {}
