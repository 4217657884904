<ion-header>
  <ion-toolbar>
    <ion-title class="ion-text-start">{{ 'PP.ADD.SELECT.TITLE' | translate }}</ion-title>
    <ion-buttons slot="end" (click)="cancel()" class="ion-padding-end">
      <ion-icon name="close-outline" size="large" color="nimus_grey"></ion-icon>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content color="light" class="ion-padding">
  <ion-searchbar
    class="ion-padding-vertical"
    (ionInput)="searchbarInput($event)"
    [placeholder]="translate.instant('COMMON.SEARCH')"
  ></ion-searchbar>
  <ion-radio-group
    [allowEmptySelection]="true"
    [value]="selectedItem"
    (ionChange)="onSelect($event)"
  >
    <ion-item *ngFor="let item of filteredItems">
      <ion-label>{{ item.name }}</ion-label>
      <ion-radio [value]="item"></ion-radio>
    </ion-item>
  </ion-radio-group>
  <ion-row
    *ngIf="!filteredItems.length"
    class="ion-justify-content-center animate__animated animate__fadeIn animate__fast"
  >
    <ion-col size="auto">
      <ion-text> No data found</ion-text>
    </ion-col>
  </ion-row>
</ion-content>
<ion-footer class="ion-padding-vertical">
  <ion-grid>
    <ion-row class="ion-justify-content-center">
      <ion-col size="auto">
        <ion-button
          class="ion-align-self-center"
          fill="outline"
          color="nimus_red"
          size="medium"
          (click)="cancel()"
          >{{ 'COMMON.CANCEL' | translate }}
        </ion-button>
        <ion-button
          class="ion-align-self-center ion-margin-start"
          fill="solid"
          color="primary"
          size="medium"
          (click)="save()"
          >{{ 'COMMON.SAVE' | translate }}
        </ion-button>
      </ion-col>
    </ion-row>
  </ion-grid>
</ion-footer>
