/**
 * NIMUS CLOUD API Backend
 * NIMUS Cloud Backend Documentation
 *
 * OpenAPI spec version: 1.0.0
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { PlantSearchItemResponseDto } from './plantSearchItemResponseDto';

export interface PlantSearchResponseDto {
  /**
   * Number of filtered items
   */
  cnt: number;
  /**
   * Response with plant profile array
   */
  data: Array<PlantSearchItemResponseDto>;
}
