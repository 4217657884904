import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { LangService } from '../../../services/lang.service';
import { ToastController } from '@ionic/angular';
import { PlantProfileService } from '../../plant-profiles/plant-profiles.service';
import { FeedbacksService } from '../feedbacks.service';
import {
  FeedbackItemDto,
  FeedbackUnloggedItemDto,
  PlantItemSearchDto,
  PlantSearchRequestDto,
} from '../../../../api';
import { SensorsService } from '../sensors.service';
import { FilesService } from '../../plant-profiles/files.service';
import { Observable, Subject } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import LangEnum = PlantSearchRequestDto.LangEnum;
import { WebcamImage } from 'ngx-webcam';
import { AlertService } from '../../../services/alert.service';

@Component({
  selector: 'app-leave-feedback',
  templateUrl: './leave-feedback.component.html',
  styleUrls: ['./leave-feedback.component.scss'],
})
export class LeaveFeedbackComponent {
  protected readonly feedbackForm: FormGroup;

  private readonly sensorId: number = Number(
    <string>this.activatedRoute.snapshot.paramMap.get('id'),
  );

  protected plantProfile: string = <string>'';

  protected isDataFetched: boolean = false;

  buttonBlocker: boolean = false;

  currentLang = this.langService.language;

  newFeedback: FeedbackUnloggedItemDto = { height: 0 };

  internalRoute: boolean = false;

  private savedPlantProfileId: string = '';

  showWebcam: boolean = false;

  // webcam snapshot trigger
  private trigger: Subject<void> = new Subject<void>();

  // latest snapshot
  public webcamImage: WebcamImage | null = null;

  private idFromRouteParam: string;

  constructor(
    private readonly feedbacksService: FeedbacksService,
    private readonly profilesService: PlantProfileService,
    private readonly formBuilder: FormBuilder,
    private readonly activatedRoute: ActivatedRoute,
    private readonly router: Router,
    protected readonly langService: LangService,
    private readonly toastCtrl: ToastController,
    private readonly filesService: FilesService,
    private readonly sensorsService: SensorsService,
    public translate: TranslateService,
    private alertService: AlertService,
  ) {
    this.feedbackForm = this.formBuilder.group({
      prepared_hash: [null, [Validators.nullValidator]],
      file_id: [null, [Validators.nullValidator]],
      sensor_id: [this.sensorId, [Validators.required, Validators.min(1)]],
      height: [null, [Validators.required, Validators.min(1)]],
    });
    this.idFromRouteParam = <string>this.activatedRoute.snapshot.paramMap.get('id');
    this.activatedRoute.data.subscribe((data) => {
      // console.log('Data from route: ', data, data['internal'], 'url ', idFromRouteParam);
      if (data['internal']) {
        this.internalRoute = true;
        // In this case we have sensor id in url
        this.loadById(this.idFromRouteParam);
        return;
      }
      // Opened from external link, in this case we have UUID 59bfceb4-e533-4d89-8315-64c0b26bd879
      this.loadByHash(this.idFromRouteParam);
    });
    // Take care about language change and loaded profile names
    this.translate.onLangChange.subscribe((event) => {
      this.currentLang = <LangEnum>event.lang;
      if (this.savedPlantProfileId !== '') {
        this.getSensorPlantProfile(this.savedPlantProfileId);
        return;
      }
      this.getSensorPlantProfile(); // Reload based on hash data
    });
  }

  /**
   * Load sensor data based on sensor id
   * @param sensorId string
   */
  loadById(sensorId: string) {
    this.sensorsService.getSensorById(Number(sensorId)).subscribe({
      next: (res) => {
        // console.log('Sensor by id: ', res);
        this.newFeedback = {
          height: 0,
          sensor_id: res.id,
          sensor_name: res.name,
        };

        if (res.id_user) {
          this.getSensorPlantProfile('u' + res.id_user);
        } else {
          this.getSensorPlantProfile('g' + res.id_global);
        }
      },
      error: (err) => {
        this.displayToast(
          this.translate.instant('TOAST.ERROR.GET_SENSOR_DATA'),
          'error-alert',
        ).then();
      },
    });
  }

  loadByHash(hash: string) {
    this.feedbacksService.getFeedbackByHash(hash).subscribe({
      next: (res) => {
        // console.log('Feedback by hash: ', res);
        this.isDataFetched = true;
        this.newFeedback = res;
        this.getSensorPlantProfile();
        // if (res.sensor_id) {
        //   this.urlId = res.sensor_id.toString();
        // }
      },
      error: (err) => {
        this.displayToast(
          this.translate.instant('TOAST.ERROR.GET_SENSOR_DATA'),
          'error-alert',
        ).then();
      },
    });
  }

  /**
   * Get plant profile name by id or from feedback variable
   * @param plantProfileId - could be undefined, then we get plant profile from feedback variable
   */
  getSensorPlantProfile(plantProfileId?: string) {
    // console.log('getSensorPlantProfile', plantProfileId, 'saved', this.savedPlantProfileId);
    // Plant profile is at feedback when hash provided
    if (!plantProfileId) {
      // console.log('Choose name from feedback', this.newFeedback.plant_profile);
      this.plantProfile =
        this.newFeedback.plant_profile?.find((item) => item.language === this.currentLang)?.name ??
        '...';
      return;
    }
    this.savedPlantProfileId = plantProfileId;
    this.profilesService.getProfile(plantProfileId).subscribe({
      next: (response: PlantItemSearchDto) => {
        this.plantProfile =
          response.name_lang.find((index) => index.lang === this.currentLang)?.value ?? '...';
        this.isDataFetched = true;
      },
      error: () => {
        this.alertService.showFailureAlert('', 'Error while fetching sensors plant profile').then();
      },
    });
  }

  async displayToast(text: string, type: string) {
    const toast = await this.toastCtrl.create({
      message: text,
      duration: 3000,
      position: 'top',
      cssClass: type,
      // icon: 'checkmark-outline',
    });
    await toast.present();
  }

  save() {
    if (this.showWebcam) {
      this.trigger.next();
      this.showWebcam = false;
    }
    this.buttonBlocker = true;
    if (this.feedbackForm.invalid) {
      return;
    }
    if (this.internalRoute) {
      this.saveInternalFeedback();
      return;
    }
    this.saveExternalFeedback();
  }

  saveInternalFeedback() {
    this.saveInternalFile().subscribe({
      error: (err) => {
        this.buttonBlocker = false;
        this.displayToast(
          this.translate.instant('TOAST.ERROR.GET_SENSOR_DATA'),
          'error-alert',
        ).then();
      },
      next: (id) => {
        this.feedbackForm.patchValue({
          file_id: id,
        });
        this.feedbacksService.saveFeedback(this.feedbackForm.value).subscribe({
          error: (err) => {
            if (err.error.statusCode === 409) {
              this.buttonBlocker = true;
              this.displayToast(
                this.translate.instant('TOAST.ERROR.SEND_FEEDBACK_LIMIT'),
                'error-alert',
              ).then();
            } else {
              this.buttonBlocker = false;
              this.displayToast(
                this.translate.instant('TOAST.ERROR.FEEDBACK_SAVE'),
                'error-alert',
              ).then();
            }
          },
          next: (response) => {
            this.buttonBlocker = false;
            if (response.code === 200) {
              this.displayToast(this.translate.instant('FEEDBACK.SUCCESS'), 'success-alert').then();
              this.router
                .navigate([`sensors/${this.sensorId}/history`], { state: ['feedback'] })
                .then();
            }
          },
        });
      },
    }); // save file and get file id
  }

  saveExternalFeedback() {
    this.newFeedback.height = this.feedbackForm.value.height;
    this.saveExternalFile(this.idFromRouteParam).subscribe({
      error: (err) => {
        this.buttonBlocker = false;
        this.displayToast(this.translate.instant('TOAST.ERROR.FILE_SAVE'), 'error-alert').then();
        return;
      },
      next: (fileId) => {
        // Save feedback for not logged user
        const saveFeedbackRequest: FeedbackItemDto = this.newFeedback;
        if (fileId) {
          saveFeedbackRequest.file_id = fileId;
        }
        this.feedbacksService.saveUnloggedFeedback(saveFeedbackRequest).subscribe({
          error: (err) => {
            // console.error('saveUnloggedFeedback', err);
            this.displayToast(
              this.translate.instant('TOAST.ERROR.FEEDBACK_SAVE'),
              'error-alert',
            ).then();
          },
          next: (res) => {
            if (res.code === 200) {
              this.displayToast(this.translate.instant('FEEDBACK.SUCCESS'), 'success-alert').then();
              setTimeout(() => {
                this.router.navigate([`auth`]).then();
                this.buttonBlocker = false;
              }, 3000);
              return;
            }
            if (res.code === 403) {
              this.buttonBlocker = false;
              this.displayToast(
                this.translate.instant('TOAST.ERROR.INVALID_CREDENTIALS'),
                'warning-alert',
              ).then();
              return;
            }
            this.displayToast(this.translate.instant('POLICY.ERROR'), 'danger-alert').then();
          },
        });
      },
    });
  }

  private getImageBlob(): Blob {
    const byteString: string = atob(this.webcamImage?.imageAsBase64 ?? '');
    const arrayBuffer: ArrayBuffer = new ArrayBuffer(byteString.length);
    const int8Array: Uint8Array = new Uint8Array(arrayBuffer);
    for (let i = 0; i < byteString.length; i++) {
      int8Array[i] = byteString.charCodeAt(i);
    }
    return new Blob([int8Array], { type: 'image/jpeg' });
  }

  saveInternalFile(): Observable<number | null> {
    return new Observable<number | null>((observer) => {
      if (!this.webcamImage) {
        observer.next(null);
        return;
      }
      let formData = new FormData();
      formData.append('source', 'feedback');
      formData.append('file', this.getImageBlob(), 'feedback.jpg');
      this.filesService.postFile(formData).subscribe({
        next: (res) => {
          // console.log('File uploaded ', res.id);
          observer.next(res.id);
        },
        error: (err) => {
          this.displayToast(this.translate.instant('TOAST.ERROR.IMAGE_SAVE'), 'error-alert').then();
          // console.log('File upload error ', err);
          observer.error(err);
        },
      });
    });
  }

  saveExternalFile(hash: string): Observable<number | null> {
    return new Observable<number | null>((observer) => {
      if (!this.webcamImage) {
        observer.next(null);
        return;
      }
      let formData = new FormData();
      formData.append('hash', hash);
      formData.append('file', this.getImageBlob(), 'feedback.jpg');
      this.filesService.saveFeedbackFile(formData).subscribe({
        next: (res) => {
          // console.log('File uploaded ', res.id);
          observer.next(res.id);
        },
        error: (err) => {
          if (err.status === 404) {
            // console.log('error expired link');
            this.buttonBlocker = false;
            this.displayToast(
              this.translate.instant('TOAST.ERROR.FEEDBACK_ALREADY_FILLED'),
              'error-alert',
            ).then();
            return;
          }
          if (err.status === 410) {
            // console.log('error expired link');
            this.buttonBlocker = false;
            this.displayToast(
              this.translate.instant('TOAST.ERROR.FEEDBACK_LINK_EXPIRED'),
              'error-alert',
            ).then();
            return;
          }
          this.buttonBlocker = false;
          this.displayToast(
            this.translate.instant('TOAST.ERROR.FEEDBACK_SAVE'),
            'error-alert',
          ).then();
          observer.error(err);
          return;
          // console.log('File upload error ', err.status, err);
        },
      });
    });
  }

  public get triggerObservable(): Observable<void> {
    return this.trigger.asObservable();
  }

  public handleImage(webcamImage: WebcamImage): void {
    this.webcamImage = webcamImage;
  }

  takePhoto() {
    this.trigger.next();
    this.showWebcam = false;
  }
}
