/**
 * NIMUS CLOUD API Backend
 * NIMUS Cloud Backend Documentation
 *
 * OpenAPI spec version: 1.0.0
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface GatewaySaveRequestDto {
  /**
   * Identification of gateway - must be 0
   */
  id: number;
  /**
   * Name of gateway
   */
  name: string;
}
