import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
  constructor(private readonly translateService: TranslateService) {}

  ngOnInit() {}

  getPageTitle() {
    const title = window.location.pathname.toString().split('/')[1];

    switch (title) {
      case 'profile':
        return this.translateService.instant('BAR.USER');
      case 'plant-profiles':
        return this.translateService.instant('BAR.PROFILES');
      case 'sensors':
        return this.translateService.instant('BAR.SENSORS');
      case 'gateways':
        return this.translateService.instant('BAR.GATEWAYS');
      case 'policy':
        return this.translateService.instant('BAR.POLICY');
    }
  }
}
