<ion-header class="ion-no-border" mode="md">
  <ion-toolbar color="nimus-md3-green-bg" class="toolbar ion-padding" mode="md">
    <div class="header-content">
      <ion-title size="md" class="header-content__title">{{
        getPageTitle() | titlecase
      }}</ion-title>
    </div>
  </ion-toolbar>
  <div class="header-content__buttons">
    <app-language-button></app-language-button>
    <app-logout-button></app-logout-button>
  </div>
</ion-header>
