export let mockProfilesData: any = [
  {
    created_at: '2023-04-24 08:03:28.020',
    created_by: 'fb',
    file_id: 0,
    hum_max: 56,
    hum_min: 12,
    id_global: 1,
    id_user: 0,
    info: 'Ja nie mogę niebo w gębie',
    modified_at: '',
    modified_by: '',
    name: 'Szyszunia',
    temp_max: 33,
    temp_min: 5,
  },
  {
    created_at: '2023-04-24 08:03:28.020',
    created_by: 'fb',
    file_id: 0,
    hum_max: 70,
    hum_min: 38,
    id_global: 2,
    id_user: 0,
    info: 'It is a pineapple but with a p',
    modified_at: '',
    modified_by: '',
    name_lang: 'Pinepapple',
    temp_max: 42,
    temp_min: 26,
  },
  {
    created_at: '2023-04-24 08:03:28.020',
    created_by: 'fb',
    file_id: 0,
    hum_max: 48,
    hum_min: 12,
    id_global: 3,
    id_user: 0,
    info: 'Puko i stuko',
    modified_at: '',
    modified_by: '',
    name_lang: 'Jabuko',
    temp_max: 32,
    temp_min: 11,
  },
  {
    created_at: '2023-04-24 08:03:28.020',
    created_by: 'fb',
    file_id: 0,
    hum_max: 56,
    hum_min: 8,
    id_global: 4,
    id_user: 0,
    info: 'W kompot',
    modified_at: '',
    modified_by: '',
    name_lang: 'Śliwka',
    temp_max: 38,
    temp_min: 0,
  },
  {
    created_at: '2023-04-24 08:03:28.020',
    created_by: 'fb',
    file_id: 0,
    hum_max: 38,
    hum_min: 0,
    id_global: 5,
    id_user: 0,
    info: 'Uważaj na bździungwe, straszny to stwór',
    modified_at: '',
    modified_by: '',
    name_lang: 'Bździongwa',
    temp_max: 40,
    temp_min: 11,
  },
  {
    created_at: '2023-04-24 08:03:28.020',
    created_by: 'fb',
    file_id: 0,
    hum_max: 23,
    hum_min: 2,
    id_global: 6,
    id_user: 0,
    info: 'Hasiok pospolity',
    modified_at: '',
    modified_by: '',
    name_lang: 'Krzaczysko',
    temp_max: 20,
    temp_min: 3,
  },
  {
    created_at: '2023-04-24 08:03:28.020',
    created_by: 'fb',
    file_id: 0,
    hum_max: 64,
    hum_min: 46,
    id_global: 7,
    id_user: 0,
    info: 'Pospolite krzaczysko',
    modified_at: '',
    modified_by: '',
    name_lang: 'Hasiok',
    temp_max: 25,
    temp_min: 0,
  },
];
