<app-header *ngIf="userInfo !== null"></app-header>

<ion-header class="ion-no-border" mode="md" *ngIf="userInfo === null">
  <ion-toolbar color="nimus-md3-green-bg" class="header-toolbar" mode="md">
    <div class="header-content">
      <ion-title size="md" class="header-content__title">NIMUS</ion-title>
    </div>
  </ion-toolbar>
  <div class="header-content__buttons">
    <ion-fab slot="fixed" class="fixed-top-lang">
      <ion-fab-button size="large" class="language-button">
        <ion-icon name="language-outline"></ion-icon>
      </ion-fab-button>
      <ion-fab-list side="bottom">
        <ion-fab-button
          [color]="langService.language === lang ? 'primary' : ''"
          size="small"
          *ngFor="let lang of langList"
          (click)="changeLanguage(lang)">
          <ion-icon [src]="'assets/flags/' + lang.toUpperCase() + '.svg'"></ion-icon>
        </ion-fab-button>
      </ion-fab-list>
    </ion-fab>

    <ion-fab slot="fixed" class="fixed-top-login">
      <ion-fab-button size="large" class="login-button" (click)="login()">
        {{ 'POLICY.LOGIN' | translate }}
      </ion-fab-button>
    </ion-fab>
  </div>
</ion-header>

<ion-content color="nimus-md3-green">
  <ion-card style="margin-top: 24px">
    <ion-card-content class="user-card">
      <div class="information">
        <div class="information__details">
          <div class="information__details__names">
            <ion-text class="title">{{ 'POLICY.TITTLE' | translate }}</ion-text>
          </div>
        </div>
      </div>
    </ion-card-content>
  </ion-card>

  <ion-card *ngIf="!filesLoaded" class="mt-5">
    <ion-card-content>
      <ion-item lines="none" class="ion-no-padding">
        <ion-skeleton-text [animated]="true"></ion-skeleton-text>
        <ion-icon name="download-outline" size="large"></ion-icon>
      </ion-item>
    </ion-card-content>
  </ion-card>

  <div *ngIf="filesLoaded">
    <div *ngIf="thereAreFiles">
      <ion-card *ngFor="let file of filesList" class="mt-5">
        <ion-card-content>
          <ion-item lines="none" class="ion-no-padding">
            <ion-label class="notify-header">{{file.uid}}</ion-label>
            <ion-button fill="clear" (click)="downloadFile(file.uid)">
              <ion-icon name="download-outline" size="large"></ion-icon>
<!--              <a href="file-api/policy/get/{{file.uid}}">-->
<!--                {{ 'POLICY.DOWNLOAD_FILE' | translate }}-->
<!--              </a>-->
            </ion-button>
          </ion-item>
        </ion-card-content>
      </ion-card>
    </div>

    <div *ngIf="!thereAreFiles">
      <ion-card class="mt-5" >
        <ion-card-content>
          <ion-item lines="none" class="ion-no-padding">
            <ion-label class="notify-header">{{ 'POLICY.NO_FILES' | translate }}</ion-label>
          </ion-item>
        </ion-card-content>
      </ion-card>
    </div>
  </div>

</ion-content>

