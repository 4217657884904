import { Injectable } from '@angular/core';
import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { AuthService } from '@auth0/auth0-angular';
import { Observable, throwError } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';

@Injectable()
export class Auth0Interceptor implements HttpInterceptor {
  constructor(private authService: AuthService) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return this.authService.isAuthenticated$.pipe(
      switchMap((isAuthenticated) => {
        if (!isAuthenticated) {
          return next.handle(request);
        }

        return this.authService.getAccessTokenSilently().pipe(
          switchMap((token) => {
            if (token) {
              // If we have a token, we set it to the header
              request = request.clone({
                setHeaders: { Authorization: `Bearer ${token}` },
              });
            }

            return next.handle(request).pipe(
              catchError((err) => {
                if (err instanceof HttpErrorResponse) {
                  if (err.status === 401) {
                    console.error('Auth0 authorization failed error: ', err);
                  }
                }
                return throwError(() => err);
              }),
            );
          }),
        );
      }),
    );
  }
}
