import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { SensorItemDto, SensorsSearchRequestDto } from '../../../../api';
import { SensorsService } from '../sensors.service';
import { ActionSheetController, ToastController } from '@ionic/angular';
import { ActivatedRoute, Router } from '@angular/router';
import SortDirectionEnum = SensorsSearchRequestDto.SortDirectionEnum;
import { TranslateService } from '@ngx-translate/core';
import { Observable, Subject } from 'rxjs';
import moment from 'moment';
import { animate, AnimationBuilder, keyframes, style } from '@angular/animations';
import { SortParams } from '../../../shared/components/sort/sort.component';
import { PlantProfileService } from '../../plant-profiles/plant-profiles.service';
import { AlertService } from '../../../services/alert.service';

@Component({
  selector: 'app-sensors-table',
  templateUrl: './sensors-table.component.html',
  styleUrls: ['./sensors-table.component.scss'],
})
export class SensorsTableComponent implements OnInit {
  tableHeaders: {
    name: string;
    value: string;
  }[] = [
    {
      name: 'SENSOR.SERIAL_NUMBER',
      value: 'serial_number',
    },
    {
      name: 'SENSOR.LAST_CONNECTED',
      value: 'last_connected',
    },
    {
      name: 'SENSOR.CREATED',
      value: 'created_at',
    },
    {
      name: 'SENSOR.BATTERY',
      value: 'battery_level',
    },
    {
      name: 'actions',
      value: 'actions',
    },
  ];

  public numberOfLoadedItems: number = 0;

  @Output() allLoaded: EventEmitter<boolean> = new EventEmitter<boolean>();

  @Output() dataFetched: EventEmitter<any> = new EventEmitter<any>();

  @Output() howManyLoaded: EventEmitter<any> = new EventEmitter<any>();

  headerValues: string[] = [];

  headerNames: string[] = [];

  tableData: any[] = [];

  aiIcon = '/assets/svg/ai-small.svg';

  sortParams: {
    field: string;
    direction: SortDirectionEnum;
  } = {
    field: 'created_at',
    direction: 'desc',
  };

  startSort: SortParams = {
    value: 'created_at',
    name: 'SENSOR.SORT',
    direction: 'desc',
  };

  listRequest: SensorsSearchRequestDto = {
    filter: [],
    page_index: 0,
    page_size: 15,
    sort_direction: 'asc',
    search_new: false,
  };

  searchValue: string = '';

  isDataFetched: boolean = false;

  displayDaysAgoFormat1 = true;

  displayDaysAgoFormat2 = true;

  constructor(
    private readonly sensorsService: SensorsService,
    private alertService: AlertService,
    private readonly actionSheetCtrl: ActionSheetController,
    private readonly router: Router,
    private readonly route: ActivatedRoute,
    private readonly toastCtrl: ToastController,
    public translate: TranslateService,
    private animationBuilder: AnimationBuilder,
    public profileService: PlantProfileService,
  ) {}

  ngOnInit() {
    this.headerValues = this.tableHeaders.map((item) => item.value);
    this.headerNames = this.tableHeaders.map((item) => item.name);
    this.sortData({ field: 'created_at', direction: 'desc' });
  }

  public fetchSensors(): Observable<void> {
    const completed$ = new Subject<void>();
    this.sensorsService.getSensors(this.listRequest).subscribe({
      next: (res) => {
        for (let sensor of res.data) {
          this.tableData = [...this.tableData, sensor];
        }
        this.isDataFetched = true;
        this.listRequest.page_index += 1;
        this.numberOfLoadedItems = this.numberOfLoadedItems + this.listRequest.page_size;
        if (res.cnt < this.listRequest.page_size) {
          this.allLoaded.emit(true);
          // this.sensorsService
          //   .showSuccessAlert(this.translate.instant('SENSOR.SCROLL_LOADED'))
          //   .then();
        } else {
          this.allLoaded.emit(false);
        }
        completed$.next();
        this.dataFetched.emit(this.tableData);
        this.howManyLoaded.emit(res.cnt);
      },
      error: (err) => {
        this.alertService.showFailureAlert(err.message, 'Error while fetching sensors').then();
        this.isDataFetched = true;
        this.howManyLoaded.emit(0);
      },
    });
    return completed$.asObservable();
  }

  refreshData() {
    this.sortData({ field: 'created_at', direction: 'desc' });
    let element = document.getElementById('refreshButton');
    if (element) {
      const animation = this.rotateAnimation();
      animation.create(element).play();
    }
  }

  rotateAnimation() {
    return this.animationBuilder.build([
      style({ transform: 'scale(1) rotate(0deg)' }),
      animate(
        '400ms ease-in-out',
        keyframes([
          style({ transform: 'scale(1) rotate(0deg)' }),
          style({ transform: 'scale(1.2) rotate(180deg)' }),
          style({ transform: 'scale(1) rotate(360deg)' }),
        ]),
      ),
    ]);
  }

  public restoreSensors() {
    let request: SensorsSearchRequestDto = structuredClone(this.listRequest);
    request.page_index = 0;
    request.page_size = this.numberOfLoadedItems;
    this.sensorsService.getSensors(request).subscribe({
      next: (res) => {
        this.isDataFetched = true;
        this.tableData = res.data;
        this.isDataFetched = true;
        this.listRequest.page_index = this.numberOfLoadedItems / this.listRequest.page_size;
        this.numberOfLoadedItems = this.numberOfLoadedItems + this.listRequest.page_size;
        if (res.cnt < this.listRequest.page_size) {
          this.allLoaded.emit(true);
        }
      },
      error: () => {
        this.alertService.showFailureAlert('', 'Error while fetching sensor').then();
      },
    });
  }

  public clearSensorsTable() {
    this.tableData = [];
  }

  showLoading() {
    this.isDataFetched = false;
  }

  applySearchFilter(event: Event) {
    this.listRequest.page_index = 0;
    this.isDataFetched = false;
    this.allLoaded.emit(false);
    let searchValue = (event.target as HTMLInputElement).value;
    this.searchValue = searchValue.trim();
    this.searchValue = searchValue.toLowerCase();
    this.listRequest.filter = searchValue
      ? [
          {
            field: 'name',
            value: `%${searchValue}%`,
          },
        ]
      : [];
    this.tableData = [];
    this.fetchSensors();
  }

  sortData($event: any) {
    this.sortParams = JSON.parse(JSON.stringify($event));
    this.listRequest.page_index = 0;
    this.numberOfLoadedItems = 0;
    this.listRequest = {
      filter: this.searchValue
        ? [
            {
              field: 'name',
              value: `%${this.searchValue}%`,
            },
          ]
        : [],
      page_index: this.listRequest.page_index,
      page_size: this.listRequest.page_size,
      sort: this.sortParams.field ? [this.sortParams.field] : [],
      sort_direction: this.sortParams.direction ? this.sortParams.direction : '',
      search_new: false,
    };
    this.tableData = [];
    this.isDataFetched = false;
    this.fetchSensors();
  }

  async openFeedback(item: any) {
    this.router
      .navigate([item.id, 'leave-feedback'], {
        relativeTo: this.route,
        state: {
          plantProfile: item.id_user
            ? `u${item.id_user}`
            : item.id_global
            ? `g${item.id_global}`
            : undefined,
          sensorName: item.name,
        },
      })
      .then();
  }

  openHistory(item: SensorItemDto) {
    this.router.navigate([item.id, 'history'], { relativeTo: this.route }).then();
  }

  async openEdit(item: SensorItemDto) {
    this.router.navigate([item.id, 'edit'], { relativeTo: this.route }).then();
  }

  async showDeleteModal(item: SensorItemDto) {
    const actionSheet = await this.actionSheetCtrl.create({
      cssClass: 'action-sheet-delete',
      header: this.translate.instant('SENSOR.DELETE_TEXT', { sensor_name: item.name }),
      buttons: [
        {
          text: this.translate.instant('COMMON.DELETE'),
          role: 'destructive',
          data: {
            action: 'delete',
          },
        },
        {
          text: this.translate.instant('COMMON.CANCEL'),
          role: 'cancel',
          data: {
            action: 'cancel',
          },
        },
      ],
    });
    await actionSheet.present();
    const { data } = await actionSheet.onWillDismiss();
    if (data?.action === 'delete') {
      this.sensorsService.deleteSensor(item.id).subscribe({
        next: (res) => {
          if (res.code === 202) {
            this.showDeleteAlert(item);
            // Remove sensor from table
            this.tableData = this.tableData.filter((sensor) => sensor.id !== item.id);
            this.fetchSensors();
          }
        },
        error: () => {
          this.alertService.showFailureAlert('', 'Error while deleting sensor').then();
        },
      });
    }
  }

  async showDeleteAlert(item: any) {
    const toast = await this.toastCtrl.create({
      message: `Sensor deleted successfully`,
      duration: 3000,
      position: 'top',
      cssClass: 'success-alert',
      icon: 'checkmark-outline',
    });

    await toast.present();
  }

  public getDateFormat(item: any): string {
    const currentLang = this.translate.currentLang;

    switch (currentLang) {
      case 'pl':
        return 'HH:mm,   MM/dd/y';
      case 'en':
        return 'HH:mm,   MM/dd/y';
    }
    return 'MM/d/y, HH:mm';
  }

  toggleDisplayFormat1() {
    this.displayDaysAgoFormat1 = !this.displayDaysAgoFormat1;
  }

  toggleDisplayFormat2() {
    this.displayDaysAgoFormat2 = !this.displayDaysAgoFormat2;
  }

  // Below is a try to trigger accordion actions - toggle/untoggle by clicking the header -> it is bugged as hell and do not mix well with the accordion inner toggle

  // @ViewChildren(IonAccordion) accordions: QueryList<IonAccordion>;
  // @ViewChild('accordionGroup', { static: true }) accordionGroup: IonAccordionGroup;

  // toggleAccordion = (index: number) => {
  //   const nativeEl = this.accordions;
  //   console.log(nativeEl);
  //   // @ts-ignore
  //   if (nativeEl.get(index).value === String(index)) {
  //     // @ts-ignore
  //     nativeEl.get(index).value = undefined;
  //   } else {
  //     // @ts-ignore
  //     nativeEl.get(index).value = String(index);
  //   }
  // };

  // toggleAccordion(index: number) {
  //   const accordion = this.accordions.toArray()[index];
  //   if (accordion) {
  //     accordion.value === String(index)
  //       ? // @ts-ignore
  //         (accordion.value = undefined)
  //       : (accordion.value = String(index));
  //     console.log('accordion: ', accordion.value, 'acc list: ', this.accordions.toArray());
  //   }
  // }

  protected readonly moment = moment;
}
