<app-header></app-header>
<ion-content color="nimus-md3-green" class="has-header">
  <ion-card class="border-radius-10 ion-margin-vertical alert animate__animated animate__fast animate__fadeIn"
            *ngIf="isConnectionLost">
    <ion-card-content class="alert-bar">
      <ion-icon
        class="feedback__alert-icon ion-padding-end"
        name="alert-circle-outline"
        color="nimus_red"
        style="font-size:20px"
      ></ion-icon>
      <ion-text color="nimus_red">{{ 'SENSOR.NO_CONNECTION_WARNING' | translate }} </ion-text>
    </ion-card-content>
  </ion-card>
  <ion-card class="page-card border-radius-25">
    <ion-card-content class="animate__animated animate__fadeIn animate__faster">
      <app-sensors-table (allLoaded)="onAllLoadedChange($event)"
                         (dataFetched)="checkSensorConnection($event)"
                         (howManyLoaded)="howManyLoaded($event)"></app-sensors-table>
      <ion-content>

        <ion-infinite-scroll (ionInfinite)="onIonInfinite($event)" *ngIf="!allSensorsLoaded">
          <ion-infinite-scroll-content loadingText="{{ 'SENSOR.SCROLL_LOADING' | translate }}">
          </ion-infinite-scroll-content>
        </ion-infinite-scroll>
      </ion-content>
      <ion-label class="scroll_label" *ngIf="allSensorsLoaded && areThereAnySensors">{{
        'SENSOR.SCROLL_LOADED' | translate
        }}</ion-label>

      <ion-row class="load-more-button-column">
        <ion-col>
          <ion-button
            expand="block"
            class="ion-text-wrap button-load-more"
            fill="outline"
            *ngIf="!hideShowMoreButton && !allSensorsLoaded"
            (click)="loadMore()">
            {{ 'BUTTON.LOAD_MORE' | translate }}
          </ion-button>
        </ion-col>
      </ion-row>

    </ion-card-content>
  </ion-card>
  <ion-fab slot="fixed" vertical="bottom" horizontal="end">
    <ion-fab-button routerLink="add">
      <ion-icon name="add"></ion-icon>
    </ion-fab-button>
  </ion-fab>
</ion-content>
