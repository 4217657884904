/**
 * NIMUS CLOUD API Backend
 * NIMUS Cloud Backend Documentation
 *
 * OpenAPI spec version: 1.0.0
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { FeedbackRequireItemDto } from './feedbackRequireItemDto';

export interface FeedbackRequireResponseDto {
  /**
   * Count of sensors
   */
  cnt: number;
  /**
   * List of sensors id
   */
  data: Array<FeedbackRequireItemDto>;
}
