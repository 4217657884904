import { AlertService } from './../../../../../services/alert.service';
import { Component, Input, OnInit } from '@angular/core';
import { FeedbackItemDto } from '../../../../../../api';
import { FilesService } from '../../../../plant-profiles/files.service';
import { take } from 'rxjs';
import { ActionSheetController, ModalController, ToastController } from '@ionic/angular';
import { FeedbacksService } from '../../../feedbacks.service';
import { HistoryComponent } from '../../history.component';
import { FeedbackImgModalComponent } from '../feedback-img-modal/feedback-img-modal.component';
import { LangService } from '../../../../../services/lang.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-feedback-item',
  templateUrl: './feedback-item.component.html',
  styleUrls: ['./feedback-item.component.scss'],
})
export class FeedbackItemComponent implements OnInit {
  @Input() item: FeedbackItemDto;

  // eslint-disable-next-line @typescript-eslint/no-useless-constructor
  constructor(
    private readonly filesService: FilesService,
    private readonly actionSheetCtrl: ActionSheetController,
    private readonly feedbackSensors: FeedbacksService,
    private readonly history: HistoryComponent,
    private readonly toastCtrl: ToastController,
    private readonly modalCtrl: ModalController,
    protected readonly langService: LangService,
    private readonly translate: TranslateService,
    private readonly alertService: AlertService,
  ) {}

  ngOnInit() {
    this.getThumbnail();
  }

  blob: any = '';

  defaultImage: string = '/assets/svg/default.svg';

  imageLoaded: boolean = false;

  getThumbnail(): void {
    if (!this.item?.file_id) {
      this.blob = '/assets/svg/default.svg';
      return;
    }

    const fileId = this.item.file_id;
    this.filesService
      .getThumbnail(fileId)
      .pipe(take(1))
      .subscribe({
        next: (val) => {
          this.createImageFromBlob(val);
        },
        error: (response) => {
          this.blob = '/assets/svg/default.svg';
          this.alertService.showFailureAlert('', 'Error while fetching thumbnails').then();
          console.error('Loading thumbnails failed', response.message);
        },
      });
  }

  createImageFromBlob(img: Blob) {
    const reader = new FileReader();
    reader.addEventListener(
      'load',
      () => {
        this.blob = reader.result;
      },
      false,
    );
    if (img) {
      reader.readAsDataURL(img);
    }
  }

  async openFeedbackImgModal() {
    const modal = await this.modalCtrl.create({
      component: FeedbackImgModalComponent,
      componentProps: {
        feedback: this.item,
      },
    });
    await modal.present();
    const { data, role } = await modal.onWillDismiss();
  }

  async showDeleteModal(item: FeedbackItemDto) {
    const raportDate = new Date(item.modified_at as string).toLocaleDateString(
      this.translate.currentLang,
    );
    const actionSheet = await this.actionSheetCtrl.create({
      cssClass: 'action-sheet-delete',
      header: this.translate.instant('SENSOR.RAPORT.DELETE', { raport_date: raportDate }),
      buttons: [
        {
          text: 'Delete',
          role: 'destructive',
          data: {
            action: 'delete',
          },
        },
        {
          text: 'Cancel',
          role: 'cancel',
          data: {
            action: 'cancel',
          },
        },
      ],
    });
    await actionSheet.present();
    const { data, role } = await actionSheet.onWillDismiss();
    if (data?.action === 'delete') {
      this.feedbackSensors.deleteFeedback(item.prepared_hash!).subscribe({
        next: (response) => {
          if (response.code === 202) {
            this.showDeleteAlert();
            this.history.getFeedbackList();
          }
        },
        error: () => {
          this.showErrorAlert();
        },
      });
    }
  }

  async showDeleteAlert() {
    const toast = await this.toastCtrl.create({
      message: `Sensor deleted successfully`,
      duration: 3000,
      position: 'top',
      cssClass: 'success-alert',
      icon: 'checkmark-outline',
    });

    await toast.present();
  }

  async showErrorAlert() {
    const toast = await this.toastCtrl.create({
      message: `Error! This sensor cannot be deleted`,
      duration: 3000,
      position: 'top',
      cssClass: 'error-alert',
      icon: 'checkmark-outline',
    });

    await toast.present();
  }

  protected readonly String = String;
}
