<app-header></app-header>
<ion-content color="nimus-md3-green">
  <ion-card class="page-card border-radius-25">
    <ion-card-header class="animate__animated animate__fadeIn animate__faster">
      <ion-card-title class="ion-text-center ion-margin-bottom">Feedback history</ion-card-title>
    </ion-card-header>
    <ion-card-content class="animate__animated animate__fadeIn animate__faster">
      <app-feedback-history-table></app-feedback-history-table>
    </ion-card-content>
  </ion-card>
</ion-content>
