import { Component } from '@angular/core';
import { User } from '@auth0/auth0-angular';
import { AlertController, ViewWillEnter } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { LangService } from '../../../services/lang.service';
import { UserService } from '../../../services/user.service';
import {
  // @ts-ignore
  FeedbackRequireResponseDto,
  UserNotificationRequestDto,
  UserNotificationResponseDto,
} from '../../../../api';

import { PwaService } from '../../../services/pwa.service';
import { FeedbacksService } from '../../sensors/feedbacks.service';
import { AlertService } from '../../../services/alert.service';

@Component({
  selector: 'app-user-profile',
  templateUrl: './user-profile.component.html',
  styleUrls: ['./user-profile.component.scss'],
})
export class UserProfileComponent implements ViewWillEnter {
  authenticatedUser: User | null | undefined;

  numberOfSensors: number = 0;

  numberOfGateways: number = 0;

  feedbacksError: string = '';

  devicesLoaded: boolean = false;

  thereAreFeedbacks: boolean = false;

  notifications: UserNotificationRequestDto = {
    push: false,
    email: false,
    subscription: null,
  };

  feedbackAlerts: FeedbackRequireResponseDto = {
    cnt: 0,
    data: [],
  };

  constructor(
    private readonly userService: UserService,
    private feedbacksService: FeedbacksService,
    protected readonly langService: LangService,
    private readonly alertController: AlertController,
    private readonly translate: TranslateService,
    public pwaService: PwaService,
    private alertService: AlertService,
  ) {
    this.getFeedbackNotifications();
    this.userService.currentUserSub().then((user: User | null | undefined) => {
      this.authenticatedUser = user;
    });
  }

  getFeedbackNotifications() {
    this.feedbacksService.getSensorsAwaitingFeedbacks().subscribe({
      next: (res: FeedbackRequireResponseDto) => {
        this.feedbackAlerts = res;
        this.thereAreFeedbacks = res.cnt !== 0;
      },
      error: () => {
        this.alertService.showFailureAlert('', 'Error while fetching feedbacks').then();
      },
    });
  }

  giveUserName() {
    let name = this.authenticatedUser?.name;
    if (name != null) {
      return name.charAt(0).toUpperCase() + name.slice(1).split('@', 1);
    }
    return name;
  }

  ionViewWillEnter() {
    this.userService.getConnectedDevices().subscribe({
      next: (res: any) => {
        this.numberOfSensors = res.sensorsCount;
        this.numberOfGateways = res.gatewaysCount;
        this.devicesLoaded = true;
      },
      error: () => {
        this.alertService.showFailureAlert('', 'Error while fetching user devices').then();
      },
    });

    this.userService.getUserNotifications().subscribe({
      next: (res: UserNotificationResponseDto) => {
        if (res.push === undefined || res.email === undefined) {
          this.notifications = this.notifications;
        } else {
          this.notifications.push = res.push as boolean;
          this.notifications.email = res.email as boolean;
        }
      },
      error: () => {
        this.alertService.showFailureAlert('', 'Error while fetching user notifications').then();
      },
    });
  }

  logout() {
    this.userService.sessionLogout();
  }

  changeLanguage($event: any) {
    this.langService.language = $event.detail.value;
  }

  onPushNotificationChange(event: Event) {
    this.notifications.push = (event.target as HTMLInputElement).checked;
    if (this.notifications.push) {
      this.pwaService.subscribeToPush(this.notifications);
      return;
    }
    this.pwaService.unsubscribeFromPush(this.notifications);
  }

  onEmailNotificationChange(event: Event) {
    this.notifications.email = (event.target as HTMLInputElement).checked;
    this.userService.setUserNotifications(this.notifications);
  }

  async changePassword() {
    this.userService.changePassword().catch(async (response: any) => {
      if (response.status === 200) {
        const alert = await this.alertController.create({
          header: this.translate.instant('USER.PASSWORD_CHANGED_HEADER'),
          message: this.translate.instant('USER.PASSWORD_CHANGED_MESSAGE'),
          buttons: [
            {
              text: this.translate.instant('PWA.BUTTON.PROCEED'),
              role: 'confirm',
            },
          ],
          mode: 'ios',
        });
        await alert.present();
      }
    });
  }

  isLocalUser() {
    return !this.authenticatedUser?.sub?.includes('google-oauth2');
  }

  pwaInstall() {
    this.pwaService.resetIgnore();
    this.pwaService.initPwaPrompt();
  }
}
