/**
 * NIMUS CLOUD API Backend
 * NIMUS Cloud Backend Documentation
 *
 * OpenAPI spec version: 1.0.0
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface PredictionResponseDto {
  /**
   * Identifier of sensor
   */
  sensor_id: number;
  /**
   * Humidity prediction
   */
  humidity: number;
  /**
   * Temperature prediction
   */
  temperature: number;
  /**
   * Plant height prediction
   */
  height: number;
  /**
   * Prediction time
   */
  date: string;
}
