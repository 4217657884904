import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { NavigationStart, Router } from '@angular/router';
import { InfiniteScrollCustomEvent } from '@ionic/angular';
import { Components } from '@ionic/core';
import { TranslateService } from '@ngx-translate/core';
import { filter } from 'rxjs';
import { PlantSearchItemResponseDto, PlantSearchRequestDto } from '../../../../../api';
import { LangService } from '../../../../services/lang.service';
import { PlantProfileService } from '../../plant-profiles.service';
import IonAccordionGroup = Components.IonAccordionGroup;
import SortDirectionEnum = PlantSearchRequestDto.SortDirectionEnum;
import { AlertService } from '../../../../services/alert.service';

type LangEnum = PlantSearchRequestDto.LangEnum;

@Component({
  selector: 'plant-list',
  templateUrl: './plant-list.component.html',
  styleUrls: ['./plant-list.component.scss'],
})
export class PlantListComponent implements OnInit {
  loaded: boolean = false;

  @Input() listData: PlantSearchItemResponseDto[] = [];

  @Input() listHeaders: {
    name: string;
    value: string;
  }[] = [
    {
      name: 'PP.EDIT.NAME',
      value: 'name',
    },
    {
      name: 'ID',
      value: 'id_global',
    },
  ];

  @Output() editEvent = new EventEmitter<any>();

  listItems: PlantSearchItemResponseDto[] = [];

  headerValues: string[] = [];

  headerNames: string[] = [];

  keepLoadingScrollElements: boolean = true;

  hideShowMoreButton: boolean = true;

  howManyProfiles: any = null;

  sorting: {
    value: string;
    name?: string;
    direction: string;
  } = {
    value: '',
    name: '',
    direction: '',
  };

  requestFilter: PlantSearchRequestDto = {
    lang: this.langService.language,
    sort: ['name'],
    page_index: 0,
    page_size: 15,
    sort_direction: this.sorting.direction ? <SortDirectionEnum>this.sorting.direction : 'asc',
  };

  @ViewChild('accordionGroup', { static: true }) accordionGroup!: IonAccordionGroup;

  constructor(
    private router: Router,
    private profilesService: PlantProfileService,
    public langService: LangService,
    protected readonly translate: TranslateService,
    private alertService: AlertService,
  ) {
    this.fetchData();
  }

  ngOnInit() {
    this.headerValues = this.listHeaders.map((item) => item.value);
    this.headerNames = this.listHeaders.map((item) => item.name);
    this.router.events
      .pipe(filter((event) => event instanceof NavigationStart))
      // @ts-ignore
      .subscribe((event: NavigationStart) => {
        if (event.url === '/plant-profiles') {
          this.restoreList();
        }
      });

    // Listen for language changes
    this.translate.onLangChange.subscribe((langEvent) => {
      // Update the request filter with the new language
      this.requestFilter.lang = <LangEnum>langEvent.lang;

      // Make the request for the plant profile list
      this.profilesService.getProfilesList(this.requestFilter).subscribe(() => {
        this.restoreList();
      });
    });
  }

  setListSorting($event: any) {
    this.sorting = JSON.parse(JSON.stringify($event));
    this.restoreList();
  }

  restoreList() {
    let sortRequest = this.requestFilter;
    sortRequest.page_size = this.requestFilter.page_index * this.requestFilter.page_size;
    sortRequest.page_index = 0;
    sortRequest.sort_direction = <SortDirectionEnum>this.sorting.direction;
    this.profilesService.getProfilesList(sortRequest).subscribe({
      next: (res) => {
        if (res.cnt !== 0) {
          this.listItems = res.data;
          this.requestFilter.page_index++;
          return;
        }
        this.alertService
          .showFailureAlert('', 'Getting profiles list failed: got empty list')
          .then();
      },
      error: (err) => {
        this.alertService
          .showFailureAlert('', 'Getting profiles list failed: ' + err.message)
          .then();
      },
    });
  }

  fetchData() {
    this.profilesService.getProfilesList(this.requestFilter).subscribe({
      next: (res) => {
        if (res.cnt !== 0) {
          this.listItems = this.listItems.concat(res.data);
          this.requestFilter.page_index++;
          this.loaded = true;
          this.hideShowMoreButton = false;
          this.howManyProfiles += res.cnt;
          if (res.cnt < this.requestFilter.page_size) {
            this.hideShowMoreButton = true;
            this.keepLoadingScrollElements = false;
          }
          return;
        }
        this.keepLoadingScrollElements = false;
        this.hideShowMoreButton = true;
        this.alertService.showSuccessAlert(this.translate.instant('PP.SCROLL_LOADED')).then();
      },
      error: (err) => {
        this.alertService.showFailureAlert('', 'Getting profiles list failed: ' + err).then();
      },
    });
  }

  onScrolled(event: any) {
    this.hideShowMoreButton = true;
    this.fetchData();
    if (event.target != null) {
      setTimeout(() => {
        (event as InfiniteScrollCustomEvent).target.complete().then();
      }, 500);
    }
  }

  searchList(event: any) {
    let searchRequest = structuredClone(this.requestFilter);
    searchRequest.filter = [{ field: 'name', value: '%' + event.detail.value + '%' }];
    searchRequest.page_size = this.requestFilter.page_index * this.requestFilter.page_size;
    searchRequest.page_index = 0;
    searchRequest.sort_direction = <SortDirectionEnum>this.sorting.direction;
    this.profilesService.getProfilesList(searchRequest).subscribe({
      next: (res) => {
        this.listItems = res.data;
      },
      error: () => {
        this.alertService.showFailureAlert('', 'Error while fetching profiles list').then();
      },
    });
  }

  loadMore() {
    const event = new Event('ionInfinite');
    this.onScrolled(event);
    this.hideShowMoreButton = true;
  }
}
