import { Inject, Injectable } from '@angular/core';
import { AuthService, User } from '@auth0/auth0-angular';
import { DOCUMENT } from '@angular/common';
import { lastValueFrom, take } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import {
  FeedbacksService,
  UserNotificationRequestDto,
  UserService as ApiUserService,
} from '../../api';
import { environment } from '../../environments/environment';

type Roles = 'user' | 'admin';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  public userNickname: string | undefined;

  public userEmail: string | undefined;

  public userRole: Roles | undefined;

  constructor(
    @Inject(DOCUMENT) protected document: Document,
    private readonly authService: AuthService,
    private readonly http: HttpClient,
    private readonly userService: ApiUserService,
  ) {
    this.userService = new ApiUserService(
      http,
      environment.apiURL,
      // @ts-ignore
      undefined,
    );

    this.currentUserSub().then((user: User | null | undefined) => {
      this.userNickname = user?.nickname ?? 'unknown';
      this.userEmail = user?.email;
      this.userRole = user?.['nimus-roles/roles'][0] ?? 'user';
    });
  }

  getConnectedDevices() {
    return this.userService.userControllerGetDevicesCount();
  }

  getUserNotifications() {
    return this.userService.userControllerGetNotification();
  }

  setUserNotifications(body: UserNotificationRequestDto) {
    this.userService.userControllerSetNotification(body).subscribe();
  }

  currentUserSub() {
    return lastValueFrom(this.authService.user$.pipe(take(1)));
  }

  sessionLogout() {
    return this.authService.logout({
      logoutParams: {
        returnTo: document.location.origin,
      },
    });
  }

  getAccessToken() {
    return lastValueFrom(this.authService.getAccessTokenSilently());
  }

  changeUserLanguage(lang: string) {
    return this.userService.userControllerUpdateUserLanguage(lang);
  }

  private getTokenClaims() {
    return lastValueFrom(this.authService.idTokenClaims$.pipe(take(1)));
  }

  async changePassword() {
    const tokenClaims = await this.getTokenClaims();
    const clientId = tokenClaims?.aud;
    const issuer = tokenClaims?.iss;
    const user = await this.currentUserSub();

    const url = `${issuer}dbconnections/change_password`;
    const body = {
      client_id: clientId,
      email: user?.email,
      connection: 'Username-Password-Authentication',
      // password: newPassword,
    };

    return lastValueFrom(this.http.post(url, body));
  }
}
