import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { ModalController } from '@ionic/angular';
import { Components } from '@ionic/core';
import { ActivatedRoute, Router } from '@angular/router';
import IonAccordionGroup = Components.IonAccordionGroup;

@Component({
  selector: 'app-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.scss'],
})
export class ListComponent implements OnInit, OnChanges {
  @Input() listData: any = [];

  @Input() entity: string = '';

  @Input() listHeaders: {
    name: string;
    value: string;
  }[] = [];

  @Input() actions: string[] = [];

  @Output() editEvent = new EventEmitter<any>();

  headerValues: string[] = [];

  headerNames: string[] = [];

  sorting: {
    value: string;
    name?: string;
    direction: string;
  } = {
    value: '',
    name: '',
    direction: '',
  };

  @ViewChild('accordionGroup', { static: true }) accordionGroup!: IonAccordionGroup;

  constructor(
    private modalCtrl: ModalController,
    private router: Router,
    private route: ActivatedRoute,
  ) {}

  ngOnInit() {
    this.headerValues = this.listHeaders.map((item) => item.value);
    this.headerNames = this.listHeaders.map((item) => item.name);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['listData']) {
      //
      //Add logic
      //
    }
  }

  sortData(sort: any) {
    const data: any[] = this.listData.slice();
    if (sort.detail.value) {
      const sortType = sort.detail.value;
      this.sorting.value = sortType.substring(0, sortType.indexOf(','));
      this.sorting.direction = sortType.substring(sortType.indexOf(',') + 1, sortType.length + 1);
      this.sorting.name = this.listHeaders.find(
        (item: any) => item.value === this.sorting.value,
      )?.name;
      this.closeSorting();
    } else {
      this.closeSorting();
      this.sorting.value = '';
      this.listData = data;
      return;
    }
    this.listData.sort((a: any, b: any) => {
      const isAsc = this.sorting.direction === 'asc';
      return this.compare(a[this.sorting.value], b[this.sorting.value], isAsc);
    });
  }

  compare(a: number | string, b: number | string, isAsc: boolean) {
    return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
  }

  emitEdit(item: { data: any; entity: string }) {
    this.editEvent.emit(item);
  }

  closeSorting = () => {
    const nativeEl = this.accordionGroup;
    if (nativeEl.value === 'second') {
      nativeEl.value = undefined;
    } else {
      nativeEl.value = 'second';
    }
  };

  goToEditPage = (name: string) => {
    this.router.navigate([name, 'edit'], { relativeTo: this.route }).then();
  };

  protected readonly console = console;
}
