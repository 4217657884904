import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {
  PlantProfilesService as ApiPlantProfilesService,
  SensorsSearchRequestDto,
  SensorsSearchResponseDto,
  SensorsService as ApiSensorsService,
  SensorUpdateDto,
  SuccessDto,
} from '../../../api';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { ToastController } from '@ionic/angular';

@Injectable({
  providedIn: 'root',
})
export class SensorsService {
  sensorsService: ApiSensorsService;

  //TODO: Delete later, after Filip merge
  plantsService: ApiPlantProfilesService;

  constructor(private readonly http: HttpClient, private readonly toastCtrl: ToastController) {
    this.sensorsService = new ApiSensorsService(
      this.http,
      environment.apiURL,
      // @ts-ignore
      undefined,
    );
    this.plantsService = new ApiPlantProfilesService(
      this.http,
      environment.apiURL,
      // @ts-ignore
      undefined,
    );
  }

  getSensorById(id: number): Observable<any> {
    return this.sensorsService.sensorsControllerGet(id);
  }

  getSensors(params: SensorsSearchRequestDto): Observable<SensorsSearchResponseDto> {
    return this.sensorsService.sensorsControllerSearch(params);
  }

  //TODO: update the endpoints

  // addSensor(sensor: SensorItemDto): Observable<SuccessDto> {
  //   return this.$sensorsService.sensorsControllerSave(sensor);
  // }

  updateSensor(sensor: SensorUpdateDto): Observable<SuccessDto> {
    return this.sensorsService.sensorsControllerUpdate(sensor);
  }

  //TODO: update the endpoints

  deleteSensor(id: number): Observable<SuccessDto> {
    return this.sensorsService.sensorsControllerDelete(id);
  }

  getSensorDataById(filter: SensorsSearchRequestDto, sensorId: number): Observable<any> {
    return this.sensorsService.sensorsControllerDataSearch(filter, sensorId);
  }

  getPlantProfiles(filter: any): Observable<any> {
    return this.plantsService.plantsControllerSearchGlobal(filter);
  }

  getGlobalPlantProfileById(id: number): Observable<any> {
    return this.plantsService.plantsControllerGetGlobal(id);
  }

  getUserPlantProfileById(id: number): Observable<any> {
    return this.plantsService.plantsControllerGetUser(id);
  }

  getPredictionData(id: number) {
    return this.sensorsService.sensorsControllerGetPredictionData(id);
  }

  cleanupSensors() {
    return this.sensorsService.sensorsControllerCleanupSensor();
  }

  async showSuccessAlert(message: string) {
    const toast = await this.toastCtrl.create({
      message: `${message}`,
      duration: 1500,
      position: 'top',
      cssClass: 'success-alert',
      icon: 'checkmark-outline',
    });

    await toast.present();
  }
}
