<ion-tabs>
  <ion-tab-bar slot="bottom">
    <ion-tab-button tab="sensors" class="bottom-btn">
      <ion-icon name="wifi-outline"></ion-icon>
      {{ 'BAR.SENSORS' | translate }}
    </ion-tab-button>
    <ion-tab-button tab="gateways" class="bottom-btn">
      <ion-icon name="layers-outline"></ion-icon>
      {{ 'BAR.GATEWAYS' | translate }}
    </ion-tab-button>
    <ion-tab-button tab="plant-profiles" class="bottom-btn">
      <ion-icon name="sunny-outline"></ion-icon>
      {{ 'BAR.PROFILES' | translate }}
    </ion-tab-button>
    <ion-tab-button tab="profile" class="bottom-btn">
      <ion-icon name="person-circle-outline"></ion-icon>
      {{ 'BAR.USER' | translate }}
    </ion-tab-button>
  </ion-tab-bar>
</ion-tabs>
