/**
 * NIMUS CLOUD API Backend
 * NIMUS Cloud Backend Documentation
 *
 * OpenAPI spec version: 1.0.0
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface GatewayItemDto {
  /**
   * Identification of gateway
   */
  id: number;
  /**
   * Serial number. Can be null
   */
  serial_number?: string;
  /**
   * Name of gateway
   */
  name: string;
  /**
   * Gateway type
   */
  type?: string;
  /**
   * Latitude from GPS
   */
  latitude?: string;
  /**
   * Longitude from GPS
   */
  longitude?: string;
  /**
   * How accurate localisation is
   */
  accuracy?: string;
  /**
   * When gateway was connected lastly. Can be null.
   */
  last_connected?: string;
  /**
   * Generated code
   */
  gw_code: string;
  /**
   * Gateway status
   */
  status?: string;
  /**
   * Expiration time of newly generated code
   */
  code_expired_in: string;
}
