import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SensorsPageComponent } from './sensors-page/sensors-page.component';
import { IonicModule } from '@ionic/angular';
import { SensorsRoutingModule } from './sensors-routing.module';
import { SharedModule } from '../../shared/shared.module';
import { NgxEchartsModule } from 'ngx-echarts';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SensorAddComponent } from './sensor-add/sensor-add.component';
import { HistoryComponent } from './history/history.component';
import { LeaveFeedbackComponent } from './leave-feedback/leave-feedback.component';
import { SensorEditComponent } from './sensor-edit/sensor-edit.component';
import { GraphsComponent } from './history/graphs/graphs.component';
import { DataComponent } from './history/data/data.component';
import { FeedbackComponent } from './history/feedback/feedback.component';
import { SensorsTableComponent } from './sensors-table/sensors-table.component';
import { PlantProfileSelectComponent } from './sensor-add/plant-profile-select/plant-profile-select.component';
import { FeedbackImgModalComponent } from './history/feedback/feedback-img-modal/feedback-img-modal.component';
import { FeedbackItemComponent } from './history/feedback/feedback-item/feedback-item.component';
import { TranslateModule } from '@ngx-translate/core';
import { MomentModule } from 'ngx-moment';
import { WebcamModule } from 'ngx-webcam';
import { LazyLoadImageModule } from 'ng-lazyload-image';

@NgModule({
  declarations: [
    SensorsPageComponent,
    SensorAddComponent,
    HistoryComponent,
    LeaveFeedbackComponent,
    FeedbackItemComponent,
    FeedbackImgModalComponent,
    SensorEditComponent,
    GraphsComponent,
    DataComponent,
    FeedbackComponent,
    SensorsTableComponent,
    PlantProfileSelectComponent,
  ],
  imports: [
    CommonModule,
    IonicModule,
    SensorsRoutingModule,
    SharedModule,
    TranslateModule,
    NgxEchartsModule.forRoot({
      echarts: () => import('echarts'),
    }),
    FormsModule,
    ReactiveFormsModule,
    MomentModule,
    WebcamModule,
    LazyLoadImageModule,
  ],
})
export class SensorsModule {}
