export * from './feedbacks.service';
import { FeedbacksService } from './feedbacks.service';
export * from './gateways.service';
import { GatewaysService } from './gateways.service';
export * from './main.service';
import { MainService } from './main.service';
export * from './plantProfiles.service';
import { PlantProfilesService } from './plantProfiles.service';
export * from './sensors.service';
import { SensorsService } from './sensors.service';
export * from './user.service';
import { UserService } from './user.service';
export const APIS = [
  FeedbacksService,
  GatewaysService,
  MainService,
  PlantProfilesService,
  SensorsService,
  UserService,
];
