/**
 * NIMUS CLOUD API Backend
 * NIMUS Cloud Backend Documentation
 *
 * OpenAPI spec version: 1.0.0
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { PlantProfileDetails } from './plantProfileDetails';

export interface FeedbackUnloggedItemDto {
  /**
   * Identification of feedback
   */
  id?: number;
  /**
   * Hash identification of feedback
   */
  prepared_hash?: string;
  /**
   * Name of sensor
   */
  sensor_name?: string;
  /**
   * Reference to sensor
   */
  sensor_id?: number;
  /**
   * Height of the plant
   */
  height: number;
  /**
   * Plant profile user details
   */
  plant_profile_user?: Array<PlantProfileDetails>;
  /**
   * Plant profile details
   */
  plant_profile?: Array<PlantProfileDetails>;
}
