import { HttpClient } from '@angular/common/http';
import { FilesService as ApiFilesService } from './../../../file-api/api/files.service';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class FilesService {
  localImage: any = undefined;

  constructor(private readonly fileService: ApiFilesService, private readonly http: HttpClient) {
    this.fileService = new ApiFilesService(
      this.http,
      environment.fileApiURL,
      /* @ts-ignore */
      undefined,
    );
  }

  getImage(id: number): Observable<Blob> {
    return this.fileService.filesControllerGetFile(id);
  }

  getThumbnail(id: number): Observable<Blob> {
    return this.fileService.filesControllerThumbnail(id);
  }

  postFile(formData: FormData): Observable<any> {
    return this.fileService.filesControllerUploadFileForm(
      formData.get('source') as string,
      formData.get('file') as Blob,
    );
  }

  saveFeedbackFile(formData: FormData): Observable<any> {
    return this.fileService.filesControllerUploadFeedbackFileForm(
      formData.get('hash') as string,
      formData.get('file') as Blob,
    );
  }

  deleteImage(id: number): Observable<any> {
    return this.fileService.filesControllerDeleteFile(id);
  }
}
