/**
 * NIMUS CLOUD API Backend
 * NIMUS Cloud Backend Documentation
 *
 * OpenAPI spec version: 1.0.0
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { LangItemDto } from './langItemDto';

export interface PlantSaveRequestDto {
  /**
   * Plant profile user id - (in global request save or update must be NULL, on user save new must be 0, for user update higher than 0)
   */
  id_user: number;
  /**
   * Plant profile global id - (required in global save request with value 0 for new or value higher than 0 for update exist, not necessary in user save or update, if not set we create completely new profile defined by user)
   */
  id_global?: number;
  /**
   * Minimal temperature
   */
  temp_min: number;
  /**
   * Maximal temperature
   */
  temp_max: number;
  /**
   * Optimal temperature
   */
  temp_opt?: number;
  /**
   * Minimal humidity
   */
  hum_min: number;
  /**
   * Maximal humidity
   */
  hum_max: number;
  /**
   * Optimal humidity
   */
  hum_opt?: number;
  /**
   * Reference id to table which contains photos (Optional). Only for new user plant.
   */
  file_id?: number;
  /**
   * Feedback interval
   */
  interval: string;
  /**
   * Name translation
   */
  name_lang: Array<LangItemDto>;
  /**
   * Info translation
   */
  info_lang: Array<LangItemDto>;
}
