/**
 * NIMUS CLOUD API Backend
 * NIMUS Cloud Backend Documentation
 *
 * OpenAPI spec version: 1.0.0
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface FeedbackSearchItemDto {
  /**
   * Field name to be search on
   */
  field: string;
  /**
   * Operator for field value to be search on
   */
  operator: FeedbackSearchItemDto.OperatorEnum;
  /**
   * Search value
   */
  value: string;
}
export namespace FeedbackSearchItemDto {
  export type OperatorEnum = '=' | '<' | '>';
  export const OperatorEnum = {
    Equal: '=' as OperatorEnum,
    LessThan: '<' as OperatorEnum,
    GreaterThan: '>' as OperatorEnum,
  };
}
