import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { PlantProfilesComponent } from './plant-profiles/plant-profiles.component';
import { ProfileEditComponent } from './profile-edit/profile-edit.component';
import { ProfileAddComponent } from './profile-add/profile-add.component';

const routes: Routes = [
  {
    path: '',
    component: PlantProfilesComponent,
  },
  {
    path: ':id/edit',
    component: ProfileEditComponent,
  },
  {
    path: 'add',
    component: ProfileAddComponent,
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class PlantProfilesRoutingModule {}
