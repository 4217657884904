import { Component, OnInit } from '@angular/core';
import { LangService } from '../../services/lang.service';
import { UserService } from '../../services/user.service';
import { ActivatedRoute, Router } from '@angular/router';
import { PlantSearchRequestDto } from '../../../api';
import LangEnum = PlantSearchRequestDto.LangEnum;
import { PolicyService } from './policy.service';
import { ToastController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { User } from '@auth0/auth0-angular';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-policy',
  templateUrl: './policy.component.html',
  styleUrls: ['./policy.component.scss'],
})
export class PolicyComponent implements OnInit {
  // storage = new Storage();
  langList: LangEnum[] = [];

  filesList: any[] = [];

  filesLoaded: boolean = false;

  thereAreFiles: boolean = false;

  userInfo: any;

  constructor(
    protected readonly langService: LangService,
    private readonly userService: UserService,
    private readonly policyService: PolicyService,
    private router: Router,
    private route: ActivatedRoute,
    private toastCtrl: ToastController,
    public translate: TranslateService,
    private http: HttpClient,
  ) {}

  ngOnInit(): void {
    this.langList = this.langService.supportedLanguages;
    this.getFilesList();
    // this.userInfo = this.userService.currentUserSub();
    // console.log('user sub: ', this.userInfo.__zone_symbol__value, this.userInfo);
    this.userService.currentUserSub().then((user: User | null | undefined) => {
      this.userInfo = user;
    });
  }

  getFilesList() {
    this.policyService.getFilesList().subscribe({
      next: (res) => {
        this.filesList = res.data;
        this.filesLoaded = true;
        if (this.filesList.length > 0) {
          this.thereAreFiles = true;
        }
      },
      error: (err) => {
        this.displayToast(this.translate.instant('POLICY.ERROR')).then();
        this.filesLoaded = true;
      },
    });
  }

  login() {
    this.router.navigate(['auth']).then();
  }

  logout() {
    this.userService.sessionLogout();
  }

  changeLanguage(lang: LangEnum) {
    this.langService.language = lang;
  }

  downloadFile(id: string) {
    let host = '';
    switch (window.location.hostname.toLowerCase()) {
      case 'localhost': // For local testing - try to use dev panel
        host = 'dev.panel.nimus.pl';
        break;
      default:
        host = window.location.hostname;
    }
    const fileUrl = `https://${host}/file-api/policy/get/${id}`;
    // console.log(fileUrl);

    this.http.get(fileUrl, { responseType: 'blob' }).subscribe((response: Blob) => {
      const blob = new Blob([response], { type: 'application/pdf' });
      const blobUrl = window.URL.createObjectURL(blob);
      const anchor = document.createElement('a');
      anchor.href = blobUrl;
      anchor.download = `nimus_${id}.pdf`;

      anchor.click();
      window.URL.revokeObjectURL(blobUrl);
    });
  }

  async displayToast(text: string) {
    const toast = await this.toastCtrl.create({
      message: text,
      duration: 3000,
      position: 'top',
    });
    await toast.present();
  }
}
