import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { PlantSearchItemResponseDto } from '../../../../api';
import { FilesService } from '../files.service';
import { PlantProfileService } from '../plant-profiles.service';
import { AlertService } from '../../../services/alert.service';

@Component({
  selector: 'app-plant-item',
  templateUrl: './plant-item.component.html',
  styleUrls: ['./plant-item.component.scss'],
})
export class PlantItemComponent implements OnInit {
  // @ts-ignore
  @Input() item: PlantSearchItemResponseDto;

  blob: any;

  defaultImage: string = '/assets/svg/loading.svg';

  constructor(
    private readonly profilesService: PlantProfileService,
    private readonly route: ActivatedRoute,
    private readonly router: Router,
    private readonly fileService: FilesService,
    private alertService: AlertService,
  ) {}

  ngOnInit() {
    if (this.item.file_id) {
      this.profilesService.restorePlantImage(this.item.file_id).subscribe({
        next: (res) => {
          if (res) {
            this.createImageFromBlob(res.img);
          } else {
            this.getThumbnail();
          }
        },
        error: (err) => {
          this.alertService.showFailureAlert('', 'Error while restoring plant image').then();
        },
      });
      return;
    }
    this.defaultImage = '/assets/svg/default.svg';
  }

  goToEditPage = (id_g: any, id_u: any) => {
    let id: string = this.profilesService.createUrlId(id_g, id_u);
    this.router.navigate([id, 'edit'], { relativeTo: this.route }).then();
  };

  getThumbnail(): void {
    if (!this.item?.file_id) {
      this.blob = '/assets/svg/default.svg';
      return;
    }

    const fileId = this.item.file_id;
    this.fileService.getThumbnail(fileId).subscribe({
      next: (val) => {
        this.profilesService.savePlantImage(fileId, val).subscribe();
        this.createImageFromBlob(val);
      },
      error: (response) => {
        this.blob = '/assets/svg/default.svg';
        this.alertService.showFailureAlert('', 'Error while fetching thumbnails').then();
        console.error('Loading thumbnails failed', response.message);
      },
    });
  }

  createImageFromBlob(img: Blob) {
    const reader = new FileReader();
    reader.addEventListener(
      'load',
      () => {
        this.blob = reader.result;
      },
      false,
    );
    if (img) {
      reader.readAsDataURL(img);
    }
  }
}
