<ion-grid class="ion-margin-bottom">
  <ion-row>
    <ion-col> </ion-col>
  </ion-row>
  <ion-row>
    <ion-col size="12">
      <ion-accordion-group #accordionGroup>
        <ion-accordion value="sorting" class="border-radius-10">
          <ion-item slot="header" color="light">
            <ion-label>
              {{ 'Sorting' }}{{ sorting.value ? ': ' + (sorting.name | lowercase) : '' }}
              <ng-container *ngIf="sorting.value">
                <ion-icon
                  *ngIf="sorting.direction === 'asc'"
                  name="arrow-up-outline"
                  class="accordion-item__icon"
                ></ion-icon>
                <ion-icon
                  *ngIf="sorting.direction === 'desc'"
                  name="arrow-down-outline"
                  class="accordion-item__icon"
                ></ion-icon>
              </ng-container>
            </ion-label>
          </ion-item>
          <ion-radio-group
            [allowEmptySelection]="true"
            value="sortTypes"
            slot="content"
            (ionChange)="sortData($event)"
          >
            <ng-container *ngFor="let header of listHeaders">
              <ion-item *ngIf="header.name !== 'actions'">
                <ion-radio class="ion-align-items-center" [value]="header.value + ',asc'"
                  >{{ header.name }}
                  <ion-icon
                    name="arrow-up-outline"
                    class="accordion-item__icon ion-padding-end"
                  ></ion-icon>
                </ion-radio>
              </ion-item>
              <ion-item *ngIf="header.name !== 'actions'">
                <ion-radio class="ion-align-items-center" [value]="header.value + ',desc'"
                  >{{ header.name }}
                  <ion-icon
                    name="arrow-down-outline"
                    class="accordion-item__icon ion-padding-end"
                  ></ion-icon>
                </ion-radio>
              </ion-item>
            </ng-container>
          </ion-radio-group>
        </ion-accordion>
      </ion-accordion-group>
    </ion-col>
  </ion-row>
  <ion-row>
    <ion-col size="12">
      <ng-container *ngIf="entity === 'sensorsHistory'">
        <ion-list>
          <ion-item lines="none">
            <ion-label slot="start" color="medium">Date, Time</ion-label>
            <ion-label slot="end" color="medium">Temp, Hum</ion-label>
          </ion-item>
          <ion-item *ngFor="let item of listData">
            <ion-label>{{ item.date + ', ' + item.time }}</ion-label>
            <ion-label slot="end"> {{ item.temp + '°C, ' + item.hum + '%' }} </ion-label>
          </ion-item>
        </ion-list>
      </ng-container>
      <ng-container *ngIf="entity === 'feedbackHistory'">
        <ion-list>
          <ion-item lines="none">
            <ion-label slot="start" color="medium">Date, Time</ion-label>
          </ion-item>
          <ion-item *ngFor="let item of listData">
            <ion-label>{{ item.date + ', ' + item.time }}</ion-label>
          </ion-item>
        </ion-list>
      </ng-container>
      <ng-container *ngIf="entity === 'plantProfiles'">
        <ion-list>
          <ion-item
            *ngFor="let item of listData"
            button="true"
            (click)="goToEditPage(item.profile)"
          >
            <ion-thumbnail slot="start">
              <img
                alt="plant_image"
                src="https://ionicframework.com/docs/img/demos/thumbnail.svg"
              />
            </ion-thumbnail>
            <ion-label>{{ item.profile }}</ion-label>
          </ion-item>
        </ion-list>
      </ng-container>
    </ion-col>
  </ion-row>
</ion-grid>
