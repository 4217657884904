<ion-header>
  <ion-toolbar>
    <ion-title class="ion-text-start">{{ 'PP.ADD.SELECT.TITLE' | translate }}</ion-title>
    <ion-buttons slot="end" (click)="cancel()" class="ion-padding-end">
      <ion-icon name="close-outline" size="large" color="nimus_grey"></ion-icon>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content color="light" class="ion-padding">
  <ion-item lines="none" class="border-radius-10">
    <ion-button routerLink="/plant-profiles/add" (click)="cancel()" class="ion-margin-vertical" size="small" fill="outline" color="primary">{{
      'PP.CREATE_PROFILE' | translate
    }}</ion-button>
    <ion-label style="font-size: 14px" class="ion-text-center">{{
      'PP.OR_CHOOSE_IT_BELOW' | translate
    }}</ion-label>
  </ion-item>
  <ion-searchbar
    debounce="500"
    class="ion-no-padding ion-margin-vertical"
    (ionInput)="applySearchFilter($event)"
    (input)="showLoading()"
    [placeholder]="translate.instant('COMMON.SEARCH')"
  ></ion-searchbar>
  <ion-radio-group>
    <ion-item
      lines="none"
      class="ion-margin-bottom padding-bottom border-radius-10"
      *ngIf="previouslySelectedItem"
    >
      <ion-label>
        <p>{{ 'PP.PREVIOUSLY_SELECTED_PROFILE' | translate }}</p>
        <span>{{ previouslySelectedItemName }}</span>
      </ion-label>
    </ion-item>
  </ion-radio-group>
  <ng-container *ngIf="!isDataFetched">
    <ion-item *ngFor="let item of [1, 2, 3, 4, 5, 6, 7, 8]">
      <ion-label>
        <p>
          <ion-skeleton-text [animated]="true"></ion-skeleton-text>
        </p>
      </ion-label>
    </ion-item>
  </ng-container>
  <ion-radio-group
    *ngIf="filteredItems.length && isDataFetched"
    [allowEmptySelection]="true"
    (ionChange)="onSelect($event)"
  >
    <ng-container *ngFor="let item of filteredItems">
      <ion-item
        lines="none"
        *ngIf="
          !(
            item.id_user === previouslySelectedItem?.id_user &&
            item.id_global === previouslySelectedItem?.id_global
          )
        "
      >
        <ion-label *ngIf="item.name"> {{ item.name }} </ion-label>
        <ion-label color="nimus_red" *ngIf="!item.name">No translation available</ion-label>
        <ion-radio
          legacy="true"
          slot="end"
          [value]="{ id_global: item.id_global, id_user: item.id_user }"
        ></ion-radio>
      </ion-item>
    </ng-container>
  </ion-radio-group>
  <ion-row
    *ngIf="!filteredItems.length && isDataFetched"
    class="ion-justify-content-center animate__animated animate__fadeIn animate__fast"
  >
    <ion-col size="auto">
      <ion-text> No data found</ion-text>
    </ion-col>
  </ion-row>
</ion-content>
<ion-footer class="ion-padding-vertical">
  <ion-grid>
    <ion-row class="ion-justify-content-center">
      <ion-col size="auto">
        <ion-button
          class="ion-align-self-center"
          fill="outline"
          color="nimus_red"
          size="medium"
          (click)="cancel()"
          >{{ 'COMMON.CANCEL' | translate }}
        </ion-button>
        <ion-button
          class="ion-align-self-center ion-margin-start"
          fill="solid"
          color="primary"
          size="medium"
          (click)="save()"
          [disabled]="!selectedItemId"
          >{{ 'COMMON.SELECT' | translate }}
        </ion-button>
      </ion-col>
    </ion-row>
  </ion-grid>
</ion-footer>
