import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { MainService as ApiMainService, VersionDto } from '../../api';
import { environment } from '../../environments/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class MainService {
  constructor(private readonly http: HttpClient, private mainService: ApiMainService) {
    this.mainService = new ApiMainService(
      http,
      environment.apiURL,
      // @ts-ignore
      undefined,
    );
  }

  public version(): Observable<VersionDto> {
    return this.mainService.mainControllerVersion();
  }
}
