<ion-searchbar
  class="ion-margin-bottom"
  (keyup)="applySearchFilter($event)"
  [placeholder]="translate.instant('COMMON.SEARCH')"
></ion-searchbar>

<app-sort [tableHeaders]="tableHeaders" (sortEvent)="sortData($event)"></app-sort>

<ion-list class="items-list" *ngFor="let item of tableData">
  <ion-item *ngFor="let row of tableHeaders" [lines]="row.value === 'actions' ? 'none' : 'full'">
    <ion-note slot="start" *ngIf="row.value !== 'actions'" class="item__note ion-text-start">{{
      row.name
    }}</ion-note>
    <ion-label *ngIf="row.value === 'timestamp'" class="ion-text-end">{{
      item[row.value]
    }}</ion-label>
    <ion-label *ngIf="row.value === 'profile'" class="ion-text-end">{{
      item[row.value]
    }}</ion-label>
    <ion-label *ngIf="row.value === 'name'" class="ion-text-end">{{ item[row.value] }}</ion-label>
    <ion-buttons *ngIf="row.value === 'actions'" class="item__actions">
      <ion-button fill="clear" color="primary" (click)="openEdit(item)">
        <ion-icon slot="icon-only" name="pencil-outline" color="primary"></ion-icon>
      </ion-button>
      <ion-button fill="clear" color="nimus_red" (click)="showDeleteAlert(item)">
        <ion-icon slot="icon-only" name="trash-outline" color="nimus_red"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-item>
</ion-list>
