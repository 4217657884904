import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { LogoutModalComponent } from '../logout-modal/logout-modal.component';

@Component({
  selector: 'app-logout-button',
  templateUrl: './logout-button.component.html',
  styleUrls: ['./logout-button.component.scss'],
})
export class LogoutButtonComponent implements OnInit {
  constructor(private modalController: ModalController) {}

  ngOnInit() {}

  async openLogoutModal() {
    const modal = await this.modalController.create({
      component: LogoutModalComponent,
      cssClass: 'logout-modal',
      breakpoints: [0, 0.2, 1],
      initialBreakpoint: 0.2,
    });
    await modal.present();
  }

  logout() {
    this.openLogoutModal().then();
  }
}
