<app-header></app-header>
<ion-content color="nimus-md3-green">
  <ion-card class="page-card border-radius-25">
    <ion-card-header class="animate__animated animate__fadeIn animate__faster">
      <ion-card-title class="ion-text-center ion-margin-bottom">
        {{ 'SENSOR.EDIT_SENSOR' | translate }} {{sensorId}}
      </ion-card-title>
    </ion-card-header>
    <ion-card-content class="animate__animated animate__fadeIn animate__faster">
      <ion-grid>
        <ng-container *ngIf="isDataFetched">
          <form [formGroup]="sensorForm">
            <ion-item class="ion-margin-bottom">
              <ion-input
                label="{{ 'SENSOR.NAME' | translate }}"
                label-placement="stacked"
                type="text"
                formControlName="name"
              ></ion-input>
            </ion-item>
          </form>

          <ion-row class="inline mt-5">
            <ion-col>
              <ion-list>
                <ion-item [button]="true" [detail]="false" (click)="openPlantProfilesList($event)">
                  <ion-label>{{ 'SENSOR.PROFILE' | translate }}</ion-label>
                  <div slot="end" [class.opacity-6]="!this.selectedPlantProfileName">
                    {{
                      this.selectedPlantProfileName
                        ? this.selectedPlantProfileName
                        : ('SENSOR.SELECT_PROFILE' | translate)
                    }}
                    <ion-icon role="img" src="/assets/svg/arrow.svg" class="mr-2"></ion-icon>
                  </div>
                </ion-item>
              </ion-list>
            </ion-col>
            <ion-col>
              <ion-list>
                <ion-item>
                  <ion-select
                    interface="popover"
                    justify="space-between"
                    label="{{ 'SENSOR.INTERVAL' | translate }}"
                    placeholder="{{ 'SENSOR.SELECT_INTERVAL' | translate }}"
                    [value]="sensorForm.get('interval')?.value"
                    (ionChange)="selectInterval($event)">
                    <ion-select-option
                      *ngFor="let option of intervalOptions"
                      [value]="option.value"
                      >{{ translate.instant(option.name) }}</ion-select-option>
                  </ion-select>
                </ion-item>
              </ion-list>
            </ion-col>
          </ion-row>

          <ion-row class="inline mt-5">
            <ion-col>
              <ion-item>
                <ion-icon slot="start" name="water-outline" color="primary" size="large"></ion-icon>
                <div slot="end" [class.opacity-6]="!this.selectedPlantProfileName">
                  {{ 'SENSOR.HISTORY.FROM' | translate }} {{this.selectedPlantProfile.hum_min}}% &nbsp; {{ 'SENSOR.HISTORY.TO' | translate }} {{this.selectedPlantProfile.hum_max}}%
                </div>
              </ion-item>
            </ion-col>
            <ion-col>
              <ion-list>
                <ion-item>
                  <ion-icon slot="start" name="thermometer-outline" color="danger" size="large"></ion-icon>
                  <div slot="end" [class.opacity-6]="!this.selectedPlantProfileName">
                    {{ 'SENSOR.HISTORY.FROM' | translate }} {{this.selectedPlantProfile.temp_min}}°C &nbsp; {{ 'SENSOR.HISTORY.TO' | translate }} {{this.selectedPlantProfile.temp_max}}°C
                  </div>
                </ion-item>
              </ion-list>
            </ion-col>
          </ion-row>
        </ng-container>
        <ng-container *ngIf="!isDataFetched">
          <ion-item *ngFor="let item of [1, 2, 3]" lines="none">
            <ion-label>
              <p style="width: 30%">
                <ion-skeleton-text [animated]="true"></ion-skeleton-text>
              </p>
              <p style="width: 100%">
                <ion-skeleton-text [animated]="true"></ion-skeleton-text>
              </p>
            </ion-label>
          </ion-item>
        </ng-container>
      </ion-grid>
    </ion-card-content>
    <ion-grid>
      <ion-row class="ion-justify-content-center">
        <ion-col size="auto">
          <ion-button
            class="ion-align-self-center"
            fill="outline"
            color="nimus_red"
            size="medium"
            routerLink="/sensors"
            (click)="cancel()"
            >{{ 'COMMON.CANCEL' | translate }}
          </ion-button>
          <ion-button
          *ngIf="!!sensorForm.get('plant_profile_user_id')?.value"
          class="ion-align-self-center ion-margin-start"
          fill="solid"
          color="priary"
          (click)="editProfile()">
            {{'COMMON.EDIT_PROFILE' | translate}}
          </ion-button>
          <ion-button
            class="ion-align-self-center ion-margin-start"
            fill="solid"
            color="primary"
            size="medium"
            (click)="save()"
            [disabled]="sensorForm.invalid || !selectedPlantProfile"
            >{{ 'COMMON.SAVE' | translate }}
          </ion-button>
        </ion-col>
      </ion-row>
    </ion-grid>
  </ion-card>
</ion-content>
