<ion-row>
  <ion-col size="12">
    <ion-searchbar
      (ionInput)="applySearchFilter($event)"
      debounce="500"
      (input)="showLoading()"
      [placeholder]="translate.instant('COMMON.SEARCH')"
    ></ion-searchbar>
  </ion-col>
  <ion-col size="11">
    <app-sort [tableHeaders]="tableHeaders" (sortEvent)="sortData($event)" [sorting]="startSort"></app-sort>
  </ion-col>
  <ion-col size="1" class="refresh-button-col">
    <ion-button id="refreshButton" fill="clear" color="primary" (click)="refreshData()" class="refresh-button">
      <ion-icon slot="icon-only" name="refresh-outline" color="primary" size="large"></ion-icon>
    </ion-button>
  </ion-col>
</ion-row>

<ion-row
  *ngIf="!tableData.length && isDataFetched"
  class="ion-justify-content-center animate__animated animate__fadeIn animate__fast">
  <ion-col size="auto">
    <ion-item lines="none">{{ 'NO_DATA' | translate }}</ion-item>
  </ion-col>
</ion-row>

<ng-container *ngIf="isDataFetched">
  <ion-accordion-group #accordionGroup class="items-list" >

    <ng-container *ngFor="let item of tableData; let i = index" class="item-container">

      <div class="centered-header">
        <h2 [ngClass]="moment().diff(item.last_connected, 'hours') >= 24 ? 'sensor-not-active' : 'sensor-active'">{{ item.name }}</h2>
      </div>

    <ion-accordion #itemAccordion [id]="'accordion' + i" [value]="i" toggle-icon-slot="start">
      <ion-item slot="header" class="ion-no-padding no-inner-padding">
<!--        <ion-label> {{ 'SENSOR.ACCORDION_LABEL' | translate }} </ion-label>-->
        <ion-buttons slot="end" class="ion-no-padding">

          <ion-button *ngIf="item.ai" class="ai-button">
            <ion-icon
              class="ai-button"
              [src]="aiIcon"
              slot="icon-only"
              color="medium"
              size="small"
            ></ion-icon>
          </ion-button>

          <ion-button class="ai-button" *ngIf="item.temp !== null || item.hum>= 0">
            <ion-icon
              *ngIf="item.temp !== null"
              name="thermometer-outline"
              color="danger"
              size="small"
            ></ion-icon>
            <span *ngIf="item.temp !== null">{{item.temp}}°C</span>
            <span *ngIf="item.temp !== null && item.hum!== null">,&nbsp;</span>
            <ion-icon
              *ngIf="item.hum !== null"
              name="water-outline"
              color="primary"
              size="small"
            ></ion-icon>
            <span *ngIf="item.hum !== null"> {{item.hum}}%&nbsp;&nbsp;</span>
          </ion-button>

          <ion-button *ngIf="item.battery_level == null">
            <ion-icon
              name="battery-dead-outline"
              slot="icon-only"
              color="medium"
              size="small"
            ></ion-icon>
          </ion-button>

          <ion-button *ngIf="item.battery_level != null && item.battery_level <= 20">
            <ion-icon
              name="battery-dead-outline"
              slot="icon-only"
              color="danger"
              size="small"
            ></ion-icon>
          </ion-button>

          <ion-button
            *ngIf="
              item.battery_level != null && item.battery_level > 20 && item.battery_level < 70">
            <ion-icon
              name="battery-half-outline"
              slot="icon-only"
              color="warning"
              size="small"
            ></ion-icon>
          </ion-button>

          <ion-button *ngIf="item.battery_level != null && item.battery_level >= 70">
            <ion-icon
              name="battery-full-outline"
              slot="icon-only"
              color="secondary"
              size="small"
            ></ion-icon>
          </ion-button>

          <ion-button
            fill="clear"
            color="primary"
            (click)="openEdit(item); $event.stopPropagation()">
            <ion-icon
              slot="icon-only"
              name="pencil-outline"
              color="primary"
              size="small"
            ></ion-icon>
          </ion-button>

          <ion-button
            fill="clear"
            color="nimus_red"
            (click)="showDeleteModal(item); $event.stopPropagation()">
            <ion-icon
              slot="icon-only"
              name="trash-outline"
              color="nimus_red"
              size="small"
            ></ion-icon>
          </ion-button>
        </ion-buttons>
      </ion-item>
      <ion-list slot="content">
        <ion-item *ngFor="let row of tableHeaders" [lines]="'full'">
          <ion-note
            slot="start"
            *ngIf="row.value !== 'name' && row.value !== 'actions'"
            class="items__note ion-text-start"
          >{{ row.name | translate }}</ion-note>
          <ion-label *ngIf="row.value === 'temp' && item[row.value]" class="ion-text-end">{{
            item[row.value] + '°C'
            }}</ion-label>
          <ng-container *ngIf="row.value === 'temp' && !item[row.value]">
            <ion-label class="ion-text-end" color="medium">{{
              'SENSOR.NO_DATA' | translate
              }}</ion-label>
          </ng-container>

          <ion-label *ngIf="row.value === 'hum' && item[row.value]" class="ion-text-end">{{
            item[row.value] + '%'
            }}</ion-label>
          <ng-container *ngIf="row.value === 'hum' && !item[row.value]">
            <ion-label class="ion-text-end" color="medium">{{
              'SENSOR.NO_DATA' | translate
              }}</ion-label>
          </ng-container>

          <ion-label *ngIf="row.value === 'serial_number' && item[row.value]" class="ion-text-end"
          >{{ item[row.value] }}</ion-label>
          <ng-container *ngIf="row.value === 'serial_number' && !item[row.value]">
            <ion-label class="ion-text-end" color="medium">{{
              'SENSOR.NO_DATA' | translate
              }}</ion-label>
          </ng-container>

          <ion-label
            *ngIf="row.value === 'last_connected' && item[row.value]"
            (click)="toggleDisplayFormat1()"
            style="cursor: pointer; font-size: medium"
            class="ion-text-end"
          >{{ item[row.value] | dateAgo: displayDaysAgoFormat1 }}</ion-label>
          <ng-container *ngIf="row.value === 'last_connected' && !item[row.value]">
            <ion-label class="ion-text-end" color="medium">{{
              'SENSOR.NO_DATA' | translate
              }}</ion-label>
          </ng-container>
          <ion-label
            *ngIf="row.value === 'created_at' && item[row.value]"
            (click)="toggleDisplayFormat2()"
            style="cursor: pointer; font-size: medium"
            class="ion-text-end"
          >{{ item[row.value] | dateAgo: displayDaysAgoFormat2 }}</ion-label>
          <ng-container *ngIf="row.value === 'created_at' && !item[row.value]">
            <ion-label class="ion-text-end" color="medium">{{
              'SENSOR.NO_DATA' | translate
              }}</ion-label>
          </ng-container>

          <ion-label
            *ngIf="row.value === 'battery_level' && (item[row.value] != null)"
            class="ion-text-end"
          >{{ item[row.value] + '%' }}</ion-label>
          <ng-container *ngIf="row.value === 'battery_level' && (item[row.value] == null)">
            <ion-label class="ion-text-end" color="medium">{{
              'SENSOR.NO_DATA' | translate
              }}</ion-label>
          </ng-container>

          <ion-buttons *ngIf="row.value === 'actions'" class="item__actions">
            <ion-button fill="clear" color="primary" (click)="openFeedback(item)">
              <ion-icon slot="icon-only" name="chatbox-ellipses-outline" color="primary"></ion-icon>
            </ion-button>
            <ion-button
              fill="clear"
              color="primary"
              (click)="openHistory(item); $event.stopPropagation()">
              <ion-icon slot="icon-only" name="time-outline" color="primary"></ion-icon>
            </ion-button>
          </ion-buttons>
        </ion-item>
      </ion-list>
    </ion-accordion>
    </ng-container>
  </ion-accordion-group>
</ng-container>

<ion-list *ngIf="!isDataFetched">
  <ion-item *ngFor="let item of [1, 2, 3, 4, 5, 6, 7]">
    <ion-label>
      <p *ngFor="let line of [1, 2, 3, 4]" class="ion-padding-top">
        <ion-skeleton-text [animated]="true"></ion-skeleton-text>
      </p>
    </ion-label>
  </ion-item>
</ion-list>
