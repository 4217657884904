import { NgModule } from '@angular/core';
import { CommonModule, NgOptimizedImage } from '@angular/common';
import { UserProfileComponent } from './user-profile/user-profile.component';
import { UserProfileRoutingModule } from './user-profile-routing.module';
import { IonicModule } from '@ionic/angular';
import { SharedModule } from '../../shared/shared.module';
import { FeedbackHistoryComponent } from './feedback-history/feedback-history.component';
import { FeedbackHistoryTableComponent } from './feedback-history/feedback-history-table/feedback-history-table.component';
import { TranslateModule } from '@ngx-translate/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

@NgModule({
  declarations: [UserProfileComponent, FeedbackHistoryComponent, FeedbackHistoryTableComponent],
  imports: [
    CommonModule,
    UserProfileRoutingModule,
    IonicModule,
    SharedModule,
    NgOptimizedImage,
    TranslateModule,
    ReactiveFormsModule,
    FormsModule,
  ],
})
export class UserProfileModule {}
