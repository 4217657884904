import { Component, OnInit } from '@angular/core';
import { AuthService } from '@auth0/auth0-angular';
import { Router } from '@angular/router';
import { MainService } from '../../services/main.service';
import packageJson from '../../../../package.json';
import { AlertService } from '../../services/alert.service';

@Component({
  selector: 'app-auth',
  templateUrl: './auth.component.html',
  styleUrls: ['./auth.component.scss'],
})
export class AuthComponent implements OnInit {
  isLoading: boolean = true;

  versionBE: string = '...';

  versionFE: string = '';

  constructor(
    protected readonly authService: AuthService,
    private readonly router: Router,
    private mainService: MainService,
    private alertService: AlertService,
  ) {
    this.mainService.version().subscribe({
      next: (version: any) => {
        this.versionBE = `${version.projectVersion}`;
      },
      error: () => {
        this.alertService.showFailureAlert('', 'Error while fetching BE version').then();
      },
    });
    this.versionFE = packageJson.version;
  }

  ngOnInit() {
    this.authService.isAuthenticated$.subscribe((authCheck) => {
      if (authCheck) {
        this.router.navigateByUrl('/sensors').then();
      }
      this.isLoading = false;
    });
  }

  login(): void {
    this.authService.loginWithRedirect({
      appState: { target: '/sensors' },
    });
  }

  signup(): void {
    this.authService.loginWithRedirect({
      authorizationParams: {
        screen_hint: 'signup',
      },
    });
  }
}
