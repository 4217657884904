<ion-accordion-group animated="false">
  <ion-accordion value="from" class="border-radius-10 ion-margin-vertical" #accordionDateFrom>
    <ion-item slot="header" color="light" lines="none">
      <ion-label
      >{{ 'SENSOR.HISTORY.FROM' | translate }}:
        {{ period.start | amLocale:langService.language | amDateFormat: 'll' }}</ion-label>
    </ion-item>
    <div slot="content">
      <ion-datetime
        [locale]="translate.currentLang"
        [value]="period.start"
        [max]="period.end"
        presentation="date"
        showDefaultTimeLabel="false"
        (ionChange)="setPeriodStart($event); toggleStartAccordion();"
      ></ion-datetime>
    </div>
  </ion-accordion>
  <ion-accordion value="to" class="border-radius-10" #accordionDateTo>
    <ion-item slot="header" color="light" lines="none">
      <ion-label
      >{{ 'SENSOR.HISTORY.TO' | translate }}
        : {{ period.end | amLocale:langService.language | amDateFormat: 'll' }}</ion-label>
    </ion-item>
    <div slot="content">
      <ion-datetime
        [locale]="translate.currentLang"
        [value]="period.end"
        [min]="period.start"
        presentation="date"
        showDefaultTimeLabel="false"
        (ionChange)="setPeriodEnd($event); toggleEndAccordion();"
      ></ion-datetime>
    </div>
  </ion-accordion>
</ion-accordion-group>
<ng-container>
  <ion-item lines="none" class="graph-name">
    <ion-label color="nimus_red"> {{ 'SENSOR.TEMP' | translate }} (°C)</ion-label>
  </ion-item>
  <ion-card
    class="graph-no-data animate__animated animate__fadeIn animate__fast"
    *ngIf="!sensorTempData.length && isDataFetched || sensorTempData[0] === undefined && isDataFetched">
    <ion-card-subtitle color="medium">{{ 'GRAPH.NO_DATA' | translate }}</ion-card-subtitle>
  </ion-card>
  <ion-list *ngIf="!sensorTempData.length && !isDataFetched && showSkeleton">
    <ion-item *ngFor="let item of [1]" lines="none">
      <ion-label>
        <p *ngFor="let line of [1, 2, 3, 4, 5]" class="ion-padding-top">
          <ion-skeleton-text class="nimus_red" [animated]="true"></ion-skeleton-text>
        </p>
      </ion-label>
    </ion-item>
  </ion-list>
  <div class="animate__animated animate__fadeIn animate__slow" *ngIf="sensorTempData.length">
    <div
      echarts
      style="height: 420px; padding-top: 10px; padding-bottom: 30px"
      [options]="tempChartOptions"
      class="demo-chart ion-no-padding"
    ></div>
  </div>
</ng-container>
<ng-container>
  <ion-item lines="none" class="graph-name">
    <ion-label color="nimus_blue"> {{ 'SENSOR.HUMID' | translate }} (%)</ion-label>
  </ion-item>
  <ion-card
    class="graph-no-data animate__animated animate__fadeIn animate__fast"
    *ngIf="!sensorHumData.length && isDataFetched || sensorHumData[0] === undefined && isDataFetched">
    <ion-card-subtitle color="medium">{{ 'GRAPH.NO_DATA' | translate }}</ion-card-subtitle>
  </ion-card>
  <div class="animate__animated animate__fadeIn animate__slow" *ngIf="sensorHumData.length">
    <div
      echarts
      style="height: 420px; padding-top: 10px; padding-bottom: 30px"
      [options]="humChartOptions"
      class="demo-chart ion-no-padding"
    ></div>
  </div>
  <ion-list *ngIf="!sensorHumData.length && !isDataFetched">
    <ion-item *ngFor="let item of [1]" lines="none">
      <ion-label>
        <p *ngFor="let line of [1, 2, 3, 4, 5]" class="ion-padding-top">
          <ion-skeleton-text class="nimus_blue" [animated]="true"></ion-skeleton-text>
        </p>
      </ion-label>
    </ion-item>
  </ion-list>
</ng-container>
<ng-container>
  <ion-item lines="none" class="graph-name">
    <ion-label color="nimus_green"> {{ 'SENSOR.PLANT_HEIGHT' | translate }} (cm)</ion-label>
  </ion-item>
  <ion-item lines="none" *ngIf="thereIsAi">
    <ion-label style="color: #bbd034">{{ 'SENSOR.PLANT_HEIGHT_PREDICTION' | translate }}</ion-label>
  </ion-item>
  <ion-card
    class="graph-no-data animate__animated animate__fadeIn animate__fast"
    *ngIf="!sensorHeightData.length && isDataFetched || sensorHeightData[0] === undefined && isDataFetched">
    <ion-card-subtitle color="medium">{{ 'GRAPH.NO_DATA' | translate }}</ion-card-subtitle>
  </ion-card>
  <div class="animate__animated animate__fadeIn animate__slow" *ngIf="sensorHeightData.length">
    <div
      id="heightChart"
      echarts
      style="height: 420px; padding-top: 10px; padding-bottom: 30px"
      [options]="heightChartOptions"
      class="demo-chart ion-no-padding"
    ></div>
  </div>
  <ion-list *ngIf="!sensorHeightData.length && !isDataFetched">
    <ion-item *ngFor="let item of [1]" lines="none">
      <ion-label>
        <p *ngFor="let line of [1, 2, 3, 4, 5]" class="ion-padding-top">
          <ion-skeleton-text class="nimus_blue" [animated]="true"></ion-skeleton-text>
        </p>
      </ion-label>
    </ion-item>
  </ion-list>

</ng-container>
