/**
 * NIMUS CLOUD API Backend
 * NIMUS Cloud Backend Documentation
 *
 * OpenAPI spec version: 1.0.0
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */ /* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams, HttpResponse, HttpEvent } from '@angular/common/http';
import { CustomHttpUrlEncodingCodec } from '../encoder';

import { Observable } from 'rxjs';

import { GatewayCodeResponseDto } from '../model/gatewayCodeResponseDto';
import { GatewayItemDto } from '../model/gatewayItemDto';
import { GatewaySaveRequestDto } from '../model/gatewaySaveRequestDto';
import { GatewaySaveResponseDto } from '../model/gatewaySaveResponseDto';
import { GatewaySearchRequestDto } from '../model/gatewaySearchRequestDto';
import { GatewaySearchResponseDto } from '../model/gatewaySearchResponseDto';
import { GatewayUpdateRequestDto } from '../model/gatewayUpdateRequestDto';
import { SuccessDto } from '../model/successDto';

import { BASE_PATH, COLLECTION_FORMATS } from '../variables';
import { Configuration } from '../configuration';

@Injectable()
export class GatewaysService {
  protected basePath = 'http://127.0.0.1:3000/api';

  public defaultHeaders = new HttpHeaders();

  public configuration = new Configuration();

  constructor(
    protected httpClient: HttpClient,
    @Optional() @Inject(BASE_PATH) basePath: string,
    @Optional() configuration: Configuration,
  ) {
    if (basePath) {
      this.basePath = basePath;
    }
    if (configuration) {
      this.configuration = configuration;
      this.basePath = basePath || configuration.basePath || this.basePath;
    }
  }

  /**
   * @param consumes string[] mime-types
   * @return true: consumes contains 'multipart/form-data', false: otherwise
   */
  private canConsumeForm(consumes: string[]): boolean {
    const form = 'multipart/form-data';
    for (const consume of consumes) {
      if (form === consume) {
        return true;
      }
    }
    return false;
  }

  /**
   * Gateway item delete
   *
   * @param gateway_id
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public gatewaysControllerDelete(
    gateway_id: number,
    observe?: 'body',
    reportProgress?: boolean,
  ): Observable<SuccessDto>;
  public gatewaysControllerDelete(
    gateway_id: number,
    observe?: 'response',
    reportProgress?: boolean,
  ): Observable<HttpResponse<SuccessDto>>;
  public gatewaysControllerDelete(
    gateway_id: number,
    observe?: 'events',
    reportProgress?: boolean,
  ): Observable<HttpEvent<SuccessDto>>;
  public gatewaysControllerDelete(
    gateway_id: number,
    observe: any = 'body',
    reportProgress: boolean = false,
  ): Observable<any> {
    if (gateway_id === null || gateway_id === undefined) {
      throw new Error(
        'Required parameter gateway_id was null or undefined when calling gatewaysControllerDelete.',
      );
    }

    let headers = this.defaultHeaders;

    // authentication (bearer) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === 'function'
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set('Authorization', 'Bearer ' + accessToken);
    }
    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.request<SuccessDto>(
      'delete',
      `${this.basePath}/gateways/${encodeURIComponent(String(gateway_id))}`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      },
    );
  }

  /**
   * Gateway item get
   *
   * @param gateway_id
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public gatewaysControllerGetGlobal(
    gateway_id: number,
    observe?: 'body',
    reportProgress?: boolean,
  ): Observable<GatewayItemDto>;
  public gatewaysControllerGetGlobal(
    gateway_id: number,
    observe?: 'response',
    reportProgress?: boolean,
  ): Observable<HttpResponse<GatewayItemDto>>;
  public gatewaysControllerGetGlobal(
    gateway_id: number,
    observe?: 'events',
    reportProgress?: boolean,
  ): Observable<HttpEvent<GatewayItemDto>>;
  public gatewaysControllerGetGlobal(
    gateway_id: number,
    observe: any = 'body',
    reportProgress: boolean = false,
  ): Observable<any> {
    if (gateway_id === null || gateway_id === undefined) {
      throw new Error(
        'Required parameter gateway_id was null or undefined when calling gatewaysControllerGetGlobal.',
      );
    }

    let headers = this.defaultHeaders;

    // authentication (bearer) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === 'function'
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set('Authorization', 'Bearer ' + accessToken);
    }
    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.request<GatewayItemDto>(
      'get',
      `${this.basePath}/gateways/${encodeURIComponent(String(gateway_id))}`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      },
    );
  }

  /**
   * Regenerate code for exist gateway
   *
   * @param gateway_id
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public gatewaysControllerRegenerateCode(
    gateway_id: number,
    observe?: 'body',
    reportProgress?: boolean,
  ): Observable<GatewayCodeResponseDto>;
  public gatewaysControllerRegenerateCode(
    gateway_id: number,
    observe?: 'response',
    reportProgress?: boolean,
  ): Observable<HttpResponse<GatewayCodeResponseDto>>;
  public gatewaysControllerRegenerateCode(
    gateway_id: number,
    observe?: 'events',
    reportProgress?: boolean,
  ): Observable<HttpEvent<GatewayCodeResponseDto>>;
  public gatewaysControllerRegenerateCode(
    gateway_id: number,
    observe: any = 'body',
    reportProgress: boolean = false,
  ): Observable<any> {
    if (gateway_id === null || gateway_id === undefined) {
      throw new Error(
        'Required parameter gateway_id was null or undefined when calling gatewaysControllerRegenerateCode.',
      );
    }

    let headers = this.defaultHeaders;

    // authentication (bearer) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === 'function'
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set('Authorization', 'Bearer ' + accessToken);
    }
    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.request<GatewayCodeResponseDto>(
      'patch',
      `${this.basePath}/gateways/code/${encodeURIComponent(String(gateway_id))}`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      },
    );
  }

  /**
   * Add gateway
   *
   * @param body
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public gatewaysControllerSave(
    body: GatewaySaveRequestDto,
    observe?: 'body',
    reportProgress?: boolean,
  ): Observable<GatewaySaveResponseDto>;
  public gatewaysControllerSave(
    body: GatewaySaveRequestDto,
    observe?: 'response',
    reportProgress?: boolean,
  ): Observable<HttpResponse<GatewaySaveResponseDto>>;
  public gatewaysControllerSave(
    body: GatewaySaveRequestDto,
    observe?: 'events',
    reportProgress?: boolean,
  ): Observable<HttpEvent<GatewaySaveResponseDto>>;
  public gatewaysControllerSave(
    body: GatewaySaveRequestDto,
    observe: any = 'body',
    reportProgress: boolean = false,
  ): Observable<any> {
    if (body === null || body === undefined) {
      throw new Error(
        'Required parameter body was null or undefined when calling gatewaysControllerSave.',
      );
    }

    let headers = this.defaultHeaders;

    // authentication (bearer) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === 'function'
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set('Authorization', 'Bearer ' + accessToken);
    }
    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected: string | undefined =
      this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected != undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    return this.httpClient.request<GatewaySaveResponseDto>('post', `${this.basePath}/gateways`, {
      body: body,
      withCredentials: this.configuration.withCredentials,
      headers: headers,
      observe: observe,
      reportProgress: reportProgress,
    });
  }

  /**
   * Gateways search
   *
   * @param body
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public gatewaysControllerSearch(
    body: GatewaySearchRequestDto,
    observe?: 'body',
    reportProgress?: boolean,
  ): Observable<GatewaySearchResponseDto>;
  public gatewaysControllerSearch(
    body: GatewaySearchRequestDto,
    observe?: 'response',
    reportProgress?: boolean,
  ): Observable<HttpResponse<GatewaySearchResponseDto>>;
  public gatewaysControllerSearch(
    body: GatewaySearchRequestDto,
    observe?: 'events',
    reportProgress?: boolean,
  ): Observable<HttpEvent<GatewaySearchResponseDto>>;
  public gatewaysControllerSearch(
    body: GatewaySearchRequestDto,
    observe: any = 'body',
    reportProgress: boolean = false,
  ): Observable<any> {
    if (body === null || body === undefined) {
      throw new Error(
        'Required parameter body was null or undefined when calling gatewaysControllerSearch.',
      );
    }

    let headers = this.defaultHeaders;

    // authentication (bearer) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === 'function'
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set('Authorization', 'Bearer ' + accessToken);
    }
    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected: string | undefined =
      this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected != undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    return this.httpClient.request<GatewaySearchResponseDto>(
      'post',
      `${this.basePath}/gateways/search`,
      {
        body: body,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      },
    );
  }

  /**
   * Update exist gateway
   *
   * @param body
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public gatewaysControllerUpdate(
    body: GatewayUpdateRequestDto,
    observe?: 'body',
    reportProgress?: boolean,
  ): Observable<SuccessDto>;
  public gatewaysControllerUpdate(
    body: GatewayUpdateRequestDto,
    observe?: 'response',
    reportProgress?: boolean,
  ): Observable<HttpResponse<SuccessDto>>;
  public gatewaysControllerUpdate(
    body: GatewayUpdateRequestDto,
    observe?: 'events',
    reportProgress?: boolean,
  ): Observable<HttpEvent<SuccessDto>>;
  public gatewaysControllerUpdate(
    body: GatewayUpdateRequestDto,
    observe: any = 'body',
    reportProgress: boolean = false,
  ): Observable<any> {
    if (body === null || body === undefined) {
      throw new Error(
        'Required parameter body was null or undefined when calling gatewaysControllerUpdate.',
      );
    }

    let headers = this.defaultHeaders;

    // authentication (bearer) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === 'function'
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set('Authorization', 'Bearer ' + accessToken);
    }
    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected: string | undefined =
      this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected != undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    return this.httpClient.request<SuccessDto>('put', `${this.basePath}/gateways`, {
      body: body,
      withCredentials: this.configuration.withCredentials,
      headers: headers,
      observe: observe,
      reportProgress: reportProgress,
    });
  }
}
