import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import {
  PlantItemSearchDto,
  PlantProfilesService,
  PlantSaveRequestDto,
  PlantSearchRequestDto,
  PlantSearchResponseDto,
  PlantUpdateRequestDto,
  SuccessDto,
} from '../../../api';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { mockProfilesData } from '../../shared/mock-data/plant-profiles';
import { UserService } from '../../services/user.service';
import { Router } from '@angular/router';
import { NgxIndexedDBService } from 'ngx-indexed-db';
import { AlertService } from '../../services/alert.service';

@Injectable({
  providedIn: 'root',
})
export class PlantProfileService {
  filteredItems: PlantItemSearchDto[] = [];

  constructor(
    private plantService: PlantProfilesService,
    private readonly http: HttpClient,
    private userService: UserService,
    private router: Router,
    private alertService: AlertService,
    private dbService: NgxIndexedDBService,
  ) {
    this.plantService = new PlantProfilesService(
      this.http,
      environment.apiURL,
      // @ts-ignore
      undefined,
    );
  }

  restorePlantImage(id: number): Observable<any> {
    return this.dbService.getByKey('plant-images', id);
  }

  savePlantImage(id: number, img: Blob) {
    return new Observable<boolean>((observer) => {
      if (img) {
        this.dbService.bulkPut('plant-images', [{ img, id }]).subscribe({
          next: () => {
            observer.next(true);
            observer.complete();
          },
          error: (err) => {
            console.error("Couldn't save image to cache: ", err.message);
            this.alertService.showFailureAlert('', 'Error while saving plant image').then();
            observer.next(false);
            observer.complete();
          },
        });
      }
    });
  }

  createUrlId(globalId: number, userId: number): string {
    if (userId) {
      return `u${userId}`;
    }
    return `g${globalId}`;
  }

  getProfilesList(params: PlantSearchRequestDto): Observable<PlantSearchResponseDto> {
    return environment.useMockData
      ? mockProfilesData
      : this.plantService.plantsControllerSearchGlobal(params);
  }

  updateProfile(request: PlantUpdateRequestDto): Observable<SuccessDto> {
    if (this.userService.userRole === 'admin') {
      return this.plantService.plantsControllerUpdateGlobal({
        ...request,
        id_user: null!,
      });
    }

    return this.plantService.plantsControllerUpdateUser(request);
  }

  addProfile(request: PlantSaveRequestDto): Observable<SuccessDto> {
    if (this.userService.userRole === 'admin') {
      return this.plantService.plantsControllerSaveGlobal({
        ...request,
        id_global: 0,
      });
    }

    return this.plantService.plantsControllerSaveUser({
      ...request,
      id_user: 0,
    });
  }

  deleteProfile(id: number) {
    if (this.userService.userRole === 'admin') {
      return this.plantService.plantsControllerDeleteGlobal(id);
    }

    return this.plantService.plantsControllerDeleteUser(id);
  }

  getProfile(id: string) {
    if (environment.useMockData) {
      return mockProfilesData.find((x: any) => x.id_global === Number(id.slice(1)));
    }
    return id.startsWith('u')
      ? this.plantService.plantsControllerGetUser(Number(id.substring(1)))
      : this.plantService.plantsControllerGetGlobal(Number(id.substring(1)));
  }

  saveChanges(urlId: string, profile: PlantUpdateRequestDto) {
    let sentProfile = profile;
    if (environment.useMockData) {
      mockProfilesData[Number(urlId.slice(1))] = profile;
    } else {
      const updateRequest = urlId.startsWith('u')
        ? this.plantService.plantsControllerUpdateUser(sentProfile)
        : this.plantService.plantsControllerUpdateGlobal(sentProfile);
      updateRequest.subscribe({
        next: (res) => {
          if (res.code === 200) {
            this.router.navigate(['/plant-profiles']).then();
            this.alertService.showSuccessAlert('Profile updated successfully').then();
          } else {
            this.alertService
              .showFailureAlert(res.code, 'Profile update failed, error code: ')
              .then();
          }
        },
        error: () => {
          this.alertService.showFailureAlert('', 'Error while saving changes').then();
        },
      });
    }
  }
}
