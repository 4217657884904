import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ActionSheetController, RangeCustomEvent } from '@ionic/angular';
import { RangeValue } from '@ionic/core';
import { TranslateService } from '@ngx-translate/core';
import { tap } from 'rxjs';
import { PlantUpdateRequestDto } from '../../../../api';
import { LangService } from '../../../services/lang.service';
import { UserService } from '../../../services/user.service';
import { FilesService } from '../files.service';
import { PlantProfileService } from '../plant-profiles.service';
import { AlertService } from '../../../services/alert.service';
import { Location } from '@angular/common';

@Component({
  selector: 'app-profile-edit',
  templateUrl: './profile-edit.component.html',
  styleUrls: ['./profile-edit.component.scss'],
})
export class ProfileEditComponent {
  profile: PlantUpdateRequestDto = {
    file_id: 0,
    id_global: 0,
    id_user: 0,
    hum_max: 0,
    hum_min: 0,
    temp_max: 0,
    temp_min: 0,
    name_lang: [{ lang: 'en', value: '' }],
    info_lang: [{ lang: 'en', value: '' }],
    interval: 'none',
  };

  name: string = '';

  translatableName: string = '';

  info: string = '';

  hasOptTemp: boolean = false;

  hasOptHum: boolean = false;

  urlId: string = '';

  languageId: number = 0;

  translationLanguageId: number = 0;

  formData = new FormData();

  tempPictureArray: number[] = [];

  blob: any;

  defaultImage: string = '/assets/svg/loading.svg';

  is_user_admin: boolean = false;

  constructor(
    private route: ActivatedRoute,
    public profilesService: PlantProfileService,
    private alertService: AlertService,
    public filesService: FilesService,
    private actionSheetCtrl: ActionSheetController,
    private router: Router,
    private translateService: TranslateService,
    private langService: LangService,
    private userService: UserService,
    private translate: TranslateService,
    private location: Location,
  ) {
    this.urlId = <string>this.route.snapshot.paramMap.get('id');
    this.getClickedProfile();
    this.is_user_admin = userService.userRole === 'admin';
  }

  getClickedProfile() {
    this.profilesService.getProfile(this.urlId).subscribe({
      next: (res: PlantUpdateRequestDto) => {
        this.profile = res;
        if (this.profile.hum_opt) {
          this.hasOptHum = true;
        }
        if (this.profile.temp_opt) {
          this.hasOptTemp = true;
        }
        this.getProfileImage();
        this.translateService.onLangChange.subscribe({
          next: () => {
            this.proceedLanguageChange(res);
          },
        });
        this.proceedLanguageChange(res);
      },
      error: (err: any) => {
        this.alertService.showFailureAlert(err.message, 'Database error').then();
      },
    });
  }

  proceedLanguageChange(res: PlantUpdateRequestDto) {
    this.getCurrentLanguageId();
    this.getTranslationLanguageId();
    this.name = this.getTranslation(res.name_lang);
    this.info = this.getTranslation(res.info_lang);
  }

  getCurrentLanguageId() {
    this.languageId = this.profile.name_lang.indexOf(
      // @ts-ignore
      (this.translatableName = this.profile.name_lang.find((data) => {
        return data.lang == this.langService.language;
      })),
    );
  }

  getTranslationLanguageId() {
    this.translationLanguageId = this.profile.name_lang.indexOf(
      // @ts-ignore
      this.profile.name_lang.find((data) => {
        return data.lang === this.getDifferentLanguage();
      }),
    );
  }

  blockElementFromUser() {
    return this.urlId[0] === 'g' && this.userService.userRole === 'user';
  }

  onTempChange(ev: Event) {
    let changedValues: RangeValue = (ev as RangeCustomEvent).detail.value;
    if (typeof changedValues !== 'number') {
      this.profile.temp_min = changedValues.lower;
      this.profile.temp_max = changedValues.upper;
    }
  }

  onHumidChange(ev: Event) {
    let changedValues: RangeValue = (ev as RangeCustomEvent).detail.value;
    if (typeof changedValues !== 'number') {
      this.profile.hum_min = changedValues.lower;
      this.profile.hum_max = changedValues.upper;
    }
  }

  humidPinFormatter(value: number) {
    return `${value}%`;
  }

  tempPinFormatter(value: number) {
    return `${value}°C`;
  }

  save() {
    if (this.userService.userRole === 'admin' && !this.profile.interval) {
      this.alertService.showFailureAlert(400, 'PP.TOAST.INTERVAL').then();
      return;
    }

    //Set translations
    const infoLng = this.profile.info_lang.find((item) => {
      return item.lang === this.langService.language;
    });
    if (infoLng) {
      infoLng.value = this.info;
    } else {
      this.profile.info_lang.push({
        lang: this.langService.language,
        value: this.info,
      });
    }
    const nameLng = this.profile.name_lang.find((item) => {
      return item.lang === this.langService.language;
    });
    if (nameLng) {
      nameLng.value = this.name;
    } else {
      this.profile.name_lang.push({
        lang: this.langService.language,
        value: this.name,
      });
    }

    this.profilesService.updateProfile(this.profile).subscribe({
      next: (res) => {
        if (res.code === 200) {
          this.alertService
            .showSuccessAlert(this.translate.instant('PP.TOAST.SAVE_SUCCESS'))
            .then();
          this.location.back();
        } else {
          this.alertService
            .showFailureAlert(res.code, 'PP.TOAST.SAVE_ERROR' + ', error code: ')
            .then();
        }
      },
      error: (err) => {
        this.alertService
          .showFailureAlert(err.message, 'PP.TOAST.SAVE_ERROR' + ', error code: ')
          .then();
      },
    });
  }

  getTranslation(item: any): string {
    if (item.length === 0) {
      return '';
    }
    if (item[this.languageId]) {
      return item[this.languageId].value;
    }
    return '';
  }

  async openDeleteModal(profile: PlantUpdateRequestDto) {
    let deletedId = null;
    const actionSheet = await this.actionSheetCtrl.create({
      cssClass: 'action-sheet-delete',
      header: `Are you sure you want to delete ${this.getTranslation(profile.name_lang)} profile?`,
      buttons: [
        {
          text: 'Delete',
          role: 'destructive',
          data: {
            action: 'delete',
          },
        },
        {
          text: 'Cancel',
          role: 'cancel',
          data: {
            action: 'cancel',
          },
        },
      ],
    });
    await actionSheet.present();
    const { data } = await actionSheet.onWillDismiss();
    if (data?.action === 'delete') {
      deletedId = profile.id_user === null ? profile.id_global : profile.id_user;
      this.profilesService.deleteProfile(deletedId!).subscribe({
        next: (res) => {
          if (res.code === 202) {
            if (profile.file_id) {
              this.filesService.deleteImage(profile.file_id).subscribe({
                next: () => {},
                error: () => {
                  this.alertService.showFailureAlert('', 'Error while deleting image').then();
                },
              });
            }

            // this.alertService.showSuccessAlert('PP.TOAST.DELETE_SUCCESS');
            this.router.navigate(['/plant-profiles']);
          } else {
            this.alertService.showFailureAlert(
              res.code,
              this.translate.instant('PP.TOAST.DELETE_ERROR') + ', error code: ',
            );
          }
        },
        error: (err) => {
          this.alertService.showFailureAlert(
            err.status,
            this.translate.instant('PP.TOAST.DELETE_ERROR') + ', error code: ',
          );
        },
      });
    }
  }

  getProfileImage(): void {
    if (!this.profile?.file_id) {
      this.blob = '/assets/svg/default.svg';
      return;
    }

    this.filesService.getImage(this.profile.file_id).subscribe({
      next: (val) => {
        this.createImageFromBlob(val);
      },
      error: (err) => {
        this.blob = '/assets/svg/default.svg';
        this.alertService
          .showFailureAlert(err.message, this.translate.instant('PP.TOAST.IMAGE_ERROR'))
          .then();
      },
    });
  }

  createImageFromBlob(img: Blob) {
    let reader = new FileReader();
    reader.addEventListener(
      'load',
      () => {
        this.blob = reader.result;
      },
      false,
    );
    if (img) {
      reader.readAsDataURL(img);
    }
  }

  openFileDialog() {
    (document as any).getElementById('file-upload').click();
  }

  onFileChange(event: Event) {
    // @ts-ignore
    const image: File = event.target.files[0];
    if (!image.name.includes('.jpg')) {
      this.alertService.showFailureAlert('', this.translate.instant('PP.TOAST.WRONG_IMAGE')).then();
      return;
    }
    if (image) {
      this.formData.delete('source');
      this.formData.delete('file');
      this.formData.append('source', this.is_user_admin ? 'plant_global' : 'plant');
      this.formData.append('file', image);
      this.filesService.postFile(this.formData).subscribe({
        next: (res) => {
          this.tempPictureArray.push(res.id);
          this.profile.file_id = res.id;
          this.getProfileImage();
        },
        error: (err) => {
          this.alertService
            .showFailureAlert('', this.translate.instant('PP.TOAST.IMAGE_ERROR'))
            .then();
        },
      });
    }
  }

  getDifferentLanguage(): string {
    return this.langService.language === 'en' ? 'pl' : 'en';
  }

  getDifferentLanguageName(): string {
    return this.langService.language === 'pl'
      ? this.translate.instant('LANG.ENG')
      : this.translate.instant('LANG.PL');
  }

  onCancel() {
    this.tempPictureArray.forEach((fileId) => {
      this.filesService
        .deleteImage(fileId)
        .pipe(tap(() => {}))
        .subscribe();
    });
    this.location.back();
  }

  selectInterval(event: Event) {
    this.profile.interval = (<any>event).detail.value;
  }
}
