import { Platform } from '@angular/cdk/platform';
import { Injectable } from '@angular/core';
import { AlertController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { take, timer } from 'rxjs';
import { SwPush } from '@angular/service-worker';
import { environment } from '../../environments/environment';
import { UserService } from './user.service';
import { UserNotificationRequestDto } from '../../api';

@Injectable({
  providedIn: 'root',
})
export class PwaService {
  constructor(
    private readonly platform: Platform,
    private alertController: AlertController,
    private readonly translate: TranslateService,
    private swPush: SwPush,
    private userService: UserService,
  ) {
    // Support for clicking on push notification action
    // this.swPush.notificationClicks.subscribe((event) => {
    //   // console.log(
    //   //   'Action: ' + event.action,
    //   //   'Notification data: ' + event.notification.data,
    //     // 'Notification data.url: ' + event.notification.data.url,
    //     // 'Notification data.body: ' + event.notification.body,
    //   );
    // });
  }

  get isPwa(): boolean {
    return this.swPush.isEnabled;
  }

  public subscribeToPush(notificationsObject: UserNotificationRequestDto) {
    if (!this.swPush.isEnabled) {
      console.warn('swPush not enabled!');
      return;
    }
    this.swPush
      .requestSubscription({
        serverPublicKey: environment.pushPublicKey,
      })
      .then((sub: PushSubscription) => {
        notificationsObject.subscription = sub;
        this.savePushSubscription(notificationsObject);
      });
  }

  public unsubscribeFromPush(notificationsObject: UserNotificationRequestDto) {
    // console.log('unsubscribeFromPush', this.swPush.isEnabled);
    this.swPush.unsubscribe().then(() => {
      notificationsObject.subscription = null;
      this.savePushSubscription(notificationsObject);
    });
  }

  public savePushSubscription(notifications: UserNotificationRequestDto) {
    this.userService.setUserNotifications(notifications);
  }

  public resetIgnore() {
    localStorage.removeItem('ignorePwaPrompt');
  }

  public initPwaPrompt(): void {
    const isIgnored = localStorage.getItem('ignorePwaPrompt');
    if (isIgnored) return;

    if (this.platform.ANDROID) {
      window.addEventListener('beforeinstallprompt', (event) => {
        event.preventDefault();
        this.openPromptComponent(event);
      });
    }

    if (this.platform.IOS) {
      const isInStandaloneMode =
        'standalone' in (window as any).navigator && (<any>window).navigator.standalone;
      if (!isInStandaloneMode) {
        this.openPromptComponent(new Event(<any>undefined));
      }
    }

    if (this.platform.isBrowser) {
      // console.log('Welcome to the browser PWA');
      timer(1000)
        .pipe(take(1))
        .subscribe(async () => {
          this.openInfoComponent().then();
        });
    }
  }

  private openPromptComponent(event: Event) {
    timer(1000)
      .pipe(take(1))
      .subscribe(async () => {
        const alert = await this.alertController.create({
          header: this.translate.instant('PWA.HEADER'),
          subHeader: this.translate.instant('PWA.SUBHEADER'),
          message: this.translate.instant('PWA.MESSAGE'),
          buttons: [
            {
              text: this.translate.instant('PWA.BUTTON.PROCEED'),
              role: 'confirm',
              handler: () => {
                if (this.platform.ANDROID) (<any>event).prompt();
              },
            },
            {
              text: this.translate.instant('PWA.BUTTON.CANCEL'),
              role: 'cancel',
              handler: () => {
                localStorage.setItem('ignorePwaPrompt', 'true');
              },
            },
          ],
          mode: 'ios',
        });

        await alert.present();
      });
  }

  private async openInfoComponent() {
    const alert = await this.alertController.create({
      header: this.translate.instant('PWA.HEADER'),
      message: this.translate.instant('PWA.ALTERNATIVE'),
      buttons: [
        {
          text: this.translate.instant('PWA.BUTTON.CLOSE'),
          role: 'cancel',
          handler: () => {
            localStorage.setItem('ignorePwaPrompt', 'true');
          },
        },
        {
          text: this.translate.instant('PWA.BUTTON.CANCEL'),
          role: 'cancel',
          handler: () => {
            localStorage.setItem('ignorePwaPrompt', 'true');
          },
        },
      ],
    });
    await alert.present();
  }
}
