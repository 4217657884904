<ion-grid class="ion-margin-bottom">
  <ion-row>
    <ion-col size="11">
      <app-sort
        class="app-sort-data"
        [tableHeaders]="tableHeaders"
        (sortEvent)="sortData($event)"
        [sorting]="startSort"
      ></app-sort>
    </ion-col>
    <ion-col size="1" class="refresh-button-col">
      <ion-button id="refreshButtonData" fill="clear" color="primary" (click)="refreshData()" class="refresh-button">
        <ion-icon slot="icon-only" name="refresh-outline" color="primary" size="large"></ion-icon>
      </ion-button>
    </ion-col>
  </ion-row>

  <!-- Skeleton - waiting... -->
  <ion-list *ngIf="!checkIfData() && !isDataFetched">
    <ion-item *ngFor="let item of [1]" lines="none">
      <ion-label>
        <p *ngFor="let line of [1, 2, 3, 4, 5, 6, 7, 8, 9, 10]" class="ion-padding-top">
          <ion-skeleton-text class="nimus_red" [animated]="true"></ion-skeleton-text>
        </p>
      </ion-label>
    </ion-item>
  </ion-list>

  <ion-row
    *ngIf="!checkIfData() && isDataFetched"
    class="ion-justify-content-center animate__animated animate__fadeIn animate__fast">
    <ion-col size="auto">
      <ion-item lines="none">{{ 'NO_DATA' | translate }}</ion-item>
    </ion-col>
  </ion-row>

  <ion-row *ngIf="checkIfData()">
    <ion-col size="12">
      <ng-container>
        <ion-list>
          <ion-item lines="none">
            <ion-note class="item__note" slot="start">{{ 'DATA_DATETIME' | translate }}</ion-note>
            <ion-note class="item__note" slot="end">{{ 'DATA_VALUES' | translate }}</ion-note>
          </ion-item>
          <ion-item *ngFor="let item of sensorData">
            <ion-label> {{ item.created_at | amLocale:langService.language | amDateFormat: 'YYYY-MM-DD    HH:mm' }} </ion-label>
            <ion-label *ngIf="item.measure_type_id === 1" slot="end">
              {{ item.value + '°C' }}
            </ion-label>
            <ion-label *ngIf="item.measure_type_id === 2" slot="end">
              {{ item.value + '%' }}
<!--              {{'DATA.HUMIDITY' | translate}}-->
            </ion-label>
          </ion-item>
        </ion-list>
      </ng-container>
    </ion-col>
  </ion-row>

  <ion-row class="load-more-button-column">
    <ion-col>
      <ion-button
        expand="block"
        class="ion-text-wrap button-load-more"
        fill="outline"
        *ngIf="!hideShowMoreButton && checkIfData()"
        (click)="loadMore()">
        {{ 'BUTTON.LOAD_MORE' | translate }}
      </ion-button>
    </ion-col>
  </ion-row>
<ion-content>

  <ion-infinite-scroll (ionInfinite)="onIonInfinite($event)">
    <ion-infinite-scroll-content
    loadingText="{{ 'SENSOR.SENSOR_DATA_LOADING' | translate }}"></ion-infinite-scroll-content>
  </ion-infinite-scroll>
</ion-content>

</ion-grid>
