import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { SensorsService } from '../../sensors.service';
import { PlantItemSearchDto, PlantSearchItemResponseDto } from '../../../../../api';
import { LangService } from '../../../../services/lang.service';
import { TranslateService } from '@ngx-translate/core';
import { AlertService } from '../../../../services/alert.service';

@Component({
  selector: 'app-plant-profile-select',
  templateUrl: './plant-profile-select.component.html',
  styleUrls: ['./plant-profile-select.component.scss'],
})
export class PlantProfileSelectComponent implements OnInit {
  constructor(
    private modalCtrl: ModalController,
    private readonly sensorsService: SensorsService,
    protected readonly langService: LangService,
    protected readonly translate: TranslateService,
    private alertService: AlertService,
  ) {}

  items: PlantSearchItemResponseDto[] = [];

  @Input() previouslySelectedItem: PlantItemSearchDto | undefined;

  previouslySelectedItemName: string | undefined;

  selectedItemId:
    | {
        id_global: number | null;
        id_user: number | null;
      }
    | undefined;

  filteredItems: PlantSearchItemResponseDto[] = [];

  selectedIndex!: number;

  isDataFetched: boolean = false;

  searchValue: string = '';

  ngOnInit() {
    this.sensorsService
      .getPlantProfiles({
        filter: [],
        page_index: 0,
        page_size: 25,
        sort_direction: 'asc',
        lang: this.langService.language,
      })
      .subscribe({
        next: (res) => {
          this.items = res.data;
          this.filteredItems = [...this.items];
          this.isDataFetched = true;
          this.previouslySelectedItemName = this.previouslySelectedItem?.name_lang?.find(
            (name: any) => name.lang === this.langService.language,
          )?.value;
        },
        error: () => {
          this.alertService.showFailureAlert('', 'Error while fetching plant profiles').then();
        },
      });
  }

  cancel() {
    this.modalCtrl.dismiss(null, 'cancel').then();
  }

  onSelect(event: any) {
    this.selectedItemId = event.detail.value;
  }

  save() {
    this.modalCtrl.dismiss(this.selectedItemId, 'save').then();
  }

  showLoading() {
    this.isDataFetched = false;
  }

  applySearchFilter(event: Event) {
    this.searchValue = (event.target as HTMLInputElement).value;
    this.searchValue = this.searchValue.trim();
    this.searchValue = this.searchValue.toLowerCase();
    this.sensorsService
      .getPlantProfiles({
        filter: [
          {
            field: 'name',
            value: `%${this.searchValue}%`,
          },
        ],
        page_index: 0,
        page_size: 25,
        sort: [],
        sort_direction: 'asc',
        lang: this.langService.language,
      })
      .subscribe({
        next: (res) => {
          this.filteredItems = res.data;
          this.isDataFetched = true;
        },
        error: () => {
          this.alertService.showFailureAlert('', 'Error while fetching plant profiles').then();
        },
      });
  }
}
