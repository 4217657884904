<app-header></app-header>
<ion-content color="nimus-md3-green">
  <ion-card class="page-card border-radius-25">
    <ion-card-header class="animate__animated animate__fadeIn animate__faster">
      <ion-text color="dark" *ngIf="sensor" class="history__sensor-name ion-text-center">{{
        sensor.name
        }}</ion-text>
      <ng-container *ngIf="!sensor">
        <div style="display: flex; justify-content: center">
          <ion-skeleton-text style="width: 80%" animated="true"></ion-skeleton-text>
        </div>
      </ng-container>
      <ion-text color="dark" class="history__title ion-text-center ion-margin-bottom ion-margin-top">
        {{ 'SENSOR.HISTORY.TITLE' | translate }}
      </ion-text>
      <ion-segment [value]="segment" (ionChange)="showSegment($event)">
        <ion-segment-button value="graphs">
          <ion-label> {{ 'SENSOR.HISTORY.GRAPHS' | translate }} </ion-label>
        </ion-segment-button>
        <ion-segment-button value="data">
          <ion-label> {{ 'SENSOR.HISTORY.DATA' | translate }} </ion-label>
        </ion-segment-button>
        <ion-segment-button value="feedback">
          <ion-label> {{ 'SENSOR.HISTORY.FEEDBACK' | translate }} </ion-label>
        </ion-segment-button>
      </ion-segment>
    </ion-card-header>

    <ion-card-content class="animate__animated animate__fadeIn animate__faster">

      <ng-container *ngIf="segment === 'graphs'">
        <app-graphs></app-graphs>
      </ng-container>

      <ng-container *ngIf="segment === 'data'">
        <div class="ion-no-padding animate__animated animate__fadeIn animate__faster">
          <ng-container>
            <app-data [sensorId]="sensor.id"></app-data>
          </ng-container>
        </div>
      </ng-container>

      <ng-container *ngIf="segment === 'feedback'">
        <div class="ion-no-padding animate__animated animate__fadeIn animate__faster">
          <app-feedback [sensorId]="sensor.id"></app-feedback>
        </div>
      </ng-container>

    </ion-card-content>
  </ion-card>

  <ion-fab *ngIf="segment === 'feedback'" slot="fixed" vertical="bottom" horizontal="end">
    <ion-fab-button (click)="openFeedback()">
      <ion-icon name="add"></ion-icon>
    </ion-fab-button>
  </ion-fab>
</ion-content>
<ion-footer class="ion-no-border"></ion-footer>
