import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { UserProfileComponent } from './user-profile/user-profile.component';
import { FeedbackHistoryComponent } from './feedback-history/feedback-history.component';
import { LeaveFeedbackComponent } from '../sensors/leave-feedback/leave-feedback.component';

const routes: Routes = [
  {
    path: '',
    component: UserProfileComponent,
  },
  {
    path: 'feedback-history',
    component: FeedbackHistoryComponent,
  },
  {
    path: 'sensors/:id/leave-feedback',
    component: LeaveFeedbackComponent,
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class UserProfileRoutingModule {}
