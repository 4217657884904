import { NgModule, ModuleWithProviders, SkipSelf, Optional } from '@angular/core';
import { Configuration } from './configuration';
import { HttpClient } from '@angular/common/http';

import { FeedbacksService } from './api/feedbacks.service';
import { GatewaysService } from './api/gateways.service';
import { MainService } from './api/main.service';
import { PlantProfilesService } from './api/plantProfiles.service';
import { SensorsService } from './api/sensors.service';
import { UserService } from './api/user.service';

@NgModule({
  imports: [],
  declarations: [],
  exports: [],
  providers: [
    FeedbacksService,
    GatewaysService,
    MainService,
    PlantProfilesService,
    SensorsService,
    UserService,
  ],
})
export class ApiModule {
  public static forRoot(configurationFactory: () => Configuration): ModuleWithProviders<ApiModule> {
    return {
      ngModule: ApiModule,
      providers: [{ provide: Configuration, useFactory: configurationFactory }],
    };
  }

  constructor(@Optional() @SkipSelf() parentModule: ApiModule, @Optional() http: HttpClient) {
    if (parentModule) {
      throw new Error('ApiModule is already loaded. Import in your base AppModule only.');
    }
    if (!http) {
      throw new Error(
        'You need to import the HttpClientModule in your AppModule! \n' +
          'See also https://github.com/angular/angular/issues/20575',
      );
    }
  }
}
