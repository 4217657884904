/**
 * NIMUS CLOUD API Backend
 * NIMUS Cloud Backend Documentation
 *
 * OpenAPI spec version: 1.0.0
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */ /* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams, HttpResponse, HttpEvent } from '@angular/common/http';
import { CustomHttpUrlEncodingCodec } from '../encoder';

import { Observable } from 'rxjs';

import { FeedbackItemDto } from '../model/feedbackItemDto';
import { FeedbackRequireResponseDto } from '../model/feedbackRequireResponseDto';
import { FeedbackSearchRequestDto } from '../model/feedbackSearchRequestDto';
import { FeedbackUnloggedItemDto } from '../model/feedbackUnloggedItemDto';
import { SuccessDto } from '../model/successDto';

import { BASE_PATH, COLLECTION_FORMATS } from '../variables';
import { Configuration } from '../configuration';

@Injectable()
export class FeedbacksService {
  protected basePath = 'http://127.0.0.1:3000/api';

  public defaultHeaders = new HttpHeaders();

  public configuration = new Configuration();

  constructor(
    protected httpClient: HttpClient,
    @Optional() @Inject(BASE_PATH) basePath: string,
    @Optional() configuration: Configuration,
  ) {
    if (basePath) {
      this.basePath = basePath;
    }
    if (configuration) {
      this.configuration = configuration;
      this.basePath = basePath || configuration.basePath || this.basePath;
    }
  }

  /**
   * @param consumes string[] mime-types
   * @return true: consumes contains 'multipart/form-data', false: otherwise
   */
  private canConsumeForm(consumes: string[]): boolean {
    const form = 'multipart/form-data';
    for (const consume of consumes) {
      if (form === consume) {
        return true;
      }
    }
    return false;
  }

  /**
   * Feedback item delete
   *
   * @param hash
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public feedbacksControllerDelete(
    hash: string,
    observe?: 'body',
    reportProgress?: boolean,
  ): Observable<SuccessDto>;
  public feedbacksControllerDelete(
    hash: string,
    observe?: 'response',
    reportProgress?: boolean,
  ): Observable<HttpResponse<SuccessDto>>;
  public feedbacksControllerDelete(
    hash: string,
    observe?: 'events',
    reportProgress?: boolean,
  ): Observable<HttpEvent<SuccessDto>>;
  public feedbacksControllerDelete(
    hash: string,
    observe: any = 'body',
    reportProgress: boolean = false,
  ): Observable<any> {
    if (hash === null || hash === undefined) {
      throw new Error(
        'Required parameter hash was null or undefined when calling feedbacksControllerDelete.',
      );
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.request<SuccessDto>(
      'delete',
      `${this.basePath}/feedbacks/${encodeURIComponent(String(hash))}`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      },
    );
  }

  /**
   * Feedback item get
   *
   * @param hash
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public feedbacksControllerGet(
    hash: string,
    observe?: 'body',
    reportProgress?: boolean,
  ): Observable<FeedbackItemDto>;
  public feedbacksControllerGet(
    hash: string,
    observe?: 'response',
    reportProgress?: boolean,
  ): Observable<HttpResponse<FeedbackItemDto>>;
  public feedbacksControllerGet(
    hash: string,
    observe?: 'events',
    reportProgress?: boolean,
  ): Observable<HttpEvent<FeedbackItemDto>>;
  public feedbacksControllerGet(
    hash: string,
    observe: any = 'body',
    reportProgress: boolean = false,
  ): Observable<any> {
    if (hash === null || hash === undefined) {
      throw new Error(
        'Required parameter hash was null or undefined when calling feedbacksControllerGet.',
      );
    }

    let headers = this.defaultHeaders;

    // authentication (bearer) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === 'function'
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set('Authorization', 'Bearer ' + accessToken);
    }
    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.request<FeedbackItemDto>(
      'get',
      `${this.basePath}/feedbacks/${encodeURIComponent(String(hash))}`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      },
    );
  }

  /**
   * Feedback item get by sensor id
   *
   * @param sensor_id
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public feedbacksControllerGetBySensorId(
    sensor_id: number,
    observe?: 'body',
    reportProgress?: boolean,
  ): Observable<Array<FeedbackItemDto>>;
  public feedbacksControllerGetBySensorId(
    sensor_id: number,
    observe?: 'response',
    reportProgress?: boolean,
  ): Observable<HttpResponse<Array<FeedbackItemDto>>>;
  public feedbacksControllerGetBySensorId(
    sensor_id: number,
    observe?: 'events',
    reportProgress?: boolean,
  ): Observable<HttpEvent<Array<FeedbackItemDto>>>;
  public feedbacksControllerGetBySensorId(
    sensor_id: number,
    observe: any = 'body',
    reportProgress: boolean = false,
  ): Observable<any> {
    if (sensor_id === null || sensor_id === undefined) {
      throw new Error(
        'Required parameter sensor_id was null or undefined when calling feedbacksControllerGetBySensorId.',
      );
    }

    let headers = this.defaultHeaders;

    // authentication (bearer) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === 'function'
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set('Authorization', 'Bearer ' + accessToken);
    }
    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.request<Array<FeedbackItemDto>>(
      'get',
      `${this.basePath}/feedbacks/sensor/${encodeURIComponent(String(sensor_id))}`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      },
    );
  }

  /**
   * Feedback item get by hash
   *
   * @param hash
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public feedbacksControllerGetFeedbackByHash(
    hash: string,
    observe?: 'body',
    reportProgress?: boolean,
  ): Observable<FeedbackUnloggedItemDto>;
  public feedbacksControllerGetFeedbackByHash(
    hash: string,
    observe?: 'response',
    reportProgress?: boolean,
  ): Observable<HttpResponse<FeedbackUnloggedItemDto>>;
  public feedbacksControllerGetFeedbackByHash(
    hash: string,
    observe?: 'events',
    reportProgress?: boolean,
  ): Observable<HttpEvent<FeedbackUnloggedItemDto>>;
  public feedbacksControllerGetFeedbackByHash(
    hash: string,
    observe: any = 'body',
    reportProgress: boolean = false,
  ): Observable<any> {
    if (hash === null || hash === undefined) {
      throw new Error(
        'Required parameter hash was null or undefined when calling feedbacksControllerGetFeedbackByHash.',
      );
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.request<FeedbackUnloggedItemDto>(
      'get',
      `${this.basePath}/feedbacks/unlogged/${encodeURIComponent(String(hash))}`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      },
    );
  }

  /**
   * Get sensors which required to leave feedbacks
   *
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public feedbacksControllerGetSensorsWhichRequireLeaveFeedback(
    observe?: 'body',
    reportProgress?: boolean,
  ): Observable<FeedbackRequireResponseDto>;
  public feedbacksControllerGetSensorsWhichRequireLeaveFeedback(
    observe?: 'response',
    reportProgress?: boolean,
  ): Observable<HttpResponse<FeedbackRequireResponseDto>>;
  public feedbacksControllerGetSensorsWhichRequireLeaveFeedback(
    observe?: 'events',
    reportProgress?: boolean,
  ): Observable<HttpEvent<FeedbackRequireResponseDto>>;
  public feedbacksControllerGetSensorsWhichRequireLeaveFeedback(
    observe: any = 'body',
    reportProgress: boolean = false,
  ): Observable<any> {
    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.request<FeedbackRequireResponseDto>(
      'get',
      `${this.basePath}/feedbacks/required`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      },
    );
  }

  /**
   * Feedback save get
   *
   * @param body
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public feedbacksControllerSave(
    body: FeedbackItemDto,
    observe?: 'body',
    reportProgress?: boolean,
  ): Observable<SuccessDto>;
  public feedbacksControllerSave(
    body: FeedbackItemDto,
    observe?: 'response',
    reportProgress?: boolean,
  ): Observable<HttpResponse<SuccessDto>>;
  public feedbacksControllerSave(
    body: FeedbackItemDto,
    observe?: 'events',
    reportProgress?: boolean,
  ): Observable<HttpEvent<SuccessDto>>;
  public feedbacksControllerSave(
    body: FeedbackItemDto,
    observe: any = 'body',
    reportProgress: boolean = false,
  ): Observable<any> {
    if (body === null || body === undefined) {
      throw new Error(
        'Required parameter body was null or undefined when calling feedbacksControllerSave.',
      );
    }

    let headers = this.defaultHeaders;

    // authentication (bearer) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === 'function'
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set('Authorization', 'Bearer ' + accessToken);
    }
    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected: string | undefined =
      this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected != undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    return this.httpClient.request<SuccessDto>('post', `${this.basePath}/feedbacks`, {
      body: body,
      withCredentials: this.configuration.withCredentials,
      headers: headers,
      observe: observe,
      reportProgress: reportProgress,
    });
  }

  /**
   * Feedback save by hash
   *
   * @param body
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public feedbacksControllerSaveFeedbackByHash(
    body: FeedbackItemDto,
    observe?: 'body',
    reportProgress?: boolean,
  ): Observable<SuccessDto>;
  public feedbacksControllerSaveFeedbackByHash(
    body: FeedbackItemDto,
    observe?: 'response',
    reportProgress?: boolean,
  ): Observable<HttpResponse<SuccessDto>>;
  public feedbacksControllerSaveFeedbackByHash(
    body: FeedbackItemDto,
    observe?: 'events',
    reportProgress?: boolean,
  ): Observable<HttpEvent<SuccessDto>>;
  public feedbacksControllerSaveFeedbackByHash(
    body: FeedbackItemDto,
    observe: any = 'body',
    reportProgress: boolean = false,
  ): Observable<any> {
    if (body === null || body === undefined) {
      throw new Error(
        'Required parameter body was null or undefined when calling feedbacksControllerSaveFeedbackByHash.',
      );
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected: string | undefined =
      this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected != undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    return this.httpClient.request<SuccessDto>('post', `${this.basePath}/feedbacks/unlogged/save`, {
      body: body,
      withCredentials: this.configuration.withCredentials,
      headers: headers,
      observe: observe,
      reportProgress: reportProgress,
    });
  }

  /**
   * Feedback search by sensor id. No filtering yet. Only pagination and sorting
   *
   * @param body
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public feedbacksControllerSearchFeedback(
    body: FeedbackSearchRequestDto,
    observe?: 'body',
    reportProgress?: boolean,
  ): Observable<Array<FeedbackItemDto>>;
  public feedbacksControllerSearchFeedback(
    body: FeedbackSearchRequestDto,
    observe?: 'response',
    reportProgress?: boolean,
  ): Observable<HttpResponse<Array<FeedbackItemDto>>>;
  public feedbacksControllerSearchFeedback(
    body: FeedbackSearchRequestDto,
    observe?: 'events',
    reportProgress?: boolean,
  ): Observable<HttpEvent<Array<FeedbackItemDto>>>;
  public feedbacksControllerSearchFeedback(
    body: FeedbackSearchRequestDto,
    observe: any = 'body',
    reportProgress: boolean = false,
  ): Observable<any> {
    if (body === null || body === undefined) {
      throw new Error(
        'Required parameter body was null or undefined when calling feedbacksControllerSearchFeedback.',
      );
    }

    let headers = this.defaultHeaders;

    // authentication (bearer) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === 'function'
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set('Authorization', 'Bearer ' + accessToken);
    }
    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected: string | undefined =
      this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected != undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    return this.httpClient.request<Array<FeedbackItemDto>>(
      'post',
      `${this.basePath}/feedbacks/search`,
      {
        body: body,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      },
    );
  }
}
