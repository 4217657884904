<app-language-button class="lang-btn"></app-language-button>

<ion-content color="nimus-md3-blue">
  <img
    ngSrc="../../../assets/logo.png"
    alt="NIMUS Logo"
    width="250"
    height="250"
    class="img-center mt-5"
  />

  <ng-container *ngIf="isLoading">
    <ion-label
      style="
        padding: 16px;
        display: flex;
        flex-direction: column;
        gap: 32px;
        align-items: center;
        justify-content: center;
      "
    >
      <h3>
        <ion-skeleton-text
          [animated]="true"
          style="width: 120px; height: 50px; border-radius: 4px"
        ></ion-skeleton-text>
      </h3>
      <h3>
        <ion-skeleton-text
          [animated]="true"
          style="width: 120px; height: 50px; border-radius: 4px"
        ></ion-skeleton-text>
      </h3>
    </ion-label>
  </ng-container>

  <div class="auth-button-bar" *ngIf="!isLoading">
    <ion-button class="auth-button" color="nimus_auth_btn" (click)="login()">
      {{ 'AUTH.LOGIN' | translate }}
    </ion-button>

    <ion-button class="auth-button btn-outlined" fill="outline" (click)="signup()">
      {{ 'AUTH.SIGN_UP' | translate }}
    </ion-button>

    <ion-text class="privacy-text" routerLink="/policy">{{'POLICY.HEADER_PRIVATE' | translate}}</ion-text>
  </div>
  <span class="auth-version">Frontend: {{ versionFE }}, Backend: {{ versionBE }}</span>
</ion-content>
