import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { Components } from '@ionic/core';
import IonAccordionGroup = Components.IonAccordionGroup;

export interface SortParams {
  value: string;
  name: string;
  direction: '' | 'asc' | 'desc';
}

@Component({
  selector: 'app-sort',
  templateUrl: './sort.component.html',
  styleUrls: ['./sort.component.scss'],
})
export class SortComponent {
  @ViewChild('accordionGroup', { static: true }) accordionGroup!: IonAccordionGroup;

  @Input() tableHeaders: {
    name: string;
    value: string;
  }[] = [];

  @Input() sorting: SortParams = {
    value: '',
    name: '',
    direction: '',
  };

  @Output() sortEvent = new EventEmitter<any>();

  emitSort($event: any) {
    if ($event.detail.value) {
      this.sorting.value = $event.detail.value.field;
      this.sorting.direction = $event.detail.value.direction;
      // @ts-ignore
      this.sorting.name = this.tableHeaders.find((item) => item.value === this.sorting.value).name;
      this.sortEvent.emit($event.detail.value);
    } else {
      this.sorting.value = '';
      this.sorting.name = '';
      this.sortEvent.emit({
        field: '',
        direction: '',
      });
    }
    this.closeSorting();
  }

  closeSorting = () => {
    const nativeEl = this.accordionGroup;
    if (nativeEl.value === 'second') {
      nativeEl.value = undefined;
    } else {
      nativeEl.value = 'second';
    }
  };
}
